import React from 'react'
import HeaderBar from './HeaderBar'
import SideNavigation from './SideNavigation'
import { Outlet } from "react-router-dom";
import { useContext } from 'react';
import { DataContext } from '../context/dataProvider';
import "../css/common.sass"

const Layout = () => {
  const { navWidth } = useContext(DataContext);
// 
  return (
    <div className='ada-admin-dashboard-page'>
        <HeaderBar />
        <div className='ada-admin-dashboard-body'>
            <SideNavigation />
            <div className='ada-admin-dashboard-data-container' style={{width: `calc(100% - ${navWidth})`}}>
                <Outlet />
            </div>
            
        </div>
    </div>
  )
}

export default Layout