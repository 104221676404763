import { useState, useEffect, useCallback } from "react";
import { post } from "../utils/api";

const useFetch = (url) => {
    const [fetchedData, setFetchedData] = useState({
        data: [],
        totalCount: 10,
        isLoading: true,
        error: "",
    });
    const token = localStorage.getItem("token");

    const fetchData = useCallback(async () => {
        try {
            const { data } = await post(url, "", {}, token);
            if (data.status >= 400) {
                setFetchedData({
                    data: [],
                    totalCount: 0,
                    isLoading: false,
                    error: data.message,
                });
            } else {
                if (data.code === 200) {
                    setFetchedData({
                        data: data.data,
                        totalCount: data.pagination.total_records_count,
                        isLoading: false,
                        error: "",
                    });
                } else if (data.code >= 400) {
                    setFetchedData({
                        data: [],
                        totalCount: 0,
                        isLoading: false,
                        error: "row count or current page should not be null",
                    });
                }
            }
        } catch (err) {
            setFetchedData({
                data: [],
                totalCount: 0,
                isLoading: false,
                error: err.message,
            });
        }
    }, [url, token]);

    useEffect(() => {
        fetchData();
    }, [url, fetchData]);

    const { data, totalCount, isLoading, error } = fetchedData;
    return { data, totalCount, isLoading, error };
};

export default useFetch;
