import React from 'react'
import ArtistInfoCard from './ArtistInfoCard'
import "../../css/process.sass";
import { Scrollbars } from "react-custom-scrollbars";



const ArtistListDropDown = ({ list, onAdd, onRemove }) => {
  const handleAdd = (artistInfo) => {
    onAdd('chosenArtistList',artistInfo)
  }

  const handleRemove= ( artistInfo ) => {
    onRemove('chosenArtistList',artistInfo)
  }
  return (
      <div className='artist-list-drop-down-container'>
        <Scrollbars autoHide autoHideTimeout={200} autoHideDuration={200} style={{height: '250px', overflow:'hidden'}}>
        {
          list.map((info, index) =>{
            // info.isAdded && console.log('added artist item', info)
            return(
              <ArtistInfoCard key={index} info={info} onAction={() => { info.isAdded ? handleRemove(info) : handleAdd(info) }} type={info.isAdded ? 'remove' : 'add'}/>
            )
          })
        }
        </Scrollbars>
    </div>
  )
}

export default ArtistListDropDown