import React, { useState } from "react";
import DropBox from "../../common/DropBox";
import EditBannerImageList from "./EditBannerImageList";
import "../../css/banner.sass";
import { MdEdit } from "react-icons/md";

const BannerImageList = ({ imageList, onUpload, onRemove, onAddCaption }) => {
    const endImageIndex = imageList.length <= 3 ? imageList.length + 1 : 5;

    const [openEditImagesPopup, setOpenEditImagePopup] = useState(false);

    if (imageList.length === 0)
        return (
            <>
                <DropBox onUpload={onUpload} height="calc(100vh- 350px)" />
                {openEditImagesPopup && (
                    <EditBannerImageList
                        imageList={imageList}
                        onRemove={onRemove}
                        onAddCaption={onAddCaption}
                        onClose={() => setOpenEditImagePopup(false)}
                    />
                )}
            </>
        );

    return (
        <>
            <div className="banner-image-list">
                <DropBox onUpload={onUpload} />
                <div className="banner-image-list-container">
                    <button onClick={() => setOpenEditImagePopup(true)}>
                        <MdEdit size="16px" />
                        &nbsp;Edit
                    </button>
                    <div
                        className={`banner-image-list-grid ${
                            endImageIndex === 3 && "three"
                        } ${endImageIndex === 4 && "four"} ${
                            endImageIndex === 5 && "five"
                        }`}
                    >
                        {imageList
                            .slice(0, endImageIndex)
                            .map((image, index) => (
                                <div
                                    key={index}
                                    className="banner-image-container"
                                >
                                    <img
                                        src={image.src}
                                        alt="banner-img"
                                        key={image.id}
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            {openEditImagesPopup && (
                <EditBannerImageList
                    imageList={imageList}
                    onRemove={onRemove}
                    onAddCaption={onAddCaption}
                    onClose={() => setOpenEditImagePopup(false)}
                />
            )}
        </>
    );
};

export default BannerImageList;
