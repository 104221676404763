import React from 'react'
import "../css/common.sass"

const FormActionRow = (props) => {
    const {onSubmit, onCancel, submitBtnName, cancelBtnName, primaryBtnClass, secondaryBtnClass, disableCancelBtn, disableSubmitBtn} = props;
  return (
    <div className='form-action-row-container'>
        <button className={ secondaryBtnClass !== undefined ? secondaryBtnClass : "cancel-btn" } disabled={disableCancelBtn} onClick={onCancel}>{cancelBtnName !== undefined ? cancelBtnName : 'Cancel'}</button>
        <button className={ primaryBtnClass !== undefined ? primaryBtnClass : "submit-btn" } disabled={disableSubmitBtn} onClick={onSubmit}>{submitBtnName}</button>
    </div>
  )
}

export default FormActionRow