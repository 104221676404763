import React from 'react'
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';


const TableAction = ( {onEdit, onDelete }) => {
  return (
    <div className="action-cell-container">
        <EditButton onEdit={onEdit} />
        <DeleteButton onDelete={onDelete} />
    </div>
  )
}

export default TableAction