import React,{ useEffect, useState } from 'react'
import DropBox from "../../common/DropBox";
import "../../css/product.sass"
import { Scrollbars } from "react-custom-scrollbars";
import ImageDeleteBtn from '../../common/ImageDeleteBtn';
import { img_url_api } from "../../constants/apiImg";

const UploadMedia = ({ mediaData, onChange, onRemove }) => {

  const [activeTab, setActiveTab] = useState('Images');
  const [imageList, setImageList ] = useState([]);
  const [videoList, setVideoList] = useState([]);

  const { mediaList } = mediaData;


  useEffect(()=> {
    if(mediaList !== null){
      setImageList(mediaList?.filter((element) => element?.type?.includes('image/')));
      setVideoList(mediaList?.filter((element) => element?.type?.includes('video/')));
    }
  },[mediaList])


  return (
    <div className="create-product-form upload-media">
        <DropBox onUpload={onChange} />
        {
          mediaData !== null &&
            mediaList?.length > 0 && (
              <div className="upload-media-info-list-container">
                <div className='upload-media-info-list-tab'>
                  {
                    ['Images', 'Videos'].map((item, index)=>
                      <button key={index} className={activeTab === item && "active-media-type"} onClick={()=> setActiveTab(item)}>{item}</button>
                    )
                  }
                </div>
                <Scrollbars autoHide autoHideDuration={200} autoHideTimeout={200} style={{height: 'calc(100vh - 450px)'}}>
                  {
                    activeTab === 'Images' && (
                      <MediaList mediaInfo={imageList} onRemoveMedia={onRemove} />
                    )
                  }
                  {
                    activeTab === 'Videos' && (
                      <MediaList mediaInfo={videoList} onRemoveMedia={onRemove} />
                    )
                  }
                </Scrollbars>
              </div>
            )
        }

    </div>
  )
}

const MediaList = ({mediaInfo, onRemoveMedia}) => {
  const handleDelete = (info) => {
    onRemoveMedia(info)
  }
  return(
    <div className='preview-img-list'>
      {
        mediaInfo.map((img, imgIndex) => 
          <div className='img-container' key={imgIndex}>
            {/* <button onClick={() => handleDelete(img)}><MdOutlineClose /></button> */}
            <ImageDeleteBtn onDelete = { () => handleDelete(img)} />
            {
              img?.type?.includes('image/') &&
                'src' in img ? <img src={img?.src} key={imgIndex} width="150px" alt={imgIndex} /> : <img src={img_url_api+img?.file} key={imgIndex} width="150px" alt={imgIndex} /> 
            }
            {
              img?.type?.includes('video/') && 
                <video width={150} height={150} control>
                    <source src={img?.src} type={img?.type} />
                </video>
            }
          </div>  
        )
      }
    </div>
  )
}

export default UploadMedia