import React from 'react'
import "../css/common.sass";
import { useNavigate } from 'react-router-dom';
import {IoArrowBack} from "react-icons/io5";

const DetailHeaderBar = ({navigateRoute, name}) => {
    const navigate = useNavigate()
    const handleBack = () => {
        navigate(`${navigateRoute}`, {replace: true})
        // navigate(-1);
      // if (window.history.state && window.history.state.idx > 0) {
      //     navigate(-1);
      // } else {
      //     navigate(navigateRoute, { replace: true });
      // }
    }
  return (
    <div className='create-operator-header-container'>
        <button className="back-btn" onClick={handleBack}><IoArrowBack size='24px'/></button>
        <h3>{name}</h3>
    </div>
  )
}

export default DetailHeaderBar