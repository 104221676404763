import React, { useState } from 'react'
import '../../css/process.sass'
import '../../css/artist.sass'
import { checkFormValidate } from '../../utils/commonFuncs'
import PopupLoading from '../../common/PopupLoading'
import apiServer from '../../constants/apiServer'
import { format } from 'date-fns'
import { post } from '../../utils/api'
import { stringify } from 'query-string'
import { useNavigate } from 'react-router-dom'
import ProcessForm from "./ProcessForm";

const CreateProcess = () => {
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(0)

  // const [productList, setProductList ] = useState([]);
  // const [artistList, setArtistList ] = useState([]);

  const [processInfoValues, setProcessInfoValues] = useState({
    nameEng: '',
    nameMyan: '',
    step: null,
    chosenProduct: null,
    startDate: new Date(),
    endDate: new Date(),
    desEng: '',
    desMyan: '',
    chosenArtistList: [],
    imageList: [],
  })

  const [errorValueList, setErrorValueList] = useState({})

  const [createLoading, setCreateLoading] = useState(false)
  const [errorCreate, setErrorCreate] = useState('')

  const handleChooseValue = (name, selectedOption) => {
    setProcessInfoValues((prevInfo) => {
      return {
        ...prevInfo,
        [name]: selectedOption,
      }
    })
    delete errorValueList[name]
    setErrorValueList(errorValueList)
  }

  const handleAddValues = (name, valueInfo) => {
    setProcessInfoValues((prevInfo) => {
      return {
        ...prevInfo,
        [name]: [...prevInfo[name], valueInfo]
      }
    })
  }

  const handleRemoveValues = (name, valueInfo) => {
    setProcessInfoValues((prevInfo) => {
      return{
        ...prevInfo,
        [name]: prevInfo[name].filter((element) => parseInt(element.id) !== parseInt(valueInfo.id))
      }
    })
  }

  const handleChangeCaption = ( value, imageInfo) => {
    console.log("call caption change func");
    const modifiedImageList = processInfoValues['imageList'];
    let modifiedImageId = modifiedImageList.findIndex(
      (element) => element.id === imageInfo.id,
    );
    modifiedImageList[modifiedImageId]['caption'] = value
    console.log("image caption length :::", modifiedImageList[modifiedImageId]['caption']?.length);
    setProcessInfoValues((prevInfo) => {
      return {
        ...prevInfo,
        imageList: modifiedImageList,
      }
    })
  }

  const handleInput = (event) => {
    const { name, value } = event.target
    setProcessInfoValues((prevInfo) => {
      return {
        ...prevInfo,
        [name]: value,
      }
    })
    delete errorValueList[name]
    setErrorValueList(errorValueList)
  }

  const handleUploadImg = (imgInfo) => {
    setProcessInfoValues((prevInfo) => {
      return {
        ...prevInfo,
        imageList: [...prevInfo['imageList'], imgInfo],
      }
    })
  }

  const handleChangeStartDate = (event) => {
    setProcessInfoValues((prevInfo) => {
      return {
        ...prevInfo,
        startDate: event,
        endDate: event,
      }
    })
  }

  const handleChangeEndDate = (event) => {
    setProcessInfoValues((prevInfo) => {
      return {
        ...prevInfo,
        endDate: event,
      }
    })
  }

  const handleRemoveImage = (imageInfo) => {
    setProcessInfoValues((prevInfo) => {
      return {
        ...prevInfo,
        imageList: processInfoValues['imageList'].filter(
          (element) => element !== imageInfo,
        ),
      }
    })
  }

  const handleNext = () => {
    if (activeStep === 0) {
      const generalInfoValue = {
        nameEng: processInfoValues['nameEng'],
        nameMyan: processInfoValues['nameMyan'],
        step: processInfoValues['step'],
        chosenProduct: processInfoValues['chosenProduct'],
      }
      const checkGeneralInfo = checkFormValidate(generalInfoValue)
      setErrorValueList(checkGeneralInfo)
      const errorStateList = Object.keys(checkGeneralInfo)
      if (errorStateList.length === 0) {
        setActiveStep((prevStep) => prevStep + 1)
      }
    } else if (activeStep === 1) {
      const detailedInfoValue = {
        startDate: processInfoValues['startDate'],
        endDate: processInfoValues['endDate'],
        desEng: processInfoValues['desEng'],
        desMyan: processInfoValues['desMyan'],
        chosenArtistList: processInfoValues['chosenArtistList'],
      }
      const checkDetailedInfo = checkFormValidate(detailedInfoValue)
      setErrorValueList(checkDetailedInfo)
      const detailErrorList = Object.keys(checkDetailedInfo)
      if (detailErrorList.length === 0) {
        setActiveStep((prevStep) => prevStep + 1)
      }
    } else if (activeStep === 2) {
      handleSubmit()
    }
  }

  const handleSubmit = async () => {
 
    const {
      nameEng,
      nameMyan,
      step,
      chosenProduct,
      startDate,
      endDate,
      desEng,
      desMyan,
      imageList,
      chosenArtistList,
    } = processInfoValues

    const errorUpdate = imageList?.filter((element) => element?.caption.length > 250);

    imageList?.length > 0 && setErrorCreate("Please upload process image");

    if(imageList?.length > 0 && errorUpdate.length === 0) {
      setCreateLoading(true)
      const passedBody = new FormData()
      for (let i = 0; i < imageList?.length; i++) {
        passedBody.append(`images[${i}]`, imageList[i]?.file)
        passedBody.append(`caption[${i}]`, imageList[i]?.caption)
      }
      for (let i = 0; i < chosenArtistList.length; i++) {
        passedBody.append(`artist_id[${i}]`, chosenArtistList[i]['id'])
      }
  
      const query = {
        titleEN: nameEng,
        titleMM: nameMyan,
        step: parseInt(step['value']),
        product: chosenProduct['value'],
        descriptionEN: desEng,
        descriptionMM: desMyan,
        from_date: format(startDate, 'yyyy-MM-dd'),
        to_date: format(endDate, 'yyyy-MM-dd'),
      }
  
      const processURL = `${apiServer.process.create}?${stringify(query)}`
  
      try {
        const { data } = await post(processURL, passedBody)
        setCreateLoading(false)
        if (data.status >= 400) {
          setErrorCreate(data.message)
        } else {
          if (data.status === 'success') {
            navigate('/processes', { replace: true, state : { status: "success", message : data.message } })
          } else if (data.code >= 400) {
            const responseErrList = Object.values(data)[0]
            setErrorCreate(responseErrList[0])
          }
        }
      } catch (error) {
        setCreateLoading(false)
        setErrorCreate(error.message)
      }
    }

  }

  const handleBack = () => {
    setActiveStep((prevStep) => (prevStep <= 0 ? 0 : prevStep - 1))
  }

  // if(getInfoLoading ) return <Loading text='Retrieving Information' />

  // if(getInfoError) return <div>{getInfoError}</div>

  if (createLoading) return <PopupLoading text="Creating Process" />

  return (
    <ProcessForm 
      title='Create' 
      processInfoValues={processInfoValues} 
      handleInput={handleInput} 
      errorValueList={errorValueList} 
      onUploadImg={handleUploadImg} 
      handleChangeStartDate={handleChangeStartDate} 
      handleChangeEndDate={handleChangeEndDate} 
      handleChooseValue={handleChooseValue} 
      handleAddValues={handleAddValues} 
      handleRemoveValues={handleRemoveValues} 
      onRemoveImage={handleRemoveImage}
      onChangeCaption={handleChangeCaption}
      activeStep={activeStep} 
      handleNext={handleNext} 
      handleBack={handleBack} 
      errorMessage={errorCreate}  
      />
  )
}

export default CreateProcess
