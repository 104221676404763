import React, { useEffect, useState, useCallback } from "react";
import apiServer from "../../constants/apiServer";
import useFetch from "../../utils/useFetch";
import "../../css/operator.sass";
import DataList from "../../common/DataList";
import { useNavigate } from "react-router-dom";
import { post } from "../../utils/api";
import ConfirmationPopup from "../../common/ConfirmationPopup";
import { Headers } from "../../constants/Headers";
import TableAction from "../../common/TableAction";
import { StatusCode, DeleteRow } from "../../utils/commonFuncs";
import { useContext } from 'react';
import { DataContext } from '../../context/dataProvider';
import { useLocation } from "react-router-dom";


const OperatorList = () => {


  const navigate = useNavigate();
  const location = useLocation();
  const { currentPageList, setCurrentPageList } = useContext(DataContext);

  const [ page, setPage ] = useState(currentPageList['operator']);
  const [rowPerPage, setRowPerPage ] = useState(10);
  const [totalRecords, setTotalRecords ] = useState(0);
  const [actionStatus, setActionStatus] = useState("");
  const [actionMessage, setActionMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [getListError, setGetListError] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [operatorId, setOperatorId] = useState('');
  const [headerInfo, setHeaderInfo] = useState(Headers['Operator']);

  const { data, totalCount, isLoading, error } = useFetch(
    `${apiServer.operator.list}?page_at=${page}&row_count=${rowPerPage}`
  );

  const modifiedData = useCallback((listData) => {

    const modifyData = listData.map((info, index) => {
      return{
        ...info,
        rowId: index + 1 + (page - 1) * rowPerPage,
      }
    });
    
    return modifyData;

  }, [ page, rowPerPage]);

  const modifyHeader = useCallback(() => {

    headerInfo[headerInfo.length - 1]["renderCell"] = ({id}) =>{
      const onEdit = () => {
        navigate(`${id}/edit`, { replace: true });
      };
      const onDelete = () => {
        setConfirmDelete(true);
        setOperatorId(id);
      };
      return (
        <TableAction onEdit={onEdit} onDelete={onDelete} />
      );
    };

    setHeaderInfo(headerInfo);

  }, [headerInfo, navigate])

  useEffect(() => {

    if(location?.state !== null){
      const { status, message } = location?.state;
      setActionStatus(status);
      setActionMessage(message);
    }

  }, [location]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {

    setRowData(modifiedData(data));

  }, [data, page, modifiedData]);

  useEffect(() => {
    setGetListError(error);
  }, [error]);

  useEffect(() => {
    setTotalRecords(totalCount)
  },[totalCount]);

  useEffect(() => {

    modifyHeader();

  }, [headerInfo, navigate, modifyHeader]);

  const handleConfirmDelete = async () => {

    setConfirmDelete(false);
    setLoading(true);
    try {
      const { data : deletedInfo } = await post(`${apiServer.operator.delete}?id=${operatorId}`);
      setLoading(false);
      setActionStatus(StatusCode[deletedInfo?.status]);
      setActionMessage(deletedInfo?.message);

      if(! (deletedInfo?.status >= 400 )){
        const dataList = modifiedData(DeleteRow(rowData, operatorId));
        setRowData(dataList);
        setTotalRecords(totalRecords - 1);
        if(dataList.length === 0){

          setPage((prev) => prev > 1  ? prev - 1 : 1);
          setCurrentPageList((prev) => {
            return {
              ...currentPageList,
              "operator" :  prev > 1 ? prev - 1 : 1
            }
          });
        }
      }

      

    } catch (err) {
      
      setLoading(false);
      setActionStatus("error");
      setActionMessage(err.message);
    }
  };

  const handleChangePage = (currentPage) => {
    setPage(currentPage + 1);
    setCurrentPageList({
      ...currentPageList,
      "operator" : currentPage + 1
    });

  }

  const handleChangePageSize = (rows) => {
    setRowPerPage(rows)
  }

  return (
    <>
      <DataList
        title="Operator List"
        headers={headerInfo}
        rowData={rowData}
        totalCount={totalRecords}
        isLoading={loading}
        error={getListError}
        currentPage={page}
        pageSize={rowPerPage}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        deleteMsg={actionMessage}
        deleteStatus={actionStatus}
        emptyDataText={'There is no operator. Please Create One.'}
      />
      
      { confirmDelete && 
          <ConfirmationPopup
          headerText="Delete Operator"
          body={"Are you sure you want to delete this operator ?"}
          onSubmit={handleConfirmDelete}
          onCancel={() => {
            setConfirmDelete(false)
          }}
        />
      }
    </>
  );
};

export default OperatorList;
