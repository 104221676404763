import React, { useState, Children } from 'react'
import '../css/common.sass'
import { MdVisibility } from 'react-icons/md'
import { MdVisibilityOff } from 'react-icons/md'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import CustomCalendar from './CustomCalendar'
import { MdOutlineDateRange } from 'react-icons/md'
import Radio from '@mui/material/Radio'
// import CustomDateRangeInputs from "../common/DateRangePicker";
import DateRangePicker from 'react-bootstrap-daterangepicker'
import '../css/bootstrap-daterangepicker/daterangepicker.css'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

const InputField = (props) => {
  const [showCalendar, setShowCalendar] = useState(false)
  // const [showDateRangeCalendar, setShowDateRangeCalendar] = useState(false);

  const children = Children.toArray(props.children)

  const rowFieldStyle = 'vertical-style'
  const textAreaFieldStyle = 'input-text-area'

  const {
    layout,
    title,
    value,
    name,
    onChange,
    id,
    type,
    isRequired,
    isFlag,
    errMsg,
    width,
    fieldType,
    onShowPassowrd,
    ref,
    disabled,
    list,
    selectedValue,
    onSelectValue,
    description,
    onChangeDescription,
    selectedDate,
    onChangeDate,
    minSelectDate,
    maxSelectDate,
    mainWidth,
    selectedRadioValue,
    onChangeRadioValue,
    radioValueList,
    dateRangeValue,
    onChangeDateRangeValue,
    unitValue,
    onChangeUnitValue,
    unitValueList,
    unitName,
    selectValue,
    selectOptionList,
    searchableSelect,
    clearableSelect,
    selectBoxClassName,
    // selectBoxName,
    onInputSelectChange,
    onChangeSelectBox,
    createValueLoading,
    createSelectValue
  } = props

  // const reactSelectStyles = {
  //   input: {fontSize: '12px',width: width },
  //   placeholder: { fontSize: '12px' }
  // }

  const handleChooseValue = (chosenValue) => {
    console.log("chosen value in select box", chosenValue)
    onChangeSelectBox(chosenValue)
  }

  return (
    <div
      className={`form-input-field-container ${
        layout === 'vertical' && rowFieldStyle
      } ${fieldType === 'textarea' && textAreaFieldStyle}`}
      style={{ width: mainWidth }}
    >
      <label htmlFor={id}>
        {title}
        {/* {isRequired && <span style={{ color: 'brown' }}>*</span>} */}
        {!isRequired && (
          <span style={{ color: '#cdcdcd', marginLeft: '5px' }}>
            (Optional)
          </span>
        )}
      </label>
      <div style={{ position: 'relative' }}>
        {fieldType === 'password' && (
          <div
            className="form-input-password-container"
            style={{ width: width }}
          >
            <input
              type={type}
              id={id}
              value={value}
              name={name}
              onChange={onChange}
              className="input-password-field"
              disabled={disabled}
            />
            <Tooltip
              title={type === 'password' ? 'Show Password' : 'Hide Password'}
            >
              <button onClick={onShowPassowrd}>
                {type === 'password' ? (
                  <MdVisibility size="20px" />
                ) : (
                  <MdVisibilityOff size="20px" />
                )}
              </button>
            </Tooltip>
          </div>
        )}
        {fieldType === 'input' && (
          <input
            type={type}
            id={id}
            value={value}
            style={{ width: width }}
            disabled={disabled}
            name={name}
            ref={ref}
            onChange={onChange}
            className="input-text-field"
          />
        )}
        {fieldType === 'textarea' && (
          <textarea
            rows={3}
            className="input-field-textarea"
            style={{ width: width }}
            value={description}
            name={name}
            onChange={onChangeDescription}
          />
        )}
        {fieldType === 'selectbox' && (
          <select
            name={name}
            value={selectedValue}
            onChange={onSelectValue}
            style={{ width: width }}
            className="input-field-selectbox"
          >
            {list.map((listItem, listItemIndex) => (
              <option value={listItem} key={listItemIndex}>
                {listItem}
              </option>
            ))}
          </select>
        )}
        {fieldType === 'date' && (
          <div className="input-field-date" style={{ width: width }}>
            <div
              className="input-field-date-box-container"
              onClick={() => setShowCalendar(!showCalendar)}
            >
              <div className="input-field-date-box">
                {selectedDate?.getDate()}-{selectedDate?.getMonth() + 1}-
                {selectedDate?.getFullYear()}
              </div>
              <MdOutlineDateRange size="24px" />
            </div>
            {showCalendar && (
              <div className="common-calendar-container">
                <CustomCalendar
                  value={selectedDate}
                  onChange={onChangeDate}
                  minDate={minSelectDate}
                  maxDate={maxSelectDate}
                />
              </div>
            )}
          </div>
        )}
        {fieldType === 'date-range' && (
          <div className="input-field-date-range-container">
            {/* <CustomDateRangeInputs
              width={width}
              value={dateRangeValue}
              onCallback={onChangeDateRangeValue}
              onShowCalendar={() => setShowDateRangeCalendar(true)}
              onHideCalendar={() => setShowDateRangeCalendar(false)}
            /> */}

            <DateRangePicker
              initialSettings={dateRangeValue}
              onCallback={onChangeDateRangeValue}
            >
              <input
                className="date-range-input-box"
                style={{ width: width }}
              />
            </DateRangePicker>
          </div>
        )}
        {fieldType === 'radio' && (
          <div className="input-field-radio-group" style={{ width: width }}>
            {radioValueList.map((radioItem, radioItemIndex) => (
              <>
                <Radio
                  checked={selectedRadioValue === radioItem.value}
                  onChange={onChangeRadioValue}
                  value={radioItem.value}
                  name={name}
                  key={radioItemIndex}
                  // inputProps={{ 'aria-label': 'A' }}
                />
                <label>{radioItem.name}</label>
              </>
            ))}
          </div>
        )}
        {fieldType === 'inputSelect' && (
          <div
            className="input-select-field-container"
            style={{ width: width }}
          >
            <input
              type={type}
              value={value}
              onChange={onChange}
              name={name}
              id={id}
              style={{ width: `calc(${width} - 70px)` }}
              className="input-select-field-box"
            />
            <select
              value={unitValue}
              name={unitName}
              onChange={onChangeUnitValue}
              className="select-unit-selectbox"
            >
              {unitValueList.map((unitValue, unitValueIndex) => (
                <option key={unitValueIndex} value={unitValue}>
                  {unitValue}
                </option>
              ))}
            </select>
          </div>
        )}
        {fieldType === 'customize' && children}
        {isFlag && (
          <div
            className={
              layout === 'vertical'
                ? 'error-message vertical-layout'
                : 'error-message'
            }
          >
            {errMsg}
          </div>
        )}
        {fieldType === 'search-select' && (
          <Select
            className={selectBoxClassName}
            value={selectValue}
            options={selectOptionList}
            name={name}
            isSearchable={searchableSelect}
            isClearable={clearableSelect}
            onChange={handleChooseValue}
          />
        )}
        {fieldType === 'create-select' && (
          <CreatableSelect
            className={selectBoxClassName}
            isLoading={createValueLoading}
            // defaultValue={defaultSelectValue}
            onCreateOption={onInputSelectChange}
            onChange={handleChooseValue}
            options={selectOptionList}
            value={createSelectValue}
          />
        )}
      </div>
    </div>
  )
}

InputField.defaultProps = {
  fieldType: 'input',
  layout: 'horizontal',
  searchableSelect: false,
  createableSelect: false,
}

InputField.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  isFlag: PropTypes.bool,
  errMsg: PropTypes.string,
  width: PropTypes.string,
  onShowPassowrd: PropTypes.func,
  fieldType: PropTypes.oneOf([
    'input',
    'password',
    'selectbox',
    'textarea',
    'date',
    'radio',
    'customize',
    'date-range',
    'inputSelect',
    'search-select',
    'create-select',
  ]),
  disabled: PropTypes.bool,
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  list: PropTypes.array,
  selectedValue: PropTypes.string,
  onSelectValue: PropTypes.func,
  description: PropTypes.string,
  onChangeDescription: PropTypes.func,
  selectedRadioValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChangeRadioValue: PropTypes.func,
  radioValueList: PropTypes.array,
  dateRangeValue: PropTypes.array,
  onChangeDateRangeValue: PropTypes.func,
  unitValue: PropTypes.string,
  onChangeUnitValue: PropTypes.func,
  unitValueList: PropTypes.object,
  unitName: PropTypes.string,
  defaultSelectValue: PropTypes.object,
  selectOptionList: PropTypes.array,
  searchableSelect: PropTypes.bool,
  clearableSelect: PropTypes.bool,
  selectBoxClassName: PropTypes.string,
  selectBoxName: PropTypes.string,
  onInputSelectChange: PropTypes.func,
  onAddSelectValue: PropTypes.func,
  createValueLoading: PropTypes.bool,
  createSelectValue: PropTypes.object
}
export default InputField
