import React from 'react'
import CreateButton from './CreateButton'

const ListHeaderBar = ({ title, createAction, createDisable, hideCreateBtn }) => {
  return (
    <div className='list-header-bar-container'>
        <h3>{title}</h3>
        {
          !hideCreateBtn && <CreateButton onCreate={createAction} disabled={createDisable ? createDisable : false} />
        }
        
    </div>
  )
}

export default ListHeaderBar