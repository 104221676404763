import React from "react";
import PropTypes from "prop-types";
import { MdOutlineClose, MdDeleteForever } from "react-icons/md";
import FormActionRow from "./FormActionRow";

const ConfirmationPopup = (props) => {
  const {
    confirmType,
    headerText,
    body,
    primaryBtnText,
    secondaryBtnText,
    onSubmit,
    onCancel
  } = props;
  return (
    <div className="popup-overlay-container">
      <div className="confirmation-popup-container">
        <button className="close-btn" onClick={onCancel}>
          <MdOutlineClose size='28px' color='#cdcdcd' />
        </button>
        <div className="confirmation-popup-header">
          <MdDeleteForever size="28px" />
          <h4>{headerText}</h4>
        </div> 
        <div className="confirmation-popup-body">
            <p>{body}</p>
            <FormActionRow
                primaryBtnClass= { `confirm-btn ${confirmType}`}
                secondaryBtnClass= "cancel-confirm-btn"
                submitBtnName={primaryBtnText}
                onSubmit={onSubmit}
                onCancel={onCancel}
                cancelBtnName={secondaryBtnText}
            />
        </div>
      </div>
    </div>
  );
};

ConfirmationPopup.defaultProps = {
  confirmType: "delete",
  primaryBtnText: "Yes, Delete",
  secondaryBtnText: "No, Keep it"
};

ConfirmationPopup.propTypes = {
  confirmType: PropTypes.oneOf(["delete"]),
  headerText: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  primaryBtnText: PropTypes.string,
  secondaryBtnText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ConfirmationPopup;
