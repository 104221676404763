import React, { useState, useRef } from "react";
import PopupHeader from "./PopupHeader";
import "../css/profileEditor.sass";
import AvatarEditor from "react-avatar-editor";
import { BiRotateLeft, BiRotateRight } from "react-icons/bi";
import {  MdAdd } from "react-icons/md";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import { HiMinusSm } from "react-icons/hi";

const ProfileEditor = ({ onClose, onSave, profileInfo, replaceImg }) => {

  const editor = useRef();

  const width =140;
  const height = 130;
  const [scale, setScale] = useState(1.5);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [rotate, setRotate] = useState(0);
  const borderRadius = 5;
  const disableCanvasRotation = false;
  const backgroundColor = undefined;
  const allowZoomOut = false;
  // const [width, setWidth] = useState(200);
  // const [height, setHeight] = useState(200);
  // const [borderRadius, setBorderRadius] = useState(50);
  // const [disableCanvasRotation, setdisableCanvasRotation] = useState(false);
  // const [isTransparent, setIsTransparent] = useState(false);
  // const [backgroundColor, setBackgroundColor] = useState(undefined);
  // const [allowZoomOut, setAllowZoomOut] = useState(false);

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const handleScaleMinus = () => {
    setScale((prevScale) => prevScale < 0.1 ? 1 : prevScale - 0.01)
  }

  const handleScalePlus = () => {
    setScale((prevScale) => prevScale > 2 ? 2 : prevScale + 0.01)
  }

  const rotateLeft = (e) => {
    e.preventDefault();
    setRotate((rotate - 90) % 360);
  };

  const rotateRight = (e) => {
    e.preventDefault();
    setRotate((rotate + 90) % 360);
  };

  // const handleAllowZoomOut = (e) => {
  //   setAllowZoomOut(e.target.checked);
  // };

  // const handleDisableCanvasRotation = (e) => {
  //   setdisableCanvasRotation(e.target.checked);
  // };

  // const rotateScale = (e) => {
  //   e.preventDefault();
  //   setRotate(parseFloat(e.target.value));
  // };

  // const handleBorderRadius = (e) => {
  //   setBorderRadius(parseInt(e.target.value));
  // };

  // const handleXPosition = (e) => {
  //   setPosition((prevInfo) => {
  //     return {
  //       ...prevInfo,
  //       x: parseFloat(e.target.value)
  //     };
  //   });
  // };

  // const handleYPosition = (e) => {
  //   setPosition((prevInfo) => {
  //     return {
  //       ...prevInfo,
  //       y: parseFloat(e.target.value)
  //     };
  //   });
  // };

  // const handleWidth = (e) => {
  //   setWidth(parseInt(e.target.value));
  // };

  // const handleHeight = (e) => {
  //   setHeight(parseInt(e.target.value));
  // };

  // logCallback(e) {
  //   console.log('callback', e)
  // }

  // const changeBackgroundColor = (e) => {
  //   setBackgroundColor(e.target.value);
  // };

  // const setTransparent = (e) => {
  //   const isTransparent = e.target.checked;
  //   // set color to white initially
  //   const backgroundColor = isTransparent ? "#fff" : undefined;
  //   setIsTransparent(isTransparent);
  //   setBackgroundColor(backgroundColor);
  // };

  const handlePositionChange = (position) => {
    setPosition(position);
  };



  const handleSave = () => {
    const img = editor.current?.getImageScaledToCanvas().toDataURL()
    const rect = editor.current?.getCroppingRect()


    onSave({
      img,
      rect,
      scale: scale,
      width: width,
      height: height,
      borderRadius: borderRadius,
    })

  }

  return (
    <>
      <div className="popup-overlay-container">
        <div className="profile-editor-popup">
          <PopupHeader header={"Edit Profile"} onClose={onClose} />
          <div className="profile-editor-image-container">
            <AvatarEditor
              ref={editor}
              scale={scale}
              width={width}
              height={height}
              image={profileInfo}
              position={position}
              color={[0, 0, 0, 0.4]}
              onPositionChange={handlePositionChange}
              rotate={rotate}
              borderRadius={width / (100 / borderRadius)}
              backgroundColor={backgroundColor}
              disableCanvasRotation={disableCanvasRotation}
            />
          </div>
          <div className="profile-editor-action-container">
            <div className="profile-editor-action-left-side">
              <Tooltip title={'Rotate Left'}>
                <button onClick={rotateLeft}>
                  <BiRotateLeft size="20px" />
                </button>
              </Tooltip>
              <Tooltip title={'Rotate Right'}>
                <button onClick={rotateRight}>
                  <BiRotateRight size="20px" />
                </button>
              </Tooltip>
            </div>
            <div className="profile-editor-action-right-side">
              <button onClick={handleScaleMinus}><HiMinusSm size="20px" /></button>
              <Slider
                min={allowZoomOut ? 0.1 : 1}
                max={2}
                step={0.01}
                defaultValue={1}
                value={scale}
                onChange={handleScale}
                size="small"
                valueLabelDisplay="auto"
              />
              <button onClick={handleScalePlus}><MdAdd size="20px" /></button>
            </div>
          </div>
          <div className="profile-editor-popup-button-row">
            <div className="profile-editor-popup-bottom-left-side">
              {/* <button className="replace-image-btn">
                Replace Image
                <input type="file" className="upload-profile-btn-input" onChange={replaceImg} />
              </button> */}
            </div>
            <div className="profile-editor-popup-bottom-right-side">
              <button className="dismiss-profile-editor" onClick={onClose}>
                Cancel
              </button>
              <button className="save-profile-editor" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileEditor;
