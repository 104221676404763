import React from 'react'
import pageNotFound from "../image/404Error.svg";
import { useNavigate } from "react-router-dom";


const PageNotFound = () => {

    const navigate = useNavigate();

    const handleBackHome = () => {
        navigate('/dashboard', { replace: true })
    }

  return (
    <div className="full-page-container">
        <div className="page-not-found-section">
            <div className='page-not-found-section-left-side'>
                <h1><span>404</span><span>Whoops!</span></h1>
                <p>We can't seem to find the page you are looking for :(</p>
                <button onClick={handleBackHome}>Back to Home</button>
            </div>
            <div className='page-not-found-section-right-side'>
                <img src={pageNotFound} alt="page not found" />
            </div>
        </div>
    </div>
  )
}

export default PageNotFound