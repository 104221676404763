import React,{useState, useEffect, useCallback} from 'react'
import { useNavigate } from "react-router-dom";
import useFetch from '../../utils/useFetch';
import apiServer from '../../constants/apiServer';
import { post } from "../../utils/api";
import DataList from '../../common/DataList';
import defaultImg from '../../image/default-image.png'
import ConfirmationPopup from '../../common/ConfirmationPopup';
import { img_url_api } from "../../constants/apiImg";
import { Headers } from '../../constants/Headers';
import TableAction from '../../common/TableAction';
import { StatusCode, DeleteRow } from "../../utils/commonFuncs";
import { useContext } from 'react';
import { DataContext } from '../../context/dataProvider';
import { useLocation } from 'react-router-dom';

const Process = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const { currentPageList, setCurrentPageList } = useContext(DataContext);

  const [ page, setPage ] = useState(currentPageList['process']);
  const [rowPerPage, setRowPerPage ] = useState(10);
  const [totalRecords, setTotalRecords ] = useState(0);
  const [actionStatus, setActionStatus] = useState("");
  const [actionMessage, setActionMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [getListError, setGetListError ] = useState('');
  const [processId, setProcessId] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [headerInfo, setHeaderInfo] = useState(Headers['Process']);


  const { data, totalCount, isLoading, error } = useFetch(
    `${apiServer.process.list}?page_at=${page}&row_count=${rowPerPage}`
  );

  const modifiedData = useCallback((listData) => {
    const modifyData = listData.map((info, index) => {
      
      const processName = info?.title;
      const productName = info?.name;
      return {
        ...info,
        rowId: index + 1 + (page - 1) * rowPerPage,
        process_eng_name : processName?.en,
        name : productName?.en
      }
    });
    
    return modifyData;
  }, [ page, rowPerPage]);

  const modifiedHeader = useCallback(() => {

    headerInfo[1]["renderCell"] = (params) => {
      const profile = params.formattedValue;
      const id = params.api.getRowIndex(params.row.id);
      return(
        <img src={profile === "" || profile === undefined ? defaultImg : img_url_api+profile} alt="process-img" className={profile === "" || profile === undefined ? "dummy_profile" : id === 0 ? "first--artist_profile" : "artist_profile"}/>
      )
    };

    headerInfo[headerInfo.length - 1]["renderCell"] = ({id}) =>{
      const onEdit = () => {
          navigate(`${id}/edit`, { replace: true });
        };
        const onDelete = async () => {
          setProcessId(id);
          setConfirmDelete(true)
        };
      return (
        <TableAction onEdit={onEdit} onDelete={onDelete} />
      );
    };

    setHeaderInfo(headerInfo);

  }, [headerInfo, navigate])

  useEffect(()=>{
    setLoading(isLoading);
  },[isLoading])

  useEffect(() => {

    if(location?.state !== null){
      const { status, message } = location?.state;
      setActionStatus(status);
      setActionMessage(message);
    }

  }, [location]);

  useEffect(()=> {
    const modifyData = modifiedData(data);
    setRowData(modifyData);
  },[data, page, modifiedData]);

  useEffect(()=> {
    setGetListError(error)
  },[error])


  useEffect(() => {
    setTotalRecords(totalCount)
  },[totalCount])

  useEffect(() => {

    modifiedHeader();

  }, [headerInfo, navigate, modifiedHeader]);

  const handleConfirmDelete = async() => {
    setConfirmDelete(false)
    setLoading(true);
    try {

      const { data : deletedInfo } = await post(
        `${apiServer.process.delete}?id=${processId}`
      );

      setActionStatus(StatusCode[deletedInfo?.status]);
      setActionMessage(deletedInfo?.message);

      if(! (deletedInfo?.status >= 400 )){
        const dataList = modifiedData(DeleteRow(rowData, processId));
        setRowData(dataList);
        setTotalRecords(totalRecords - 1);
        if(dataList.length === 0){

          setPage((prev) => prev > 1  ? prev - 1 : 1);
          setCurrentPageList((prev) => {
            return {
              ...currentPageList,
              "process" :  prev > 1 ? prev - 1 : 1
            }
          });
        }
      }

      setLoading(false);

    } catch (err) {
      
      setLoading(false);
      setActionStatus("error");
      setActionMessage(err.message);
    }
  }

  const handleChangePage = (currentPage) => {
    setPage(currentPage + 1)
    setCurrentPageList({
      ...currentPageList,
      "process" : currentPage + 1
    });
  }

  const handleChangePageSize = (rows) => {
    setRowPerPage(rows)
  }

  return (
    <>
        <DataList
          title={"Process List"}
          headers={headerInfo}
          rowData={rowData}
          isLoading={loading}
          totalCount={totalRecords}
          error={getListError}
          currentPage={page}
          pageSize={rowPerPage}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangePageSize}
          deleteMsg={actionMessage}
          deleteStatus={actionStatus}
          emptyDataText={'There is no process. Please Create One.'}

        />

        { confirmDelete && 
            <ConfirmationPopup
              headerText="Delete Process Step"
              body={"Are you sure you want to delete this process step ?"}
              onSubmit={handleConfirmDelete}
              onCancel={() => {
                setConfirmDelete(false)
              }}
          />
        }
    </>
  );
}

export default Process