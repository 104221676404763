import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import apiServer from "../../constants/apiServer";
import { post } from "../../utils/api";
import AdaAlert from "../../common/AdaAlert";
import PopupLoading from "../../common/PopupLoading";
import { checkFormValidate } from "../../utils/commonFuncs";
import ArtistTypeForm from "./ArtistTypeForm";

const CreateArtistType = () => {
    const navigate = useNavigate();

    const [artistTypeInfoValues, setArtistTypeInfoValues] = useState({
        nameENG: "",
        nameMM: "",
    });

    const [artistTypeErrorValues, setArtistTypeErrorValues] = useState({});

    const [createLoading, setCreateLoading] = useState(false);
    const [errorCreate, setErrorCreate] = useState("");

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setArtistTypeInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                [name]: value,
            };
        });
        delete artistTypeErrorValues[name];
        setArtistTypeErrorValues(artistTypeErrorValues);
    };

    const handleCreateArtistType = async (event) => {
        event.preventDefault();
        const checkGeneralInfo = checkFormValidate({
            nameENG: artistTypeInfoValues["nameENG"],
            nameMM: artistTypeInfoValues["nameMM"],
        });
        setArtistTypeErrorValues(checkGeneralInfo);
        const errorStateList = Object.keys(checkGeneralInfo);
        if (errorStateList?.length === 0) {
            setCreateLoading(true);

            const createArtistURL = `${apiServer.artist_type.create}`;
            const fd = new FormData();
            Object.keys(artistTypeInfoValues).map((element) =>
                fd.append(element, artistTypeInfoValues[element])
            );
            try {
                const { data } = await post(createArtistURL, fd);
                setCreateLoading(false);
                if (data.status > 400) {
                    setErrorCreate(data.message);
                } else {
                    if (data.code >= 400) {
                        setErrorCreate("Data should not be empty");
                    } else if (data.status === "success") {
                        navigate("/artist_types", {
                            replace: true,
                            state: { status: "success", message: data.message },
                        });
                    }
                }
            } catch (error) {
                setCreateLoading(false);
                setErrorCreate(error.message);
            }
        }
    };

    const handleCancel = () => {
        navigate("/artist_types", { replace: true });
    };

    if (createLoading) return <PopupLoading text={"Creating Artist Type"} />;

    return (
        <>
            <ArtistTypeForm
                onCreate={handleCreateArtistType}
                onCancel={handleCancel}
                infoValues={artistTypeInfoValues}
                errorValues={artistTypeErrorValues}
                onChangeValue={handleChangeValue}
                formTitle={"Create Artist Type"}
                onSubmitName={"Create"}
            />
            {errorCreate !== "" && (
                <AdaAlert type="error" message={errorCreate} />
            )}
        </>
    );
};

export default CreateArtistType;
