import React from 'react'
import { MdClose } from 'react-icons/md';
import "../css/common.sass";

const EditImageBox = ({imageInfo, onRemove, onAddCaption}) => {

  const handleChangeText = (event) => {
    const { value } = event.target;
    onAddCaption(value, imageInfo)
  }


  return (
    <div className='edit-image-box-container'>
        <button onClick={onRemove}><MdClose size='18px' /></button>
        <div className='edit-image-box'>
            <img src={imageInfo.src} alt='upload-img' />
        </div>
        <input type='text' value={imageInfo.caption} onChange={handleChangeText} name='caption' placeholder='Add Caption here' />

    </div>
  )
}



export default EditImageBox