import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import "../css/common.sass";
import PropTypes from 'prop-types';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent:'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  rowGap: '15px',
  width: '95%'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const DropBox = ({ onUpload, height }) => {

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      acceptedFiles.map((file, index) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          onUpload({
            id: index,
            src: e.target.result,
            file: file,
            type: file.type,
            caption: '',
            status: "new"
          });
        };
        reader.readAsDataURL(file);
        return file;
      });
    }, [onUpload])
  });

  const style = useMemo(() => ({
    ...{...baseStyle, height: height},
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused, 
    isDragAccept,
    isDragReject,
    height
  ]);

  return (
    <>
      <div
        // className="drop-box-container"
        style={{height: height}}
        {...getRootProps({ style })}
      >
        <input {...getInputProps()} />
        <FaCloudUploadAlt color="var(--background-color)" size="35px" />
        <div style={{fontSize: '12px'}}>Drop files here or</div>
        {/* <div style={{ color: "#0f53a5", fontSize:'12px' }}>Browse files to upload</div> */}
        <button className="choose-img">Browse Files</button>
      </div>
    </>
  );
};

DropBox.defaultProps = {
  height: '150px',
}

DropBox.propTypes = {
  height: PropTypes.string,
  onUpload:PropTypes.func.isRequired,
}

export default DropBox;
