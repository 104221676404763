import React, { useState, useEffect } from "react";
import useFetchInfo from "../../utils/useFetchInfo";
import { useParams, useNavigate } from "react-router-dom";
import apiServer from "../../constants/apiServer";
import { img_url_api } from "../../constants/apiImg";
import SquareLoading from "../../common/SquareLoading";
import { checkFormValidate } from "../../utils/commonFuncs";
import { post } from "../../utils/api";
import PopupLoading from "../../common/PopupLoading";
import AdaAlert from "../../common/AdaAlert";
import BannerForm from "./BannerForm";
import EmptyPage from "../../common/EmptyPage";

const UpdateBanner = () => {
    const { bannerId } = useParams();
    const navigate = useNavigate();

    const [bannerInfoValues, setBannerInfoValues] = useState({
        name: "",
        banner_type: 1,
        is_active: 1,
        remark: "",
        imageList: [],
        deleteImageList: [],
    });

    const { name, imageList, deleteImageList } = bannerInfoValues;

    const [errorInfoList, setErrorInfoList] = useState({});
    const [ipdateLoading, setUpdateLoading] = useState(false);
    const [getInfoLoading, setGetInfoLoading] = useState(false);
    const [getInfoError, setGetInfoError] = useState("");
    const [updateError, setUpdateError] = useState("");

    const { data, isLoading, error } = useFetchInfo(
        `${apiServer.banner.getDetail}?id=${bannerId}`
    );

    useEffect(() => {
        const bannerMediaList = data["bannermedia"];
        const bannerImageList = [];
        for (let i = 0; i < bannerMediaList?.length; i++) {
            bannerImageList.push({
                id: bannerMediaList[i]?.id,
                caption: bannerMediaList[i]?.caption,
                file: bannerMediaList[i]?.file,
                type: bannerMediaList[i]?.type,
                src: img_url_api + bannerMediaList[i]?.file,
                status: "uploaded",
            });
        }
        setBannerInfoValues({
            name: data["name"],
            banner_type: parseInt(data["banner_type"]),
            is_active: parseInt(data["is_active"]),
            remark: data["remark"],
            imageList: bannerImageList,
            deleteImageList: [],
        });
        setGetInfoError(error);
        setGetInfoLoading(isLoading);
    }, [data, isLoading, error]);

    const handleChangeInput = (event) => {
        const { name, value } = event.target;
        setBannerInfoValues((generalInfo) => {
            return {
                ...generalInfo,
                [name]:
                    name === "banner_type" || name === "is_active"
                        ? parseInt(value)
                        : value,
            };
        });
        delete errorInfoList[name];
        setErrorInfoList(errorInfoList);
    };

    const handleUploadImg = (imgInfo) => {
        setBannerInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                imageList: [...prevInfo["imageList"], imgInfo],
            };
        });
    };

    const handleChangeCaption = (value, imageInfo) => {
        const modifiedImageList = bannerInfoValues["imageList"];
        let modifiedImageId = modifiedImageList.findIndex(
            (element) => element.id === imageInfo.id
        );
        modifiedImageList[modifiedImageId]["caption"] = value;
        setBannerInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                imageList: modifiedImageList,
            };
        });
    };

    const handleRemoveImage = (imageInfo) => {
        setBannerInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                imageList: bannerInfoValues["imageList"].filter(
                    (element) => element !== imageInfo
                ),
                deleteImageList: [...prevInfo["deleteImageList"], imageInfo],
            };
        });
    };

    const handleCancleUpdate = () => {
        navigate("/banners", { replace: true });
    };

    const handleUpdateBanner = async (event) => {
        event.preventDefault();
        const infoValue = {
            name: bannerInfoValues.name,
            banner_type: bannerInfoValues.banner_type,
            is_active: bannerInfoValues.is_active,
            remark: bannerInfoValues.remark,
            imageList: bannerInfoValues.imageList,
        };

        const checkFormValues = checkFormValidate(infoValue);
        setErrorInfoList(checkFormValues);
        if (Object.keys(checkFormValues).length === 0 && name.length < 100) {
            setUpdateLoading(true);
            const body = new FormData();
            for (let i = 0; i < imageList?.length; i++) {
                if (imageList[i].status === "uploaded") {
                    body.append(
                        `update_banner_media_id[${i}]`,
                        imageList[i]?.id
                    );
                    body.append(`update_caption[${i}]`, imageList[i]?.caption);
                } else {
                    body.append(`image[${i}]`, imageList[i]?.file);
                    body.append(`image_caption[${i}]`, imageList[i]?.caption);
                }
            }

            for (let i = 0; i < deleteImageList?.length; i++) {
                body.append(
                    `delete_banner_media_id[${i}]`,
                    deleteImageList[i]?.id
                );
            }
            Object.keys(bannerInfoValues).map((element) =>
                body.append(element, bannerInfoValues[element])
            );
            body.append("id", bannerId);
            const bannerURL = `${apiServer.banner.update}`;

            try {
                const { data } = await post(bannerURL, body);
                setUpdateLoading(false);
                if (data.status >= 400) {
                    setUpdateError(data.message);
                } else {
                    if (data.status === "success") {
                        navigate("/banners", {
                            replace: true,
                            state: { status: "success", message: data.message },
                        });
                    } else if (data.code >= 400) {
                        const responseErrList = Object.values(data)[0];
                        setUpdateError(responseErrList[0]);
                    }
                }
            } catch (error) {
                setUpdateLoading(false);
                setUpdateError(error.message);
            }
        }
    };

    if (getInfoLoading)
        return <SquareLoading text="Retrieving Information..." />;

    if (getInfoError) return <EmptyPage text={getInfoError} />;

    if (ipdateLoading)
        return <PopupLoading text="Updating Banner Information..." />;

    return (
        <>
            <BannerForm
                formTitle={"Update Banner"}
                onSubmitName="Update"
                onCreate={handleUpdateBanner}
                onCancel={handleCancleUpdate}
                onChangeValue={handleChangeInput}
                onUploadImg={handleUploadImg}
                onRemoveImg={handleRemoveImage}
                infoValues={bannerInfoValues}
                errorValues={errorInfoList}
                onChangeCaption={handleChangeCaption}
            />

            {updateError !== "" && (
                <AdaAlert type="error" message={updateError} />
            )}
        </>
    );
};

export default UpdateBanner;
