import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        reverse: true
      },
    //   title: {
    //     display: true,
    //     text: "Product Overview"
    //   }
    },
    scales: {
      y: {  
        ticks: {
          color: "#696969", 
          font: {
            size: 14, 
          },
        }
      },
      x: { 
        ticks: {
          color: "#696969", 
          font: {
            size: 14
          },
        }
      }
    },
    layout: {
      padding: {
        bottom: 25
      }
    }
    
};

const BarChart = ({ data, customizeOption }) => {
  return (
    <Bar options={{...options, customizeOption}} data={data} height={470} />
  )
}

export default BarChart