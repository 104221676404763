import React from 'react'
import { IoAddSharp } from "react-icons/io5";


const CreateButton = ({onCreate, disabled}) => {
  return (
    <button className="create-button" onClick={onCreate} disabled={disabled}>
        <IoAddSharp size='18px' />&nbsp;Create
    </button>
  )
}

export default CreateButton