import React from "react";
import { useState } from "react";
// import { AuthContext } from "../context/AuthProvider";
import { FaUserCircle } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import "../css/headerBar.sass";
import { post } from "../utils/api";
import apiServer from "../constants/apiServer";
import { Navigate, useNavigate } from "react-router-dom";
import AdaAlert from "./AdaAlert";
import PopupLoading from "./PopupLoading";
import logo from "../image/ada-logo.svg";


const HeaderBar = () => {

  const navigate = useNavigate();

  // const { auth } = useContext(AuthContext);
  const [openProfile, setOpenProfile] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const adminInfo = JSON.parse(localStorage.getItem('admin'));

  const handleLogout = async() => {
    try {
      const { data } = await post(apiServer.auth.logout);
      if(data.status > 400){
        console.log(data.message);
        setError(data.message)
        setTimeout(()=>{
          setError('');
        },[3000])
      }else{
        if (data.code === 200) {
          setLoading(true);
          localStorage.removeItem('token');
          localStorage.removeItem('admin');
          setTimeout(()=> {
            setLoading(false);
            
            setSuccess(data.message);
          },3000)
          
        }
      }
      
    } catch (err) {
      console.log("error ", err.message);
    }
  };

  const handleRouteDashboard = () => {
    navigate('/dashboard', { replace: true })
  }

  console.log("error:::",error)

  // if(loading) return <PopupLoading text={"Logging out..."} />

  return (
    <>
      {success && <Navigate to="/login" replace="true" />}
      <div className="ada-admin-header-bar">
        <div className="ada-admin-logo" onClick={handleRouteDashboard}>
          <img src={logo} alt='ada-logo' />
        </div>
        <div className="ada-admin-header-admin-profile">
          <div
            className="ada-admin-header-admin-profile-name"
            onClick={() => {
              setOpenProfile(!openProfile);
            }}
          >
            <FaUserCircle size="20px" />
            &nbsp;{adminInfo?.name}
          </div>
          {openProfile && (
            <div className="ada-admin-header-profile-dropdown">
              <button>
                <CgProfile size="20px" />
                &nbsp;Profile
              </button>
              <button onClick={handleLogout}>
                <BiLogOut size="20px" />
                &nbsp;Logout
              </button>
            </div>
          )}
        </div>
      </div>
      {error && <AdaAlert type={'error'} message={error} />}
      {loading && <PopupLoading text={"Logging out..."} />}
    </>
  );
};

export default HeaderBar;
