import React from 'react'
import "../css/common.sass"


const ReactLoading = ({ text }) => {
  return (
    <div className='main-loading-container'>
        <div className='loading-container'>
            <div className='react-loading' />&nbsp;{text}
        </div>
    </div>
  )
}

export default ReactLoading