export const checkFormValidate = (infoValue) => {
    const errorState = {};
    for(let key in infoValue){
        if(!(key in infoValue)){
            errorState[key] = 'This is mandatory field'
        }
    }
    return errorState
}

export const unitConversion = ( value, initUnit, resultUnit ) => {
    let convertedValue = 0;
    switch(initUnit){
        case "cm": {
            switch(resultUnit) {
                case "inch": {
                    convertedValue = (value * 2.54).toFixed(3);
                    break;
                }
                default: {
                }
            }
            break;
        }
        case "inch" : {
            switch(resultUnit) {
                case "cm": {
                    convertedValue = (value * 0.393701).toFixed(3);
                    break;
                }
                default: {
                }
            }
            break;
        }
        case "kg" : {
            switch(resultUnit) {
                case "lb": {
                    convertedValue = (value * 0.393701).toFixed(3);
                    break;
                }
                default: {
                }
            }
            break;
        }
        case "lb" : {
            switch(resultUnit) {
                case "kg": {
                    convertedValue = Math.trunc(value * 0.453592);
                    break;
                }
                default: {
                }
            }
            break;
        }
        default : {
            convertedValue=  value;
            break;
        }
    }
    return convertedValue;

}

export const sortArray = (array) => {
    array.sort((a, b) => b - a);

    return array;

}

export const StatusCode = {
    200 : "success",
    400 : "error",
    403 : "error"
}

export const DeleteRow = (dataArr, id) => {

    const index = dataArr.findIndex((element) => element.id === `${id}`);
    const modifiedArr = dataArr.filter((element) => element !== dataArr[index]);
    return modifiedArr;
    
}

