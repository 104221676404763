import React from 'react'
import DropBox from "../../common/DropBox";
import { Scrollbars } from "react-custom-scrollbars";
// import { BiEditAlt } from "react-icons/bi";
import Tooltip from "@mui/material/Tooltip";
import { MdDeleteForever } from "react-icons/md";
import "../../css/process.sass";

const UploadProcessImage = ({ onUpload, processInfo, onRemove, onChangeCaption }) => {

  const { imageList } = processInfo;


  return (
    <div className='create-process-form'>
      <DropBox onUpload={onUpload} />
      {
        (imageList.length > 0 ) && (
          <ProcessImageList imageList={imageList} onRemove={onRemove} onChangeCaption={onChangeCaption} />
        )
      }
    </div>
  )
}

const ProcessImageList = ({ imageList, onRemove, onChangeCaption }) => {
  return (
    <div className='process-upload-image-list-container'>
      <Scrollbars autoHide autoHideTimeout={200} autoHideDuration={200} style={{height: 'calc(100vh - 450px)'}}>
        {
          imageList.map((imageInfo, imageIndex) => 
            <ProcessImage key={imageIndex} imageInfo={imageInfo} onAddCaption={onChangeCaption}  onDeleteImage={() => onRemove(imageInfo)} isFlag= { imageInfo?.caption?.length > 250 }  errMsg={"Caption must not exceed 250 words"} />
          )
        }
      </Scrollbars>
    </div>

  )
}

const ProcessImage = ({imageInfo, onAddCaption, onDeleteImage, isFlag, errMsg}) => {

  const handleChangeText = (event) => {
    const { value } = event.target;
    onAddCaption(value, imageInfo)
  }

  
  return(
    <div style={{position: 'relative', marginBottom: "8px"}}>
       <div className="process-upload-image-container">
        <img src={imageInfo?.src} alt='process-img'  />
        <div className="process-upload-image-caption">
          <input type='text' value={imageInfo?.caption} onChange={handleChangeText} name='caption' placeholder='Add Caption...' />
        </div>
        <div className='process-upload-image-action-container'>
          <Tooltip title='Delete Image'>
            <button className='delete-image' onClick={onDeleteImage}><MdDeleteForever size='24px'/></button>
          </Tooltip>
        </div>  
      </div>
      {
        isFlag && <div className='error-message vertical-layout'>{errMsg}</div>
      }
    </div>

  ) 
}



export default UploadProcessImage