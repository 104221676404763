import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FormActionRow from "../../common/FormActionRow";
import "../../css/product.sass";
import DetailHeaderBar from "../../common/DetailHeaderBar";
import { Scrollbars } from "react-custom-scrollbars";
import GeneralInformation from "./GeneralInformation";
import { checkFormValidate } from "../../utils/commonFuncs";
import DetailedInformation from "./DetailedInformation";
import UploadMedia from "./UploadMedia";
import apiServer from "../../constants/apiServer";
import moment from "moment";
import { format } from "date-fns";
import { post } from '../../utils/api';
import { useNavigate } from "react-router-dom";
import PopupLoading from "../../common/PopupLoading";
import AdaAlert from "../../common/AdaAlert";
import { stringify } from "query-string";
import { unitConversion } from "../../utils/commonFuncs";

const productSteps = ["General Info", "Detailed Info", "Images & Videos"];

const CreateProduct = () => {

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const [generalInfoValues, setGeneralInfoValues] = useState({
    productEngName: "",
    productMyanName: "",
    productCode: "",
    chosenCategory: "",
    currency: 1,
    price: "",
    quantity: "",
    engDescription: "",
    myanDescription: "",
    creationDateRange: {
      startDate: moment(new Date()),
      endDate: moment(new Date())
    },
    releasedDate: new Date(),
    isLimited: "1",
    isAvailable: "1",
    size: 0,
    sizeUnit: "inch",
    length: 0,
    lengthUnit: "inch",
    weight: 0,
    weightUnit: "lb",
    warranty: "",
    mediaList: []
  });

  const [generalInfoError, setGeneralInfoError] = useState({});
  const [creationDate, setCreationDate] = useState({});
  const [disableCreate, setDisableCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorCreate , setErrorCreate ] = useState('');

  useEffect(() => {
    if (activeStep === 2) {
      if (generalInfoValues.mediaList.length === 0) {
        setDisableCreate(true);
      }
    }
    setDisableCreate(false);
  }, [activeStep, generalInfoValues]);

  useEffect(() => {
    setCreationDate({
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD")
    })
  }, [])


  const handleNext = () => {
    if (activeStep === 0) {
      const infoValue = {
        productEngName: generalInfoValues.productEngName,
        productMyanName: generalInfoValues.productMyanName,
        productCode: generalInfoValues.productCode,
        chosenCategory: generalInfoValues.chosenCategory,
        currency: generalInfoValues.currency,
        price: generalInfoValues.price,
        quantity: generalInfoValues.quantity,
        engDescription: generalInfoValues.engDescription,
        myanDescription: generalInfoValues.myanDescription
      };
      const checkGeneralInfo = checkFormValidate(infoValue);
      setGeneralInfoError(checkGeneralInfo);
      const errorGeneralInfoValue = Object.keys(checkGeneralInfo);
      if (errorGeneralInfoValue.length === 0 && generalInfoValues.productEngName?.length < 100 && generalInfoValues.productMyanName?.length < 100 && generalInfoValues.productCode?.length < 250) {
        setActiveStep((prevStep) => prevStep + 1);
      }
    } else if (activeStep === 1) {
      const detailedInfoValue = {
        creationDateRange: generalInfoValues.creationDateRange,
        releasedDate: generalInfoValues.releasedDate,
        isLimited: generalInfoValues.isLimited,
        isAvailable: generalInfoValues.isAvailable
      };
      const productWarranty = generalInfoValues.warranty;
      // const productSize = generalInfoValues.size;
      // const productLength = generalInfoValues.length;
      // const productWeight = generalInfoValues.weight;
      const checkDetailedInfo = checkFormValidate(detailedInfoValue);
      setGeneralInfoError(checkDetailedInfo);
      const errorGeneralInfoValue = Object.keys(checkDetailedInfo);
      if (errorGeneralInfoValue.length === 0 && productWarranty.length < 250) {
        setActiveStep((prevStep) => prevStep + 1);
      }
      if(generalInfoValues.mediaList.length === 0){
        setDisableCreate(true)
      }
    } else if (activeStep === 2) {
      handleSubmit();
    }
  };

  const handleChangeDate = (event) => {
    console.log("change date:::", event);
    setGeneralInfoValues((generalInfo) => {
      return {
        ...generalInfo,
        releasedDate: event
      };
    });
  };

  const handleUploadMedia = (mediaInfo) => {
    setGeneralInfoValues((generalInfo) => {
      return {
        ...generalInfo,
        mediaList: [
          ...generalInfo['mediaList'],
          mediaInfo
        ]
      };
    });
  };

  const handleChangeDateRange = (start, end) => {
    setGeneralInfoValues((generalInfo) => {
      return {
        ...generalInfo,
        releasedDate: new Date(moment(end).format("YYYY-MM-DD")),
        creationDateRange: {
          startDate: start,
          endDate: end
        }
      };
    });

    setCreationDate({
      startDate: moment(start).format("YYYY-MM-DD"),
      endDate: moment(end).format("YYYY-MM-DD")
    });
  };

  const handleRemoveMediaInfo = (mediaInfo) => {
    setGeneralInfoValues((generalInfo) => {
      return {
        ...generalInfo,
        mediaList: generalInfoValues["mediaList"].filter(
          (element) => element !== mediaInfo
        )
      };
    });
  };

  const handleChangeSelect = (name, value) => {
    setGeneralInfoValues((prevInfo) => {
      return {
        ...prevInfo,
        [name]: value
      };
    });
  };

  const handleSubmit = async () => {
   
    const {
      productEngName,
      productMyanName,
      productCode,
      chosenCategory,
      currency,
      price,
      quantity,
      engDescription,
      myanDescription,
      releasedDate,
      isLimited,
      isAvailable,
      size,
      sizeUnit,
      length,
      lengthUnit,
      weight,
      weightUnit,
      warranty,
      mediaList
    } = generalInfoValues;

    if(mediaList.length > 0){
      setLoading(true);

      const productSize = size !== "" ? size+" "+sizeUnit : ''
      const productLength = length !== "" ? length+" "+lengthUnit : '';
      const productWeight = weight !== "" ? weight+" "+weightUnit : '';
      const releasedAt = format(releasedDate, "yyyy-MM-dd");
      const imgList = new FormData();
      for (let i = 0; i < mediaList.length; i++) {
        imgList.append(`images[${i}]`, mediaList[i].file);
      }
      // imgList.append(`images[${mediaList[0]}]`, mediaList[0].file);
      console.log("create product product media::", mediaList, imgList)
      console.log("creation date::", creationDate);
  
      const query = {
        nameEN:  productEngName,
        nameMM: productMyanName,
        history_from: creationDate.startDate,
        history_to: creationDate.endDate,
        released_at: releasedAt,
        catalog: chosenCategory.value,
        product_code: productCode,
        price: price,
        currency: currency,
        qty: quantity,
        is_limited: isLimited,
        is_available: isAvailable,
        size: productSize,
        weight: productWeight,
        length: productLength,
        warranty: warranty,
        descriptionEN: engDescription,
        descriptionMM: myanDescription
      }
  
      const productCreateURL = `${apiServer.product.create}?${stringify(query)}`;
      try{
        const { data } = await post(productCreateURL, imgList);
        setLoading(false);
        if(data.status >= 400){
          setErrorCreate(data.message)
        }else {
          if(data.status === 'success'){
            navigate("/products", { replace: true, state: { status: "success", message: data.message } });
          }else if(data.code >= 400 ){
            setErrorCreate("Please fill all mandatory field")
          }
        }
      }catch(error){
        setLoading(false);
        setErrorCreate(error.message)
      }
    }else{
      setErrorCreate("Please upload product image")
    }
  
  };

  const handleChangeUnit = (event) => {
    const { name, value } = event.target;
    const unitValue = name === "sizeUnit" ? 'size' : name === "lengthUnit" ? 'length' : name === 'weightUnit' ? 'weight' : "";
    const initalUnit = value === "inch" ? "cm" : value === "cm" ? "inch" : value === "lb" ? "kg" : value === "kg" ? "lb" : '';
    setGeneralInfoValues((prevInfo) => {
      return{
        ...prevInfo,
        [name] : value,
        [unitValue]: generalInfoValues[unitValue] !== "" && unitConversion( generalInfoValues[unitValue], initalUnit, value)
      }
    })
  }

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setGeneralInfoValues((generalInfo) => {
      return {
        ...generalInfo,
        [name]: value
      };
    });
    delete generalInfoError[name];
    setGeneralInfoError(generalInfoError);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep <= 0 ? 0 : prevActiveStep - 1
    );
  };

  if( loading ) return <PopupLoading text="Creating Product..." />
  
 
  return (
    <>
      <div className="create-artist-page-section">
        <DetailHeaderBar navigateRoute={"/products"} name="Create Product" />
        <div className="create-product-page-section">
          <div className="create-product-page-stepper-container">
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {productSteps.map((label, index) => {
                  // const stepProps = {};
                  return (
                    <Step key={index}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </div>
          <Scrollbars
            style={{ height: "calc(100vh - 200px)" }}
            autoHide
            autoHideTimeout={200}
            autoHideDuration={200}
          >
            {activeStep === 0 ? (
              <GeneralInformation
                states={generalInfoValues}
                onChange={handleChangeInput}
                errorStates={generalInfoError}
                onChangeSelect={handleChangeSelect}
              />
            ) : activeStep === 1 ? (
              <DetailedInformation
                states={generalInfoValues}
                onChange={handleChangeInput}
                onChangeDateRange={handleChangeDateRange}
                onChangeDate={handleChangeDate}
                minReleaseDate={creationDate["endDate"]}
                onChangeUnit={handleChangeUnit}
              />
            ) : (
              <UploadMedia
                mediaData={generalInfoValues}
                onChange={handleUploadMedia}
                onRemove={handleRemoveMediaInfo}
              />
            )}
          </Scrollbars>
          <FormActionRow
            submitBtnName={
              activeStep === productSteps.length - 1 ? "Create" : "Next"
            }
            cancelBtnName="Back"
            disableCancelBtn={activeStep === 0}
            onSubmit={handleNext}
            disableSubmitBtn={disableCreate}
            onCancel={handleBack}
          />
        </div>
      </div>
      {errorCreate !== "" && <AdaAlert type="error" message={errorCreate} />}
    </>
  );
};

export default CreateProduct;
