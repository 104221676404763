import React from 'react'
import "../../css/common.sass"
import "../../css/operator.sass"
import DetailHeaderBar from '../../common/DetailHeaderBar'
import InputField from '../../common/InputField'
import FormActionRow from '../../common/FormActionRow'

const OperatorForm = ({ onCreate, onCancel, formTitle, onSubmitName, onChangeValue, infoValues, errorValue, onShowPassword }) => {

    const { email, password, name, phone, showPassword } = infoValues;

  return (
    <div className="create-operator-container">
        <DetailHeaderBar navigateRoute={"/operators"} name={formTitle} />
        <div className="create-operator-form-container">
          <form className="create-operator-form" onSubmit={onCreate}>
            <InputField
              title="Email"
              type="email"
              name="email"
              value={email}
              width="250px"
              id="operator_create"
              isRequired
              isFlag={'email' in errorValue || email?.length > 200}
              errMsg={email?.length > 200 ? "Must not exceed more than 200 words" : errorValue['email']}
              onChange={onChangeValue}
            />
            <InputField
              title="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              width="250px"
              isRequired
              id="operator_password"
              fieldType="password"
              isFlag={'password' in errorValue || password?.length > 200}
              errMsg={password?.length > 200  ? "Must not exceed more than 200 words" : errorValue['password']}
              onShowPassowrd={onShowPassword}
              onChange={onChangeValue}
            />
            <InputField
              title="Name"
              type="text"
              name='name'
              value={name}
              width="250px"
              id="operator_name"
              isRequired
              isFlag={'name' in errorValue}
              errMsg={errorValue?.name}
              onChange={onChangeValue}
            />
            <InputField
              title="Phone"
              type="text"
              id="operator_phone"
              name="phone"
              value={phone}
              width="250px"
              onChange={onChangeValue}
            />
            <FormActionRow submitBtnName={onSubmitName} onCancel={onCancel} />
          </form>
        </div>
      </div>
  )
}

export default OperatorForm