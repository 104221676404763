import React, { useState, useEffect } from "react";
import apiServer from "../../constants/apiServer";
import Loading from "../../common/Loading";
import { post } from "../../utils/api";
import AdaAlert from "../../common/AdaAlert";
import PopupLoading from "../../common/PopupLoading";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import useFetchInfo from "../../utils/useFetchInfo";
import { checkFormValidate } from "../../utils/commonFuncs";
import { stringify } from "query-string";
import ArtistForm from "./ArtistForm";
import "../../css/artist.sass";
import EmptyPage from "../../common/EmptyPage";

const CreateArtist = () => {
    const navigate = useNavigate();

    const [payload, setPayload] = useState({
        profile: null,
        nameEN: "",
        nameMM: "",
        type: [],
        chosenType: {},
        artisttype_id: 0,
        dob: new Date("1800-1-1"),
        gender: "Male",
        bioEN: "",
        bioMM: "",
    });

    const [errorValues, setErrorValues] = useState({});

    const [getInfoLoading, setGetInfoLoading] = useState(true);
    const [artistCreateLoading, setArtistCreateLoading] = useState(false);
    const [errorCreateArtist, setErrorCreateArist] = useState("");

    const [getInfoError, setGetInfoError] = useState("");

    const { data, isLoading, error } = useFetchInfo(apiServer.artists.filter);

    useEffect(() => {
        setGetInfoLoading(isLoading);
        setGetInfoError(error);
        const modifyData = [];

        data.forEach((element) => {
            const artistTypeName = element["name"];
            modifyData.push({
                value: element?.id,
                label: `${artistTypeName?.en} : ${artistTypeName?.mm}`,
            });
        });

        setPayload((prevInfo) => {
            return {
                ...prevInfo,
                type: modifyData,
                chosenType: modifyData[0],
                artisttype_id: modifyData[0]?.value,
            };
        });
    }, [data, isLoading, error]);

    const handleUploadImage = (imageInfo) => {
        setPayload((prevInfo) => {
            return {
                ...prevInfo,
                profile: imageInfo,
            };
        });
        delete errorValues["profile"];
        setErrorValues(errorValues);
    };

    const handleChangeDate = (date) => {
        const modifiedDate = new Date(date);
        setPayload((prevInfo) => {
            return {
                ...prevInfo,
                dob: modifiedDate,
            };
        });
    };

    const handleChangeSelect = (name, value) => {
        setPayload((prevInfo) => {
            return {
                ...prevInfo,
                [name]: value,
            };
        });
    };

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setPayload((prevInfo) => {
            return {
                ...prevInfo,
                [name]: value,
            };
        });
        delete errorValues[name];
        setErrorValues(errorValues);
    };

    const handleRemoveImage = () => {
        setPayload((prevInfo) => {
            return {
                ...prevInfo,
                profile: null,
            };
        });
    };

    const handleCreateArtist = async (event) => {
        event.preventDefault();
        const checkGeneralInfo = checkFormValidate({
            profile: payload["profile"],
            nameEN: payload["nameEN"],
            nameMM: payload["nameMM"],
            chosenType: payload["chosenType"],
            bioEN: payload["bioEN"],
            bioMM: payload["bioMM"],
            dob: payload["dob"],
            gender: payload["gender"],
        });
        setErrorValues(checkGeneralInfo);
        const errorStateList = Object.keys(checkGeneralInfo);
        const { nameEN, nameMM } = payload;
        if (
            errorStateList?.length === 0 &&
            nameEN?.length < 100 &&
            nameMM?.length < 100
        ) {
            setArtistCreateLoading(true);
            // const query = {
            //     nameEN: engName,
            //     nameMM: myanName,
            //     artisttype_id: chosenType["value"],
            //     bioEN: engBio,
            //     bioMM: myanBio,
            //     dob: format(dob, "yyyy-MM-dd"),
            //     gender: gender === "Male" ? 0 : 1,
            // };
            const fd = new FormData();
            fd.append("image", payload?.profile);
            Object.keys(payload).map((element) =>
                element === "gender"
                    ? fd.append("gender", payload["gender"] === "Male" ? 0 : 1)
                    : element === "dob"
                    ? fd.append("dob", format(payload["dob"], "yyyy-MM-dd"))
                    : fd.append(element, payload[element])
            );

            //create artist api url
            const createArtistURL = `${apiServer.artists.create}`;
            try {
                const { data } = await post(createArtistURL, fd);
                setArtistCreateLoading(false);
                if (data.status >= 400) {
                    setErrorCreateArist(data.message);
                } else {
                    if (data.code >= 400) {
                        setErrorCreateArist("Please fill all the data");
                    } else if (data.status === "success") {
                        navigate("/artists", {
                            replace: true,
                            state: { status: "success", message: data.message },
                        });
                    }
                }
            } catch (err) {
                setArtistCreateLoading(false);
                setErrorCreateArist(err.message);
            }
        }
    };

    const handleCancelCreate = () => {
        navigate("/artists", { replace: true });
    };

    if (getInfoLoading) return <Loading text={"Loading..."} />;

    if (getInfoError) return <EmptyPage text={getInfoError} />;

    if (artistCreateLoading) return <PopupLoading text="Creating Artist..." />;

    return (
        <>
            <ArtistForm
                formTitle={"Create Artist"}
                onChangeValue={handleChangeValue}
                onUploadProfile={handleUploadImage}
                onRemoveProfile={handleRemoveImage}
                onChangeDate={handleChangeDate}
                onSelectValue={handleChangeSelect}
                infoValues={payload}
                errorValues={errorValues}
                onCreate={handleCreateArtist}
                onCreateCancel={handleCancelCreate}
                formSubmitName="Create"
            />

            {errorCreateArtist !== "" && (
                <AdaAlert type="error" message={errorCreateArtist} />
            )}
        </>
    );
};

export default CreateArtist;
