import React, { useEffect, useState } from 'react'
import InputField from '../../common/InputField'
import DetailHeaderBar from '../../common/DetailHeaderBar'
import FormActionRow from '../../common/FormActionRow'
import apiServer from "../../constants/apiServer";
import { post } from '../../utils/api';
import SquareLoading from "../../common/SquareLoading";
import EmptyPage from '../../common/EmptyPage';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdaAlert from '../../common/AdaAlert';
import PopupLoading from "../../common/PopupLoading";
import useFetchInfo from "../../utils/useFetchInfo";
import { useLocation } from 'react-router-dom';


const EditOperator = () => {
 
  const { operatorId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reTypePassword, setReTypePassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [infoLoading, setInfoLoading] = useState(true);
  const [editLoading, setEditLoading] = useState(false);
  

  const [errorEmail, setErrorEmail] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [isSamePassword, setIsSamePassword] = useState(false);
  const [getInfoError, setGetErrorInfo] = useState('');
  const [errorEdit, setErrorEdit] = useState('');

  const { data, isLoading, error } = useFetchInfo(`${apiServer.operator.getDetail}?operator_id=${operatorId}`);


  useEffect(()=>{

    setIsSamePassword(newPassword === reTypePassword)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reTypePassword])

  // const GetOperatorDetail = async() => {
  //   try{
  //     const { data } = await post(`${apiServer.operator.getDetail}?operator_id=${operatorId}`);
  //     console.log("operator detail info::", data);
  //     setInfoLoading(false);
  //     if(data.status > 400){
  //       setGetErrorInfo(data.message)
  //     }else{
  //       if(data.code === 200) {
  //         setEmail(data.data.email)
  //         setName(data.data.name)
  //         setPhone(data.data.phone)
  //       }else if(data.code === 400){
  //         setGetErrorInfo(data.message)
  //       }
  //     }
  //   }catch(error){
  //     setInfoLoading(false);
  //     setGetErrorInfo(error)
  //   }
    
  // };

  useEffect(()=> {
    setInfoLoading(isLoading);
    setEmail(data.email);
    setName(data.name);
    setPhone(data.phone);
    setGetErrorInfo(error);
  },[data, isLoading, error])


  const handleEdit = async(event) => {
    event.preventDefault();
    setEditLoading(true);
    if(email !== "" && name !== "" && email !== "" && newPassword !== "") {
      try{
        const { data } = await post(`${apiServer.operator.update}?operator_id=${operatorId}&name=${name}&email=${email}&password=${newPassword}&phone=${phone}&operator_role=1`);
        setEditLoading(false);
        if(data.status > 400){
          setErrorEdit(data.message);
        }else{
          if(data.code === 200){
            navigate('/operators', { replace: true, state: { status: "success", message : data.message } })
          }else if(data.code >= 400){
            setErrorEdit("Operator id should not be null");
          }
        }
      }catch(error){
        setErrorEdit(error.message);
      }
    }else{
      if(email === ""){
        setErrorEmail("Email is mandatory")
      }
      if(name === ""){
        setErrorName("Name is mandatory")
      }
      if(newPassword === ""){
        setErrorPassword("Password is mandatory")
      }
    }
    
    
  }

  if(infoLoading) return <SquareLoading text="Retrieving Data" />

  if(getInfoError) return <EmptyPage text="Cannot get operator information" />

  return (
    <>
      <div className="create-operator-container">
        <DetailHeaderBar navigateRoute={"/operators"} name="Update Operator" />
        <div className="create-operator-form-container">
          <form className="create-operator-form" onSubmit={handleEdit}>
            <InputField
              title="Email"
              type="email"
              value={email}
              width="250px"
              id="operator_create"
              isRequired
              isFlag={errorEmail !== ''}
              errMsg={errorEmail}
              onChange={(event) => {setEmail(event.target.value)}}
            />
            <InputField
              title="Name"
              type="text"
              value={name}
              width="250px"
              id="operator_name"
              isRequired
              isFlag={errorName !== ""}
              errMsg={errorName}
              onChange={(event) => {setName(event.target.value)}}
            />
            <InputField
              title="Phone"
              type="text"
              id="operator_phone"
              value={phone}
              width="250px"
              onChange={(event) => {setPhone(event.target.value)}}
            />
            <InputField
              title="New Password"
              type='password'
              isRequired
              value={newPassword}
              width="250px"
              isFlag={errorPassword !== ''}
              errMsg= {errorPassword}
              id="operator_new_password"
              onChange={(event) => {setNewPassword(event.target.value)}}
            />
            <InputField
              title="Re-Type Password"
              type='password'
              isRequired
              value={reTypePassword}
              width="250px"
              id="operator_retype_password"
              isFlag={!isSamePassword}
              errMsg={"Doesn't match password"}
              onChange={(event) => {setReTypePassword(event.target.value)}}
            />
            <FormActionRow submitBtnName="Update" onCancel={()=>{ navigate('/operators', { replace: true })} } />
          </form>
        </div>
      </div>
      {editLoading && <PopupLoading text={"Modifying Operator Information..."} />}
      {errorEdit && <AdaAlert type="error" message={errorEdit} />}
    </>
  )
}

export default EditOperator