import React from 'react'
import "../../css/dashboard.sass";
import { HiOutlineExternalLink } from "react-icons/hi";

const DashboardCard = ({ title, children, className, onRoute, headerAction }) => {
  return (
    <div className={`dashboard-card-container ${className}`}>
        <div className='dashboard-card-header'>
            <div className='dashboard-card-header-left-side'>
              <h4>{title}</h4>
              {
                onRoute !== undefined && <button onClick={onRoute}><HiOutlineExternalLink size='18px'/></button>
              }
            </div>
            <div className='dashboard-card-header-right-side'>
              {headerAction}
            </div>
        </div>
        <div className='dashboard-card-body'>
            {children}
        </div>
      
    </div>
  )
}

export default DashboardCard