import React from 'react'

const artist_type = ({size}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
        {/* <rect width="24" height="24" fill="white"/> */}
        <path d="M20.7576 10.0001C19.8501 10.0042 16.5386 10.1926 14.0752 12.5938C13.0402 13.5197 12.4077 14.5719 11.9999 15.5582C11.5921 14.5719 10.9596 13.5197 9.92461 12.5938C7.46121 10.1926 4.14968 10.0042 3.2422 10.0001C3.10438 9.99947 2.99876 10.1063 3.00001 10.2445C3.0072 11.117 3.22313 14.1863 5.77404 16.4725C8.39963 19.0294 10.9999 19 11.9999 19C12.9999 19 15.5995 19.0297 18.2258 16.4725C20.777 14.186 20.9926 11.117 20.9998 10.2445C21.001 10.1063 20.8954 9.99947 20.7576 10.0001ZM11.9993 13.4563C12.3999 12.8672 12.8618 12.3382 13.3771 11.8776C13.9736 11.296 14.6139 10.837 15.2602 10.4657C14.7464 8.26262 13.6439 6.30795 12.2374 5.08391C12.109 4.97203 11.893 4.97203 11.7643 5.08391C10.3584 6.30702 9.25587 8.2598 8.74119 10.461C9.37775 10.8266 10.0068 11.2779 10.5918 11.8482C11.1243 12.3253 11.5969 12.8653 11.9993 13.4563V13.4563Z" fill={'inherit'}/>
    </svg>
  )
}

export default artist_type