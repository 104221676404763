import axios from 'axios';
import { base_api_url } from '../constants/api';


const apiClient = axios.create({
    baseURL : base_api_url,
    headers: new Headers({
        "Accept": "application/json",
        "Content-type": "application/json"
    })
});



export const get = async(url, headers = {}, jwt_token = localStorage.getItem('token')) => {
    headers = { ...headers, Authorization: `Bearer ${jwt_token}` };
    try{
        return await apiClient.get(url, { headers })
    }catch(err){
        console.log(err)
    }
}

export const post = async(url, body, headers, jwt_token = localStorage.getItem('token') ) => {

    headers = { ...headers, Authorization: `Bearer ${jwt_token}`};
    try{
        return await apiClient.post(url, body, { headers });
    }catch(err){
        console.log(err)
    }
}

export const put = async(url, body, headers = {}, jwt_token = localStorage.getItem('token') ) => {
    headers = { ...headers, Authorization: `Bearer ${jwt_token}` };

    try{
        return await apiClient.put(url, body, {headers})
    }catch(err){
        console.log(err)
    }
}

export const del = async(url, headers = {}, jwt_token = localStorage.getItem('token')) => {
    headers = { ...headers, Authorization: `Bearer ${jwt_token}` };
    try{
        return await apiClient.del(url, { headers })
    }catch(err){
        console.log(err)
    }

}