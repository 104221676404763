import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom"
      },
    //   title: {
    //     display: true,
    //     text: "Product Overview"
    //   }
    }
};

export function DoughnutChart ({ data }) {
    return <Doughnut options={options} data={data} />
}