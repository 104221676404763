import React from 'react';
import "../../css/process.sass";
import "../../css/artist.sass";
import DetailHeaderBar from '../../common/DetailHeaderBar';
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Scrollbars } from "react-custom-scrollbars";
import GeneralProcessInfo from "../Process/GeneralProcessInfo";
import DetailedProcessInfo from "../Process/DetailedProcessInfo";
import UploadProcessImage from "../Process/UploadProcessImage";
import FormActionRow from "../../common/FormActionRow";
import AdaAlert from "../../common/AdaAlert";


const processSteps =  ['General Info', 'Detailed Info', 'Upload Images'];

const ProcessForm = ({ 
    title, 
    processInfoValues, 
    handleInput, 
    errorValueList, 
    onUploadImg, 
    handleChangeStartDate, 
    handleChangeEndDate, 
    handleChooseValue, 
    handleAddValues,
    handleRemoveValues, 
    onRemoveImage,
    onChangeCaption,
    activeStep, 
    handleNext, 
    handleBack, 
    errorMessage
}) => {

  return (
    <div>
      <div className='create-artist-page-section'>
        <DetailHeaderBar navigateRoute={'/processes'} name={title + ' Process'} />
        <div className='create-process-page-section'>
          <div className='create-process-page-stepper-container'>
            <Box sx={{width: '100%'}}>
              <Stepper activeStep={activeStep}>
                {
                  processSteps.map((label, index) => {
                    return (
                      <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    )
                  })
                }
              </Stepper>
            </Box>
          </div>
          <Scrollbars
            style={{ height: 'calc(100vh - 200px)'}}
            autoHide 
            autoHideTimeout={200}
            autoHideDuration={200}
          > 
            {
              activeStep === 0 &&
                <GeneralProcessInfo processInfo ={processInfoValues} onChange={handleInput} errorStates={errorValueList} onChooseProduct={handleChooseValue} />
            }
            {
              activeStep === 1 && 
                <DetailedProcessInfo processInfo={processInfoValues} onChangeStartDate={handleChangeStartDate} onChangeEndDate={handleChangeEndDate} onChange={handleInput} errorStates={errorValueList} onAddArtist={handleAddValues} onRemoveArtist={handleRemoveValues} />
            }
            {
              activeStep === 2 &&
                <UploadProcessImage onUpload={onUploadImg} processInfo={processInfoValues} onRemove={onRemoveImage} onChangeCaption={onChangeCaption} />
            }
          </Scrollbars>
          <FormActionRow submitBtnName={activeStep === processSteps.length - 1 ? title : 'Next'} cancelBtnName='Back' disableCancelBtn={activeStep === 0} onSubmit={handleNext} onCancel={handleBack} />
        </div>
      </div>
      {errorMessage !== "" && <AdaAlert type="error" message={errorMessage} />}
    </div>
  )
}

export default ProcessForm