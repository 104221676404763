import React, { useState, useEffect } from "react";
import useFetchInfo from "../../utils/useFetchInfo";
import { useParams, useNavigate } from "react-router-dom";
import apiServer from "../../constants/apiServer";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FormActionRow from "../../common/FormActionRow";
import "../../css/product.sass";
import DetailHeaderBar from "../../common/DetailHeaderBar";
import { Scrollbars } from "react-custom-scrollbars";
import GeneralInformation from "./GeneralInformation";
import { checkFormValidate } from "../../utils/commonFuncs";
import DetailedInformation from "./DetailedInformation";
import UploadMedia from "./UploadMedia";
import moment from "moment";
import { format } from "date-fns";
import { post } from "../../utils/api";
import PopupLoading from "../../common/PopupLoading";
import AdaAlert from "../../common/AdaAlert";
import Loading from "../../common/Loading";
import { stringify } from "query-string";
import { unitConversion } from "../../utils/commonFuncs";
import EmptyPage from "../../common/EmptyPage";

const productSteps = ["General Info", "Detailed Info", "Images & Videos"];

const UpdateProduct = () => {
    const { productId } = useParams();

    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState(0);

    const [generalInfoValues, setGeneralInfoValues] = useState({
        productEngName: "",
        productMyanName: "",
        productCode: "",
        chosenCategory: "",
        currency: 1,
        price: "",
        quantity: "",
        engDescription: "",
        myanDescription: "",
        creationDateRange: {
            startDate: new Date(),
            endDate: new Date(),
        },
        releasedDate: new Date(),
        isLimited: "1",
        isAvailable: "1",
        size: "",
        sizeUnit: "",
        length: "",
        lengthUnit: "",
        weight: "",
        weightUnit: "",
        warranty: "",
        mediaList: [],
    });

    const [generalInfoError, setGeneralInfoError] = useState({});
    const [creationDate, setCreationDate] = useState({});
    const [disableUpdate, setDisableUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorUpdate, setErrorUpdate] = useState("");

    const [getInfoLoading, setGetInfoLoading] = useState(true);
    const [getInfoError, setGetInfoError] = useState("");

    const [deleteImgIdList, setDeleteImgIdList] = useState([]);
    // const [getInfoList, setGetInfoList] = useState([]);

    const { data, isLoading, error } = useFetchInfo(
        `${apiServer.product.detail}?id=${productId}`
    );

    // console.log("product info::", data, isLoading, error);

    useEffect(() => {
        updateProductInfo(data);
        setGetInfoLoading(isLoading);
        setGetInfoError(error);
    }, [data, isLoading, error]);

    const updateProductInfo = (productInfo) => {
        const historyFromDate = productInfo["historyfromdate"];
        const historyToDate = productInfo["historytodate"];
        const releasedDate = productInfo["releasedate"];
        const startCreatedDate = `${historyFromDate?.year}-${historyFromDate?.month}-${historyFromDate?.day}`;
        const endCreatedDate = `${historyToDate?.year}-${historyToDate?.month}-${historyToDate?.day}`;
        const productSize =
            typeof productInfo["size"] === "string" &&
            productInfo["size"]?.split(" ");
        const productLength =
            typeof productInfo["length"] === "string" &&
            productInfo["length"]?.split(" ");
        const productWeight =
            typeof productInfo["weight"] === "string" &&
            productInfo["weight"]?.split(" ");

        // const productDescription = JSON.parse(productInfo['description']);
        setGeneralInfoValues({
            productEngName: productInfo["product_name"]?.en,
            productMyanName: productInfo["product_name"]?.mm,
            productCode: productInfo["product_code"],
            chosenCategory: {
                label: `${productInfo["catalog_name"]?.en} : ${productInfo["catalog_name"]?.mm}`,
                value: productInfo["catalog_id"],
            },
            currency: productInfo["currency_id"],
            price: productInfo["price"],
            quantity: productInfo["qty"],
            engDescription: productInfo["description"]?.en,
            myanDescription: productInfo["description"]?.mm,
            creationDateRange: {
                startDate: moment(new Date(startCreatedDate)),
                endDate: moment(new Date(endCreatedDate)),
            },
            releasedDate: new Date(
                `${releasedDate?.year}-${releasedDate?.month}-${releasedDate?.day}`
            ),
            isLimited: productInfo["isLimited"],
            isAvailable: productInfo["available"],
            size: productSize.length > 0 && parseInt(productSize[0]),
            sizeUnit: productSize.length > 0 && productSize[1],
            length: productLength.length > 0 && parseInt(productLength[0]),
            lengthUnit: productLength.length > 0 && productLength[1],
            weight: productWeight.length > 0 && parseInt(productWeight[0]),
            weightUnit: productWeight.length > 0 && productWeight[1],
            warranty: productInfo["warranty"],
            mediaList: productInfo["productmedia"],
        });

        setCreationDate({
            startDate: startCreatedDate,
            endDate: endCreatedDate,
        });
    };

    const handleChangeSelect = (name, value) => {
        setGeneralInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                [name]: value,
            };
        });
    };

    const handleNext = () => {
        if (activeStep === 0) {
            const infoValue = {
                productEngName: generalInfoValues.productEngName,
                productMyanName: generalInfoValues.productMyanName,
                productCode: generalInfoValues.productCode,
                chosenCategory: generalInfoValues.chosenCategory,
                currency: generalInfoValues.currency,
                price: generalInfoValues.price,
                quantity: generalInfoValues.quantity,
                engDescription: generalInfoValues.engDescription,
                myanDescription: generalInfoValues.myanDescription,
            };
            const checkGeneralInfo = checkFormValidate(infoValue);
            setGeneralInfoError(checkGeneralInfo);
            const errorGeneralInfoValue = Object.keys(checkGeneralInfo);
            if (
                errorGeneralInfoValue.length === 0 &&
                generalInfoValues.productEngName?.length < 100 &&
                generalInfoValues.productMyanName?.length < 100 &&
                generalInfoValues.productCode?.length < 250
            ) {
                setActiveStep((prevStep) => prevStep + 1);
            }
        } else if (activeStep === 1) {
            const detailedInfoValue = {
                creationDateRange: generalInfoValues.creationDateRange,
                releasedDate: generalInfoValues.releasedDate,
                isLimited: generalInfoValues.isLimited,
                isAvailable: generalInfoValues.isAvailable,
            };
            const productWarranty = generalInfoValues.warranty;
            // const productSize = generalInfoValues.size;
            // const productLength = generalInfoValues.length;
            // const productWeight = generalInfoValues.weight;
            const checkDetailedInfo = checkFormValidate(detailedInfoValue);
            setGeneralInfoError(checkDetailedInfo);
            const errorGeneralInfoValue = Object.keys(checkDetailedInfo);
            if (
                errorGeneralInfoValue?.length === 0 &&
                productWarranty?.length < 250
            ) {
                setActiveStep((prevStep) => prevStep + 1);
            }
            if (generalInfoValues.mediaList.length === 0) {
                setDisableUpdate(true);
            }
        } else if (activeStep === 2) {
            handleSubmit();
        }
    };

    const handleChangeDate = (event) => {
        setGeneralInfoValues((generalInfo) => {
            return {
                ...generalInfo,
                releasedDate: event,
            };
        });
    };

    const handleChangeUnit = (event) => {
        const { name, value } = event.target;
        const unitValue =
            name === "sizeUnit"
                ? "size"
                : name === "lengthUnit"
                ? "length"
                : name === "weightUnit"
                ? "weight"
                : "";
        const initalUnit =
            value === "inch"
                ? "cm"
                : value === "cm"
                ? "inch"
                : value === "lb"
                ? "kg"
                : value === "kg"
                ? "lb"
                : "";
        setGeneralInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                [name]: value,
                [unitValue]:
                    generalInfoValues[unitValue] !== "" &&
                    unitConversion(
                        generalInfoValues[unitValue],
                        initalUnit,
                        value
                    ),
            };
        });
    };

    const handleUploadMedia = (mediaInfo) => {
        setGeneralInfoValues((generalInfo) => {
            console.log("media list::", generalInfo["mediaList"]);
            return {
                ...generalInfo,
                mediaList:
                    generalInfo["mediaList"] !== null
                        ? [...generalInfo["mediaList"], mediaInfo]
                        : [mediaInfo],
            };
        });
    };

    const handleChangeDateRange = (start, end) => {
        setCreationDate({
            startDate: moment(start).format("YYYY-MM-DD"),
            endDate: moment(end).format("YYYY-MM-DD"),
        });
        setGeneralInfoValues((generalInfo) => {
            return {
                ...generalInfo,
                releasedDate: new Date(moment(end).format("YYYY-MM-DD")),
                creationDateRange: {
                    startDate: start,
                    endDate: end,
                },
            };
        });
    };

    const handleRemoveMediaInfo = (mediaInfo) => {
        setGeneralInfoValues((generalInfo) => {
            return {
                ...generalInfo,
                mediaList: generalInfoValues["mediaList"].filter(
                    (element) => element !== mediaInfo
                ),
            };
        });
        setDeleteImgIdList((delImgList) => {
            return [...delImgList, mediaInfo.id];
        });
    };

    const handleSubmit = async () => {
        const {
            productEngName,
            productMyanName,
            productCode,
            chosenCategory,
            currency,
            price,
            quantity,
            engDescription,
            myanDescription,
            releasedDate,
            isLimited,
            isAvailable,
            size,
            sizeUnit,
            length,
            lengthUnit,
            weight,
            weightUnit,
            warranty,
            mediaList,
        } = generalInfoValues;

        if (mediaList?.length > 0) {
            setLoading(true);

            const productSize = size !== "" ? size + " " + sizeUnit : "";
            const productLength =
                length !== "" ? length + " " + lengthUnit : "";
            const productWeight =
                weight !== "" ? weight + " " + weightUnit : "";
            const releasedAt = format(releasedDate, "yyyy-MM-dd");
            const imgList = new FormData();
            for (let i = 0; i < mediaList.length; i++) {
                imgList.append(`images[${i}]`, mediaList[i].file);
            }

            for (let delImg = 0; delImg < deleteImgIdList.length; delImg++) {
                imgList.append(
                    `delete_product_media_id[${delImg}]`,
                    deleteImgIdList[delImg]
                );
            }

            const query = {
                nameEN: productEngName,
                nameMM: productMyanName,
                history_from: creationDate.startDate,
                history_to: creationDate.endDate,
                released_at: releasedAt,
                catalog: chosenCategory["value"],
                product_code: productCode,
                price: price,
                currency: currency,
                qty: quantity,
                is_limited: isLimited,
                is_available: isAvailable,
                size: productSize,
                weight: productWeight,
                length: productLength,
                warranty: warranty,
                descriptionEN: engDescription,
                descriptionMM: myanDescription,
            };

            const createProductURL = `${
                apiServer.product.update
            }?id=${productId}&${stringify(query)}`;

            // const productCreateURL = `${apiServer.product.update}?id=${productId}&nameEN=${productEngName}&nameMM=${productMyanName}&history_from=${creationDate.startDate}&history_to=${creationDate.endDate}&released_at=${releasedAt}&catalog=${chosenCategory}&product_code=${productCode}&price=${price}&currency=${currency}&qty=${quantity}&is_limited=${isLimited}&is_available=${isAvailable}&size=${productSize}&weight=${productWeight}&length=${productLength}&warranty=${warranty}&descriptionEN=${engDescription}&descriptionMM=${myanDescription}`;
            try {
                const { data } = await post(createProductURL, imgList);
                setLoading(false);
                if (data.status >= 400) {
                    setErrorUpdate(data.message);
                } else {
                    if (data.status === "success") {
                        navigate("/products", {
                            replace: true,
                            state: { status: "success", message: data.message },
                        });
                    } else if (data.code >= 400) {
                        setErrorUpdate("Please fill all mandatory field");
                    }
                }
            } catch (error) {
                setLoading(false);
                setErrorUpdate(error.message);
            }
        } else {
            setErrorUpdate("Please upload product image");
        }
    };

    const handleChangeInput = (event) => {
        const { name, value } = event.target;
        setGeneralInfoValues((generalInfo) => {
            return {
                ...generalInfo,
                [name]: value,
            };
        });
        delete generalInfoError[name];
        setGeneralInfoError(generalInfoError);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) =>
            prevActiveStep <= 0 ? 0 : prevActiveStep - 1
        );
    };

    if (getInfoLoading)
        return <Loading text="Retrieving Product Information" />;

    if (getInfoError) return <EmptyPage text={getInfoError} />;

    if (loading) return <PopupLoading text="Updating product information" />;

    return (
        <>
            <div className="create-artist-page-section">
                <DetailHeaderBar
                    navigateRoute={"/products"}
                    name="Update Product"
                />
                <div className="create-product-page-section">
                    <div className="create-product-page-stepper-container">
                        <Box sx={{ width: "100%" }}>
                            <Stepper activeStep={activeStep}>
                                {productSteps.map((label, index) => {
                                    // const stepProps = {};
                                    return (
                                        <Step key={index}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Box>
                    </div>
                    <Scrollbars
                        style={{ height: "calc(100vh - 200px)" }}
                        autoHide
                        autoHideTimeout={200}
                        autoHideDuration={200}
                    >
                        {activeStep === 0 ? (
                            <GeneralInformation
                                states={generalInfoValues}
                                onChange={handleChangeInput}
                                errorStates={generalInfoError}
                                onChangeSelect={handleChangeSelect}
                            />
                        ) : activeStep === 1 ? (
                            <DetailedInformation
                                states={generalInfoValues}
                                onChange={handleChangeInput}
                                onChangeDateRange={handleChangeDateRange}
                                onChangeDate={handleChangeDate}
                                minReleaseDate={creationDate["endDate"]}
                                onChangeUnit={handleChangeUnit}
                            />
                        ) : (
                            <UploadMedia
                                mediaData={generalInfoValues}
                                onChange={handleUploadMedia}
                                onRemove={handleRemoveMediaInfo}
                            />
                        )}
                    </Scrollbars>
                    <FormActionRow
                        submitBtnName={
                            activeStep === productSteps.length - 1
                                ? "Update"
                                : "Next"
                        }
                        cancelBtnName="Back"
                        disableCancelBtn={activeStep === 0}
                        onSubmit={handleNext}
                        disableSubmitBtn={disableUpdate}
                        onCancel={handleBack}
                    />
                </div>
            </div>
            {errorUpdate !== "" && (
                <AdaAlert type="error" message={errorUpdate} />
            )}
        </>
    );
};

export default UpdateProduct;
