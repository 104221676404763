export const Headers = {
    Artists: [
        {
            field: "rowId",
            headerName: "No.",
            type: "number",
            width: 50,
        },
        {
            field: "profile",
            headerName: "Profile",
            width: 110,
            type: "image",
        },
        {
            field: "artist_eng_name",
            headerName: "Artist Name",
            width: 180,
        },
        {
            field: "artist_type",
            headerName: "Artist Type",
            width: 180,
        },
        {
            field: "gender",
            headerName: "Gender",
            width: 100,
        },
        {
            field: "dob",
            headerName: "Date of Birth",
            width: 150,
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
        },
    ],

    ArtistType: [
        {
            headerName: "No.",
            type: "number",
            field: "rowId",
            width: 50,
        },
        {
            field: "eng_title",
            headerName: "Title in English",
            width: 400,
        },
        {
            field: "myan_title",
            headerName: "Title in Myanmar",
            width: 400,
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
        },
    ],

    Banner: [
        {
            headerName: "No.",
            type: "number",
            field: "rowId",
            width: 50,
        },
        {
            field: "images",
            headerName: "Image",
            width: 110,
            type: "image",
        },
        {
            field: "name",
            headerName: "Name",
            width: 440,
        },
        {
            field: "is_active",
            headerName: "Status",
            width: 150,
        },
        {
            field: "banner_type",
            headerName: "Banner Type",
            width: 150,
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
        },
    ],

    Category: [
        {
            headerName: "No.",
            type: "number",
            field: "rowId",
            width: 50,
        },
        {
            field: "image",
            headerName: "Image",
            width: 110,
            type: "image",
        },
        {
            field: "category_eng_name",
            headerName: "Title in English",
            width: 280,
        },
        {
            field: "category_myan_name",
            headerName: "Title in Myanmar",
            width: 300,
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
        },
    ],

    Operator: [
        {
            headerName: "No.",
            type: "number",
            field: "rowId",
            width: 50,
        },
        {
            field: "name",
            headerName: "Name",
            width: 200,
        },
        {
            field: "phone",
            headerName: "Phone",
            width: 250,
        },
        {
            field: "email",
            headerName: "Email",
            width: 300,
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
        },
    ],

    Process: [
        {
            headerName: "No.",
            type: "number",
            field: "rowId",
            width: 50,
        },
        {
            field: "images",
            headerName: "Image",
            width: 110,
            type: "image",
        },
        {
            field: "process_eng_name",
            headerName: "Process Name",
            width: 350,
        },
        {
            field: "name",
            headerName: "Product Name",
            width: 280,
        },
        {
            field: "step",
            headerName: "Step",
            width: 50,
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
        },
    ],

    Product: [
        {
            headerName: "No.",
            type: "number",
            field: "rowId",
            width: 50,
        },
        {
            field: "product_img",
            headerName: "Image",
            headerAlign: "center",
            width: 110,
            type: "image",
        },
        {
            field: "product_code",
            headerName: "Product Code",
            headerAlign: "center",
            width: 150,
        },
        {
            field: "product_eng_name",
            headerName: "Product Name In English",
            headerAlign: "center",
            width: 180,
        },
        {
            field: "product_category",
            headerName: "Category",
            headerAlign: "center",
            width: 250,
        },
        {
            field: "price",
            headerName: "Price",
            headerAlign: "center",
            align: "center",
            width: 150,
        },
        {
            field: "currency",
            headerName: "Currency",
            headerAlign: "center",
            align: "center",
            width: 150,
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            width: 200,
        },
    ],

    User: [
        {
            headerName: "No.",
            type: "number",
            field: "rowId",
            width: 50,
        },
        {
            field: "name",
            headerName: "User Name",
            width: 250,
        },
        {
            field: "phone",
            headerName: "Phone",
            width: 250,
        },
        {
            field: "email",
            headerName: "Email",
            width: 250,
        },
    ],
};
