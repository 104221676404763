import { useState, useEffect, useCallback } from "react";
import { post } from "../utils/api";

const useFetchInfo = (url) => {
    const [fechedInfo, setFetchedInfo] = useState({
        data: [],
        isLoading: true,
        error: "",
    });

    const token = localStorage.getItem("token");

    const fetchInfoData = useCallback(async () => {
        try {
            const { data } = await post(url, "", {}, token);

            if (data.status >= 400) {
                setFetchedInfo({
                    data: [],
                    isLoading: false,
                    error: data.message,
                });
            } else {
                if (data?.status === "success" || data?.code === 200) {
                    setFetchedInfo({
                        data: data?.data,
                        isLoading: false,
                        error: "",
                    });
                } else if (data.code >= 400) {
                    setFetchedInfo({
                        data: [],
                        isLoading: false,
                        error: data?.message,
                    });
                }
            }
        } catch (error) {
            setFetchedInfo({
                data: [],
                isLoading: false,
                error: "Connection Time out",
            });
        }
    }, [url, token]);

    useEffect(() => {
        fetchInfoData();
    }, [url, fetchInfoData]);

    const { data, isLoading, error } = fechedInfo;
    return { data, isLoading, error };
};

export default useFetchInfo;
