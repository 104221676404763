import React from 'react'
import PropTypes from "prop-types";


const Loading = ({text}) => {
  return (
    <div className="main-loading-container">
      <div className="loading-container">
        <div className='loading-spinner' />&nbsp;{text}
      </div>
    </div>
  )
}

Loading.defaultProps ={
  text: 'Loading',
}

Loading.propTypes = {
  text: PropTypes.string,
}

export default Loading