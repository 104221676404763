import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../utils/useFetch";
import apiServer from "../../constants/apiServer";
import { post } from "../../utils/api";
import DataList from "../../common/DataList";
import defaultImg from "../../image/default-image.png";
import ConfirmationPopup from "../../common/ConfirmationPopup";
import { img_url_api } from "../../constants/apiImg";
import "../../css/artist.sass";
import { Headers } from "../../constants/Headers";
import TableAction from "../../common/TableAction";
import { StatusCode, DeleteRow } from "../../utils/commonFuncs";
import { useContext } from "react";
import { DataContext } from "../../context/dataProvider";
import { useLocation } from "react-router-dom";
import { commafy } from "../../utils";

const ProductList = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { currentPageList, setCurrentPageList } = useContext(DataContext);

    const [page, setPage] = useState(currentPageList["product"]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [actionStatus, setActionStatus] = useState("");
    const [actionMessage, setActionMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [getListError, setGetListError] = useState("");
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [productId, setProductId] = useState("");
    const [headerInfo, setHeaderInfo] = useState(Headers["Product"]);

    const { data, totalCount, isLoading, error } = useFetch(
        `${apiServer.product.list}?page_at=${page}&row_count=${rowPerPage}`
    );

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    const modifiedData = useCallback(
        (listData) => {
            const modifyData = listData.map((info, index) => {
                const productName = info?.product_name; // change after fixed api update
                // const productName = JSON.parse(info?.product_name);
                const currency = info?.currency_id === "1" ? "MMK" : "Dollar";
                return {
                    ...info,
                    rowId: index + 1 + (page - 1) * rowPerPage,
                    product_img: info?.images[0],
                    product_eng_name: productName?.en,
                    product_category: info?.catalog_name?.mm,
                    currency: currency,
                    price: commafy(info?.price),
                };
            });
            return modifyData;
        },
        [page, rowPerPage]
    );

    const modifyHeader = useCallback(() => {
        headerInfo[1]["renderCell"] = (params) => {
            const profile = params.formattedValue;
            const id = params.api.getRowIndex(params.row.id);
            return (
                <img
                    src={
                        profile === "" || profile === undefined
                            ? defaultImg
                            : img_url_api + profile
                    }
                    alt="product_img"
                    className={
                        profile === "" || profile === undefined
                            ? "dummy_profile"
                            : id === 0
                            ? "first--artist_profile"
                            : "artist_profile"
                    }
                />
            );
        };

        headerInfo[headerInfo.length - 1]["renderCell"] = ({ id }) => {
            const onEdit = () => {
                navigate(`${id}/edit`, { replace: true });
            };
            const onDelete = async () => {
                setConfirmDelete(true);
                setProductId(id);
            };
            return <TableAction onEdit={onEdit} onDelete={onDelete} />;
        };

        setHeaderInfo(headerInfo);
    }, [headerInfo, navigate]);

    useEffect(() => {
        const modifyData = modifiedData(data);
        setRowData(modifyData);
    }, [data, page, modifiedData]);

    useEffect(() => {
        if (location?.state !== null) {
            const { status, message } = location?.state;
            setActionStatus(status);
            setActionMessage(message);
        }
    }, [location]);

    useEffect(() => {
        setTotalRecords(totalCount);
    }, [totalCount]);

    useEffect(() => {
        setGetListError(error);
    }, [error]);

    useEffect(() => {
        modifyHeader();
    }, [headerInfo, navigate, modifyHeader]);

    const handleConfirmDelete = async () => {
        setConfirmDelete(false);
        setLoading(true);
        try {
            const { data: deletedInfo } = await post(
                `${apiServer.product.delete}?id=${productId}`
            );

            setActionStatus(StatusCode[deletedInfo?.status]);
            setActionMessage(deletedInfo?.message);

            if (!(deletedInfo?.status >= 400)) {
                const dataList = modifiedData(DeleteRow(rowData, productId));
                setRowData(dataList);
                setTotalRecords(dataList.length);
                if (dataList.length === 0) {
                    setPage((prev) => (prev > 1 ? prev - 1 : 1));
                    setCurrentPageList((prev) => {
                        return {
                            ...currentPageList,
                            product: prev > 1 ? prev - 1 : 1,
                        };
                    });
                }
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
            setActionStatus("error");
            setActionMessage(err.message);
        }
    };

    const handleChangePage = (currentPage) => {
        setPage(currentPage + 1);
        setCurrentPageList({
            ...currentPageList,
            product: currentPage + 1,
        });
    };

    const handleChangePageSize = (rows) => {
        setRowPerPage(rows);
    };

    return (
        <>
            <DataList
                title={"Product List"}
                headers={headerInfo}
                rowData={rowData}
                isLoading={loading}
                totalCount={totalRecords}
                error={getListError}
                currentPage={page}
                pageSize={rowPerPage}
                onPageChange={handleChangePage}
                onPageSizeChange={handleChangePageSize}
                deleteMsg={actionMessage}
                deleteStatus={actionStatus}
                emptyDataText={"There is no product. Please Create One."}
            />

            {confirmDelete && (
                <ConfirmationPopup
                    headerText="Delete Product"
                    body={"Are you sure you want to delete this product ?"}
                    onSubmit={handleConfirmDelete}
                    onCancel={() => {
                        setConfirmDelete(false);
                    }}
                />
            )}
        </>
    );
};

export default ProductList;
