import React, { useContext, useState } from "react";
// import { BiMenu } from "react-icons/bi";
import { NavLink } from 'react-router-dom';
import { SiProcesswire } from "react-icons/si";
import "../css/navigation.sass";
import { DataContext } from "../context/dataProvider";
import Tooltip from "@mui/material/Tooltip";
// import { HiTemplate } from "react-icons/hi";
// import { AiFillSetting } from "react-icons/ai";
// import { GiArtificialHive } from "react-icons/gi";
// import { FaArtstation } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { MdCategory } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { MdSpaceDashboard } from "react-icons/md";
import Banner from "../icons/banner";
import ArtistType from "../icons/artist_type";
import Artist from "../icons/artist";
import Product from "../icons/product";
import Operator from "../icons/operator";

const SideNavigation = () => {

  const location = useLocation();

  const [expandNav, setExpandNav] = useState(true);
  const { setNavWidth } = useContext(DataContext);

  const navMenuList = [
    {
      icon: <MdSpaceDashboard size='18px' />,
      name: 'Dashboard',
      route: 'dashboard',
    },
    {
        icon: <Banner size='20px'   />,
        name: 'Banner',
        route: 'banners'
    },
    {
        icon: <ArtistType size='20px'/>,
        name: 'Artist Type',
        route: 'artist_types'
    },
    {
        icon: <Artist size='20px'/>,
        name: 'Artist',
        route: 'artists',
    },
    {
        icon: <MdCategory size='18px'/>,
        name: 'Category',
        route: 'categories',
    },
    {
        icon: <Product size='20px'/>,
        name: 'Product',
        route: 'products',
    },
    {
      icon: <SiProcesswire size='18px'/>,
      name: 'Process',
      route: 'processes',
    },
    {
        icon: <Operator size='26px'/>,
        name: 'Operator',
        route: 'operators'
    },
    {
        icon: <FaUserAlt size='18px'/>,
        name: 'User',
        route: 'users'
    },
   
  ];

  // const handleExpandNavigation = () => {
  //   setExpandNav(!expandNav); 
  //   setNavWidth( !expandNav ? '200px' : '48px' );
  // }

  const activeClassName = "admin-nav-item active"
  const menuClassName = "admin-nav-item"
  const expandMenuClass = 'admin-nav-item expand-item'
  const expanActive = 'admin-nav-item expand-item active'


  return (
    <div className="ada-admin-navigation-bar" style={{width: expandNav && '200px'}}>
      {/* <div className="ada-admin-nav-header" onClick={handleExpandNavigation}>
        {expandNav && (
          <div className="ada-admin-logo">
            <img src={logo} alt="ada-logo" />
          </div>
        )}
        <button className="ada-nav-menu-icon">
            <BiMenu size="25px" />
        </button>
      </div> */}
      <div className="ada-admin-nav-list">
        <nav>
            <ul>
                {
                    navMenuList.map((menu, menuIndex) => 
                        <Tooltip title={menu.name} key={menuIndex}>
                            <div className="ada-admin-menu-item">
                              <NavLink to={menu.route} className={({isActive}) => isActive ? expandNav ? expanActive : activeClassName: expandNav ? expandMenuClass : menuClassName }>
                                <div className={location.pathname.includes(`/${menu.route}`) ? "navigation-menu active" : "navigation-menu"}>
                                  {
                                    !expandNav ? menu.icon : <>{menu.icon}&nbsp;&nbsp;&nbsp;{menu.name}</>
                                  }
                                </div>       
                              </NavLink>
                              {/* { location.pathName === `/${menu.route}` && <div className='menu-active-bar' />} */}
                            </div>
                        </Tooltip>
                    )
                }
            </ul>
        </nav>      
      </div>
    </div>
  );
};

export default SideNavigation;
