import React from 'react'
import { MdClose } from 'react-icons/md';
import "../css/common.sass";


const PopupHeader = ({header, onClose}) => {
  return (
    <div className='popup-header'>
        <label>{header}</label>
        <button onClick={onClose}>
            <MdClose size='18px' />
        </button>
    </div>
  )
}

export default PopupHeader