import React from "react";
import DetailHeaderBar from "../../common/DetailHeaderBar";
import BannerImageList from "./BannerImageList";
import FormActionRow from "../../common/FormActionRow";
import InputField from "../../common/InputField";
import "../../css/artist.sass";
import "../../css/banner.sass";

const BannerForm = ({
    formTitle,
    onSubmitName,
    onCreate,
    onCancel,
    onChangeValue,
    onUploadImg,
    onRemoveImg,
    onChangeCaption,
    infoValues,
    errorValues,
}) => {
    const bannerTypeList = [
        { name: "Home Page", value: 1 },
        { name: "Product Page", value: 2 },
        { name: "Artist Page", value: 3 },
    ];

    const { imageList, name, banner_type, is_active, remark } = infoValues;

    return (
        <div className="create-artist-page-section">
            <DetailHeaderBar navigateRoute={"/banners"} name={formTitle} />
            <div className="create-banner-form">
                <div className="create-banner-form-left-side">
                    <BannerImageList
                        imageList={imageList}
                        onUpload={onUploadImg}
                        onRemove={onRemoveImg}
                        onAddCaption={onChangeCaption}
                    />
                </div>
                <div className="create-banner-form-right-side">
                    <div className="create-banner-form-row">
                        <InputField
                            isRequired
                            title="Name"
                            name="name"
                            id="name"
                            mainWidth="100%"
                            width="100%"
                            value={name}
                            onChange={onChangeValue}
                            isFlag={"name" in errorValues || name?.length > 100}
                            errMsg={
                                name?.length > 100
                                    ? "Must not exceed more than 100 words"
                                    : errorValues?.name
                            }
                            layout="vertical"
                        />
                    </div>
                    <div className="create-banner-form-row">
                        <InputField
                            isRequired
                            title="Banner is For"
                            fieldType={"radio"}
                            mainWidth="100%"
                            selectedRadioValue={banner_type}
                            radioValueList={bannerTypeList}
                            onChangeRadioValue={onChangeValue}
                            name="banner_type"
                            isFlag={"banner_type" in errorValues}
                            errMsg={errorValues?.banner_type}
                        />
                    </div>
                    <div className="create-banner-form-row">
                        <InputField
                            isRequired
                            title="Active Banner"
                            fieldType={"radio"}
                            selectedRadioValue={is_active}
                            radioValueList={[
                                { name: "Yes", value: 1 },
                                { name: "No", value: 0 },
                            ]}
                            onChangeRadioValue={onChangeValue}
                            name="is_active"
                            width={"315px"}
                            isFlag={"is_active" in errorValues}
                            errMsg={errorValues?.is_active}
                        />
                    </div>
                    <div className="create-banner-form-row">
                        <InputField
                            isRequired
                            title="Remark"
                            mainWidth="100%"
                            width="100%"
                            layout={"vertical"}
                            fieldType={"textarea"}
                            description={remark}
                            name="remark"
                            onChangeDescription={onChangeValue}
                            isFlag={"remark" in errorValues}
                            errMsg={errorValues?.remark}
                        />
                    </div>
                </div>
            </div>
            <FormActionRow
                submitBtnName={onSubmitName}
                onSubmit={onCreate}
                onCancel={onCancel}
            />
        </div>
    );
};

export default BannerForm;
