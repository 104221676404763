import React, { useState } from 'react'
import ArtistListDropDown from './ArtistListDropDown';
import ArtistInfoCard from "./ArtistInfoCard";
import "../../css/process.sass";
import { IoPersonAddSharp } from "react-icons/io5"; 
import Tooltip from "@mui/material/Tooltip";
import Scrollbars from 'react-custom-scrollbars';

const ChooseArtistList = ({ chosenArtistList, allArtistList, onAddArtist, onRemoveArtist }) => {

  const [showArtistList, setShowArtistList ] = useState(false);

  console.log("artist list::", chosenArtistList);
 

  return (
    <div className='choose-artist-list-container'>
      <div className='choose-artist-list-header'>
        <label>Artists</label>
        <div className="artist-list-container">
          <Tooltip title='View Artist List'>
            <button onClick={() => { setShowArtistList(!showArtistList) }}><IoPersonAddSharp size='24px' /></button>
          </Tooltip>  
          {
            showArtistList && <ArtistListDropDown list={allArtistList} onAdd={onAddArtist} onRemove = {onRemoveArtist} />
          }
        </div>
       
        
      </div>
      <div className='chosen-artist-list-container'>
        { chosenArtistList?.length > 0 && 
            <Scrollbars autoHide autoHideTimeout={200} autoHideDuration={200} style={{height:'100%', overflow:'hidden'}}>
              {
                chosenArtistList?.map((artistInfo, artistIndex) =>
                  <ArtistInfoCard key={artistIndex} info={artistInfo}  />
                )
              }
            </Scrollbars>
        }
        {
          chosenArtistList?.length === 0 && <p>There is no chosen artist.<br/> Please choose artist by click on add button</p>
        }
        </div>
    </div>
  )
}



export default ChooseArtistList