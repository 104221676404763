import React, { useEffect, useState } from 'react'
import useFetchInfo from "../../utils/useFetchInfo";
import { useNavigate, useParams } from "react-router-dom";
import apiServer from "../../constants/apiServer";
import "../../css/artist.sass";
import SquareLoading from "../../common/SquareLoading";
import { post } from "../../utils/api";
import PopupLoading from '../../common/PopupLoading';
import AdaAlert from '../../common/AdaAlert';
import { img_url_api } from "../../constants/apiImg";
import { checkFormValidate } from '../../utils/commonFuncs';
import { stringify } from 'query-string';
import CategoryForm from './CategoryForm';
import EmptyPage from '../../common/EmptyPage';

const UpdateCategory = () => {

  const navigate = useNavigate();
  const { categoryId } = useParams();


  const [categoryInfoValues, setCategoryInfoValues ] = useState({
    categoryImg: null,
    engName: '',
    myanName: '',
    subCategory: 'no',
    categoryList: [],
    chosenCategory: {}
  })

  const [errorValues, setErrorValues ] = useState({});


  const [getInfoLoading, setGetInfoLoading] = useState(true);
  const [getInfoError, setGetInfoError ] = useState("");


  const [updateCategoryLoading, setUpdateCategoryLoading ] = useState(false);
  const [errorUpdate, setErrorUpdate ] = useState("");

  const { data: categoryList, isLoading: loadingCategoryList, error: categoryListError } = useFetchInfo(apiServer.category.filter)
  
  const { data: categoryInfo, isLoading: loadingcategoryInfo, error : categoryInfoError } = useFetchInfo(`${apiServer.category.detail}?id=${categoryId}`);


  useEffect(() => {

    setGetInfoLoading(loadingCategoryList && loadingcategoryInfo);

    setGetInfoError(categoryListError || categoryInfoError);

    let modifiedCategoryList = categoryList.map((element) => {
      return {
        value: element?.id,
        label: `${element?.name?.en} : ${element?.name?.mm}`
      }
    });

    const availableCategoryList = modifiedCategoryList.filter((element) => element?.value !== categoryId);

    const selectedCategory = categoryInfo['parent_id'] === "0" ? availableCategoryList[0] : modifiedCategoryList.filter((element) => element?.value === categoryInfo['parent_id'])[0];

    setCategoryInfoValues({
      categoryImg: img_url_api+categoryInfo?.image,
      engName: categoryInfo?.name?.en,
      myanName: categoryInfo?.name?.mm,
      subCategory: categoryInfo?.parent_id > 0 ? 'yes' : 'no',
      categoryList: availableCategoryList,
      chosenCategory: selectedCategory,
    })
    

  }, [categoryList, loadingCategoryList, categoryListError, categoryInfo, loadingcategoryInfo, categoryInfoError, categoryId])
  

  const handleChangeValue = ( event ) => {
    const { name, value } = event.target;
    setCategoryInfoValues((prevInfo) => {
      return{
        ...prevInfo,
        [name] : value
      }
    })
  }

  const handleChooseValue = ( selectedOption) => {
    setCategoryInfoValues((prevInfo) => {
      return{
        ...prevInfo,
        chosenCategory: selectedOption
      }
    });
    delete errorValues['chosenCategory'];
    setErrorValues(errorValues);
  }


  const handleUploadImage = (imageInfo) => {
    setCategoryInfoValues((prevInfo) => {
      return {
        ...prevInfo,
        categoryImg: imageInfo
      }
    })
    delete errorValues['categoryImg']
    setErrorValues(errorValues)
  };

  const handleRemoveImage = () => {
    setCategoryInfoValues((prevInfo) => {
      return {
        ...prevInfo,
        categoryImg: null
      };
    });
  };

  const handleCancelUpdate = () => {
    navigate("/categories", { replace : true })
  }

  const handleUpdateCategory = async(event) => {

    event.preventDefault();
    const {categoryImg, engName, myanName, subCategory, chosenCategory } = categoryInfoValues;
    const checkGeneralInfo = checkFormValidate({
      categoryImg,
      engName,
      myanName,
      subCategory
    });
    setErrorValues(checkGeneralInfo);
    const errorStateList = Object.keys(checkGeneralInfo);
    const categoryParentId = subCategory === "yes" ? chosenCategory?.value : 0;
    const categoryBody = new FormData();
    categoryBody.append("image", categoryImg);
    if(errorStateList.length === 0 && engName.length <= 100 && myanName.length <= 100){
      setUpdateCategoryLoading(true);
      const query = {
        nameENG: engName,
        nameMM: myanName,
        parent_id: categoryParentId,
      };
      const updateCategoryURL = `${apiServer.category.update}?id=${categoryId}&${stringify(query)}`;
      try{
        const { data } = await post(updateCategoryURL, categoryBody);
        setUpdateCategoryLoading(false);
        if(data.status >= 400){
          setErrorUpdate(data.message)
        }else{
          if(data.status === "success"){
            navigate('/categories', { replace : true, state: {status: 'success', message: data.message} });
          }else if(data.code >= 400){
            setErrorUpdate('Category Id is invalid')
          }
        }
      }catch(error){
        setUpdateCategoryLoading(false)
        setErrorUpdate(error.message)
      }
    }
  }

  if(getInfoLoading) return <SquareLoading text="Retrieving Category Information" />
  
  if(getInfoError) return <EmptyPage text={getInfoError} />

  if(updateCategoryLoading) return <PopupLoading text='Updating Category...' />


  return (
    <>
      <CategoryForm 
        formTitle={'Update Category'}
        onCreate={handleUpdateCategory}
        onCancel={handleCancelUpdate}
        onSubmitName='Update'
        infoValues={categoryInfoValues}
        errorValues={setErrorValues}
        onUploadImg={handleUploadImage}
        onRemoveImg={handleRemoveImage}
        onChangeValues={handleChangeValue}
        onChangeSelect={handleChooseValue}
      />
      {errorUpdate !== "" && (
        <AdaAlert type="error" message={errorUpdate} />
      )} 
    </>
  )
}

export default UpdateCategory