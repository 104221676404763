import React, { useState, useEffect } from 'react'
import InputField from '../../common/InputField'
import apiServer from '../../constants/apiServer'
import useFetchInfo from '../../utils/useFetchInfo'
import { img_url_api } from '../../constants/apiImg'
import ChooseArtistList from './ChooseArtistList'
import Loading from '../../common/Loading'
import EmptyPage from '../../common/EmptyPage'

const DetailedProcessInfo = ({
  processInfo,
  onChangeStartDate,
  onChangeEndDate,
  onChange,
  errorStates,
  onAddArtist,
  onRemoveArtist,
}) => {
  const { data, isLoading, error } = useFetchInfo(apiServer.process.filter)
  const [artistList, setArtistList] = useState([])
  const { startDate, endDate, desEng, desMyan, chosenArtistList } = processInfo

  const getAllArtistList = (retrievedArtistList) => {
    const modifiedArtistList = []
    for (let i = 0; i < retrievedArtistList?.length; i++) {
      const artistName = retrievedArtistList[i]?.name
      modifiedArtistList.push({
        id: retrievedArtistList[i]?.id,
        name: artistName.en,
        profile: img_url_api + retrievedArtistList[i].image,
        isAdded: false
      })
    }
    return modifiedArtistList
  }

  useEffect(() => {
    const retrievedArtistList = data['artist'];
    const allArtistList = getAllArtistList(retrievedArtistList);
    chosenArtistList.forEach((item) => {
      const artistId = allArtistList.findIndex((element) => element.id === `${item.id}` );
      if(artistId >= 0){
        allArtistList[artistId].isAdded = true
      }
    })
    setArtistList(allArtistList)
  }, [data, chosenArtistList])



  // const handleModifyArtistList = (artistInfo) => {
  //   console.log('added artist info::', artistInfo)
  //   const addedArtistId = artistList.findIndex((element) => element.id === artistInfo.id);
  //   artistList[addedArtistId].isAdded = !artistList[addedArtistId].isAdded;
  //   setArtistList(artistList)
  // }

  if (isLoading) return <Loading text="Retrieving Information" />

  if (error) return <EmptyPage text={error} />

  return (
    <div className="create-process-form detailed-form">
      <div className='create-process-form-left-side'>
        <div className="create-process-form-row">
          <InputField
            title="Start Date"
            isRequired
            fieldType={"date"}
            layout="vertical"
            id="Process Start Date"
            selectedDate={startDate}
            onChangeDate={onChangeStartDate}
            isFlag={'startDate' in errorStates}
            errMsg={errorStates?.startDate}
            mainWidth='42%'
          />
          <InputField
            title="End Date"
            isRequired
            fieldType={"date"}
            layout="vertical"
            id="Process End Date"
            name="endDate"
            selectedDate={endDate}
            onChangeDate={onChangeEndDate}
            isFlag={'endDate' in errorStates}
            errMsg={errorStates?.endDate}
            mainWidth='42%'
            minSelectDate={startDate}
          />
        </div>
        <div className="create-process-form-row">
          <InputField
            title="Description (in English)"
            fieldType="textarea"
            isRequired
            layout="vertical"
            id="process end description"
            name="desEng"
            description={desEng}
            onChangeDescription={onChange}
            isFlag={'desEng' in errorStates}
            errMsg={errorStates?.desEng}
            mainWidth="100%"
            width='100%'
          />
        </div>
        <div className="create-process-form-row">
          <InputField
            title="Description (in Myanmar)"
            fieldType="textarea"
            isRequired
            layout="vertical"
            id="process myanmar description"
            name="desMyan"
            description={desMyan}
            onChangeDescription={onChange}
            isFlag={'desMyan' in errorStates}
            errMsg={errorStates?.desMyan}
            mainWidth="100%"
            width='100%'
          />
        </div>
      </div>
      <div className='create-process-form-right-side'>
        <div className="create-process-form-row">
          <ChooseArtistList
            chosenArtistList={chosenArtistList}
            allArtistList={artistList}
            onAddArtist={onAddArtist}
            onRemoveArtist={onRemoveArtist}
            // onModifyAllArtistList= {handleModifyArtistList}
          />
        </div>
      </div>
    </div>
  )
}

export default DetailedProcessInfo
