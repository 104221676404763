import React, { useState } from "react";
import { post } from "../../utils/api";
import apiServer from "../../constants/apiServer";
import { useNavigate } from "react-router-dom";
import AdaAlert from "../../common/AdaAlert";
import { stringify } from "query-string";
import OperatorForm from "./OperatorForm";
import { checkFormValidate } from "../../utils/commonFuncs";
import PopupLoading from "../../common/PopupLoading";
const CreateOperator = () => {
  const navigate = useNavigate();

  const [operatorInfoValues, setOperatorInfoValues] = useState({
    email: "",
    password: "",
    name: "",
    phone: "",
    showPassword: false
  });

  const [errorValues, setErrorValues] = useState({});
  const [createOperatorLoading, setCreateOperatorLoading ] = useState(false);
  const [errorCreate, setErrorCreate] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password, name, phone } = operatorInfoValues;
    const checkGeneralInfo = checkFormValidate({
      email,
      password,
      name,
      phone,
    });
    setErrorValues(checkGeneralInfo);
    const errorStateList = Object.keys(checkGeneralInfo);
    if (errorStateList.length === 0 && email.length < 200 && password.length < 200) {
      setCreateOperatorLoading(true);
      const query = {
        email,
        password,
        name,
        phone,
        operator_role: 1
      };

      const CreateOperatorURL = `${apiServer.operator.create}?${stringify(
        query
      )}`;

      const { data } = await post(CreateOperatorURL);
      setCreateOperatorLoading(false);
      if (data.status > 400) {
        setErrorCreate(data.message);
      } else {
        if (data.code === 200) {
          navigate("/operators", { replace: true, state: { status: 'success', message : data.message } });
        } else if (data.code === 400) {
          setErrorCreate("Email is already taken.");
        }
      }
    }
  };

  const handleChangeValue = (event) => {
    const { name, value } = event.target;
    setOperatorInfoValues((operatorInfo) => {
      return {
        ...operatorInfo,
        [name]: value
      };
    });
  };

  const handleChangePasswordType = () => {
    setOperatorInfoValues((operatorInfo) => {
      return {
        ...operatorInfo,
        showPassword: !operatorInfo["showPassword"]
      };
    });
  };

  const handleCancel = () => {
    navigate("/operators", { replace: true });
  };

  if(createOperatorLoading) return <PopupLoading text="Creating Opreator"/>

  return (
    <>
      <OperatorForm
        onCreate={handleSubmit}
        onCancel={handleCancel}
        formTitle="Create Operator"
        onSubmitName="Create"
        onChangeValue={handleChangeValue}
        infoValues={operatorInfoValues}
        errorValue={errorValues}
        onShowPassword={handleChangePasswordType}
      />
      {errorCreate !== "" && <AdaAlert type="error" message={errorCreate} />}
    </>
  );
};

export default CreateOperator;
