import React from 'react'

const BarLoading = ({ text }) => {
  return (
    <div className='main-loading-container'>
        <div className='loading-container'>
            <div className='bar-loading-container' />&nbsp;{text}
        </div>
    </div>
  )
}

export default BarLoading