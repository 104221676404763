import React from "react";
import "../../css/process.sass";
import { MdAdd } from "react-icons/md";
import { BsCheck } from "react-icons/bs";

const ArtistInfoCard = ({ info, onAction, type }) => {
  return (
    <div className="artist-info-card">
      <div className="artist-profile-image">
        <img src={info.profile} alt={info.name} />
      </div>
      <div className="artist-info-right-side">
        <label title={info.name}>{info.name}</label>
        {type !== undefined && (
          <button onClick={onAction}>
            {type === "add" && !info.isAdded && (
              <>
                <MdAdd size="18px" />
                &nbsp;Add
              </>
            )}
            {type === "add" && info.isAdded && (
              <>
                <BsCheck size="18px" />
                &nbsp;Added
              </>
            )}
            {type === "remove" && <>Remove</>}
          </button>
        )}
      </div>
    </div>
  );
};

export default ArtistInfoCard;
