import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../utils/useFetch";
import apiServer from "../../constants/apiServer";
import { post } from "../../utils/api";
import DataList from "../../common/DataList";
import ConfirmationPopup from "../../common/ConfirmationPopup";
import { Headers } from "../../constants/Headers";
import TableAction from "../../common/TableAction";
import { StatusCode, DeleteRow } from "../../utils/commonFuncs";
import { useContext } from 'react';
import { DataContext } from '../../context/dataProvider';
import { useLocation } from "react-router-dom";


const ArtistTypeList = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const { currentPageList, setCurrentPageList } = useContext(DataContext);

  const [ page, setPage ] = useState(currentPageList["artistType"]);
  const [rowPerPage, setRowPerPage ] = useState(10);
  const [totalRecords, setTotalRecords ] = useState(0);
  const [actionStatus, setActionStatus] = useState("");
  const [actionMessage, setActionMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [getListError, setGetListError ] = useState('');
  const [confirmDelete , setConfirmDelete ] = useState(false);
  const [artistTypeId, setArtistTypeId] = useState('');
  const [headerInfo, setHeaderInfo ] = useState(Headers['ArtistType']);

  const { data, totalCount, isLoading, error } = useFetch(
    `${apiServer.artist_type.list}?page_at=${page}&row_count=${rowPerPage}`
  );

  const modifiedData = useCallback((listData) => {

    const modifyData = listData.map((info, index) => {
      const artistTypeName = info?.name;
      return {
        ...info,
        rowId: index + 1 + (page - 1) * rowPerPage,
        eng_title: artistTypeName?.en,
        myan_title : artistTypeName?.mm
      }
    });
    
    return modifyData;
  }, [page, rowPerPage])

  useEffect(()=>{
    setLoading(isLoading);
  },[isLoading])

  useEffect(() => {

    if(location?.state !== null){
      const { status, message } = location?.state;
      setActionStatus(status);
      setActionMessage(message);
    }

  }, [location]);

  useEffect(() => {
    setTotalRecords(totalCount)
  },[totalCount])


  useEffect(()=> {

    const modifyData = modifiedData(data);

    setRowData(modifyData);

  },[data, modifiedData]);

  useEffect(()=> {
    setGetListError(error)
  },[error]);


  const modifyHeader = useCallback(() => {

    headerInfo[headerInfo.length - 1]["renderCell"] = ({id}) =>{
      const onEdit = () => {
        navigate(`${id}/edit`, { replace: true });
      };
      const onDelete = async () => {
        setConfirmDelete(true);
        setArtistTypeId(id);
      };
      return (
        <TableAction onEdit={onEdit} onDelete={onDelete} />
      );
    };

    setHeaderInfo(headerInfo);

  }, [headerInfo, navigate])

  useEffect(() => {

    modifyHeader();

  }, [headerInfo, navigate, modifyHeader])

  const handleConfirmDelete = async() => {
    setConfirmDelete(false);
    setLoading(true);



    try {
      const { data: deletedInfo } = await post(
        `${apiServer.artist_type.delete}?id=${artistTypeId}`
      );

      if(! (data?.status >= 400 )){

        const dataList = modifiedData(DeleteRow(rowData, artistTypeId));
        setRowData(dataList);
        setTotalRecords(totalRecords - 1);
        if(dataList.length === 0){

          setPage((prev) => prev > 1  ? prev - 1 : 1);
          setCurrentPageList((prev) => {
            return {
              ...currentPageList,
              "artistType" :  prev > 1 ? prev - 1 : 1
            }
          });
        }

      }

      setLoading(false);
      setActionStatus(StatusCode[deletedInfo?.status]);
      setActionMessage(deletedInfo?.message);

    } catch (err) {

      setLoading(false);
      setActionStatus("error");
      setActionMessage(err.message);
    }
  }

  
  const handleChangePage = (currentPage) => {
    setPage(currentPage + 1);
    setCurrentPageList({
      ...currentPageList,
      "artistType" : currentPage + 1
    });
  }

  const handleChangePageSize = (rows) => {
    setRowPerPage(rows)
  }


  return (
    <>
      <DataList
        title={"Artist Type List"}
        headers={headerInfo}
        rowData={rowData}
        isLoading={loading}
        totalCount={totalRecords}
        error={getListError}
        currentPage={page}
        pageSize={rowPerPage}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        deleteMsg={actionMessage}
        deleteStatus={actionStatus}
        emptyDataText={'There is no artist type. Please Create One.'}

      />

      { confirmDelete && 
          <ConfirmationPopup
            headerText="Delete Artist Type"
            body={"Are you sure you want to delete this artist type ?"}
            onSubmit={handleConfirmDelete}
            onCancel={() => {
              setConfirmDelete(false)
            }}
          />
      }
    </>
  );
};

export default ArtistTypeList;
