import React from "react";
import DetailHeaderBar from "../../common/DetailHeaderBar";
import InputField from "../../common/InputField";
import FormActionRow from "../../common/FormActionRow";

const ArtistTypeForm = ({
    onCreate,
    onCancel,
    infoValues,
    errorValues,
    onChangeValue,
    formTitle,
    onSubmitName,
}) => {
    const { nameENG, nameMM } = infoValues;

    return (
        <div className="create-operator-container">
            <DetailHeaderBar navigateRoute={"/artist_types"} name={formTitle} />
            <div className="create-operator-form-container">
                <form className="create-operator-form" onSubmit={onCreate}>
                    <InputField
                        title="Artist Type in English"
                        isRequired
                        type="text"
                        width={"280px"}
                        name="nameENG"
                        value={nameENG}
                        onChange={onChangeValue}
                        id="artistType_eng"
                        isFlage={"nameENG" in errorValues}
                        errMsg={errorValues?.nameENG}
                    />
                    <InputField
                        title="Artist Type in Myanmar"
                        isRequired
                        type="text"
                        name="nameMM"
                        value={nameMM}
                        width={"280px"}
                        onChange={onChangeValue}
                        id="artistType_eng"
                        isFlage={"nameMM" in errorValues}
                        errMsg={errorValues?.nameMM}
                    />
                    <FormActionRow
                        submitBtnName={onSubmitName}
                        onCancel={onCancel}
                    />
                </form>
            </div>
        </div>
    );
};

export default ArtistTypeForm;
