import React from 'react'
import { MdOutlineClose } from "react-icons/md";
import "../css/common.sass";

const ImageDeleteBtn = ({onDelete}) => {
  return (
    <button className='delete-img-btn' onClick={onDelete}><MdOutlineClose /></button>
  )
}

export default ImageDeleteBtn