import React from 'react'

const PopupLoading = ({text}) => {
  return (
    <div className="popup-overlay-container">
        <div className="popup-loading-container">
            <div className='loading-spinner'/>&nbsp;{text}
        </div>
    </div>
  )
}

export default PopupLoading