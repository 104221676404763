import React,{ useState, useEffect, useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import useFetch from '../../utils/useFetch';
import apiServer from '../../constants/apiServer';
import { post } from "../../utils/api";
import DataList from '../../common/DataList';
import defaultImg from '../../image/default-image.png'
import ConfirmationPopup from '../../common/ConfirmationPopup';
import { img_url_api } from "../../constants/apiImg";
import { Headers } from '../../constants/Headers';
import TableAction from '../../common/TableAction';
import { StatusCode, DeleteRow } from "../../utils/commonFuncs";
import { useContext } from 'react';
import { DataContext } from '../../context/dataProvider';
import { useLocation } from 'react-router-dom';
import StatusLabel from '../../common/StatusLabel';

const Banner = () => {


  const navigate = useNavigate();
  const location = useLocation();

  const { currentPageList, setCurrentPageList } = useContext(DataContext);

  const [ page, setPage ] = useState(currentPageList['banner']);
  const [rowPerPage, setRowPerPage ] = useState(10);
  const [totalRecords, setTotalRecords ] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [getListError, setGetListError ] = useState('');
  const [bannerId, setBannerId] = useState('')
  const [confirmDelete ,setConfirmDelete] = useState(false);
  const [headerInfo, setHeaderInfo ] = useState(Headers['Banner']);
  const [ actionStatus, setActionStatus ] = useState('success');
  const [ actionMessage, setActionMessage ] = useState("");

  const { data, totalCount, isLoading, error } = useFetch(
    `${apiServer.banner.list}?page_at=${page}&row_count=${rowPerPage}`
  );

  const modifiedData = useCallback((listData) => {

    const modifyData = listData.map((info, index) => {
      return {
        ...info, 
        rowId: index+1+(page - 1) * rowPerPage
      };
    });

    return modifyData;
    
  }, [page, rowPerPage])

  useEffect(()=>{
    setLoading(isLoading);
  },[isLoading])


  useEffect(()=> {
    setRowData(modifiedData(data));
  },[data, page, modifiedData]);

  useEffect(() => {
    setTotalRecords(totalCount)
  },[totalCount])

  useEffect(()=> {
    setGetListError(error)
  },[error]);

  useEffect(() => {

    if(location?.state !== null){
      const { status, message } = location?.state;
      setActionStatus(status);
      setActionMessage(message);
    }

  }, [location]);

  const modifyHeader = useCallback(() => {

    const headerIdList = {
      "image" : headerInfo.findIndex((element) => element.field === "images"),
      "is_active" : headerInfo.findIndex((element) => element.field === "is_active"),
      "banner_type": headerInfo.findIndex((element) => element.field === "banner_type"),
      "action" : headerInfo.findIndex((element) => element.field === "action")
    }
    console.log("header id list::", headerIdList);
    headerInfo[headerIdList['image']]["renderCell"] = (params) => {
      const bannerImgList = params.formattedValue;
      const id = params.api.getRowIndex(params.row.id);
      return (
        <>
          {
            bannerImgList !== "" || bannerImgList !== undefined ?
              bannerImgList?.map((imgInfo, index) => 
                imgInfo !== "" ?
                  <img src={img_url_api+imgInfo} alt='bannerImg' className={ id === 0 ? "first--artist_profile" : "artist_profile"} />
                  :
                  <img src={defaultImg} alt='bannerImg' className="dummy_profile" />
              )
            :
            <img src={defaultImg} alt='bannerImg' className="dummy_profile" />
          }
        </>
      );
      
    }


    headerInfo[headerIdList["is_active"]]["renderCell"] = (params) => {
      const is_active = params.formattedValue;
      console.log("is activie::", is_active);
      return (
        <StatusLabel status={is_active === "1" ? "active" : "inactive"} />
      )
    }

    headerInfo[headerIdList['action']]["renderCell"] = ({id}) =>{
      const onEdit = () => {
        navigate(`${id}/edit`, { replace: true });
      };
      const onDelete = () => {
        setBannerId(id);
        setConfirmDelete(true)
      };
      return (
        <TableAction onEdit={onEdit} onDelete={onDelete} />
      );
    };

    headerInfo[headerIdList['banner_type']]["renderCell"] = (params) => {
      return(
        <p>
          {params.formattedValue === '1' && 'Home Page'}
          {params.formattedValue === '2' && 'Product Page'}
          {params.formattedValue === '3' && 'Artist Page'}
        </p>
      )
    };

    headerInfo[headerIdList['is_active']]["renderCell"] = (params) => {

      const is_active = params.formattedValue;

      return (
        <StatusLabel status={is_active === "1" ? "active" : "inactive"} />
      )
    }

    setHeaderInfo(headerInfo);

  }, [headerInfo, navigate])

  useEffect(() => {

    modifyHeader();

  }, [headerInfo, navigate, modifyHeader]);

  const handleConfirmDelete = async() => {
    setConfirmDelete(false)
    setLoading(true);
    try {

      const { data : deletedInfo } = await post(
        `${apiServer.banner.delete}?id=${bannerId}`
      );
      

      if(! (deletedInfo?.status >= 400 )){
        
        const dataList = modifiedData(DeleteRow(rowData, bannerId));
        setRowData(dataList);
        setTotalRecords(totalRecords - 1);
        if(dataList.length === 0){

          setPage((prev) => prev > 1  ? prev - 1 : 1);
          setCurrentPageList((prev) => {
            return {
              ...currentPageList,
              "banner" :  prev > 1 ? prev - 1 : 1
            }
          });
        }

      }

      setLoading(false);
      setActionStatus(StatusCode[deletedInfo?.status]);
      setActionMessage(deletedInfo?.message);

    } catch (err) {

      setLoading(false);
      setActionStatus("error");
      setActionMessage(err.message);
      
    }

  }

  const handleChangePage = (currentPage) => {
    setPage(currentPage + 1);
    setCurrentPageList({
      ...currentPageList,
      "banner" : currentPage + 1
    });
  }
  const handleChangePageSize = (rows) => {
    setRowPerPage(rows)
  }

  return (
    <>
        <DataList
          title={"Banner List"}
          headers={headerInfo}
          rowData={rowData}
          isLoading={loading}
          totalCount={totalRecords}
          error={getListError}
          currentPage={page}
          pageSize={rowPerPage}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangePageSize}
          deleteMsg={actionMessage}
          deleteStatus={actionStatus}
          emptyDataText={'There is no banner. Please Create One.'}
        />

      { confirmDelete && 
          <ConfirmationPopup
              headerText="Delete Banner"
              body={"Are you sure you want to delete this banner ?"}
              onSubmit={handleConfirmDelete}
              onCancel={() => {
                setConfirmDelete(false)
              }}
        />
      }

    </>
  );
}

export default Banner