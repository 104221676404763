import React, { useEffect, useState } from "react";
import ListHeaderBar from "../../common/ListHeaderBar";
// import Loading from "../../common/Loading";
import apiServer from "../../constants/apiServer";
import DashboardInfoCard from "./DashboardInfoCard";
import { useNavigate } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import ArtistType from "../../icons/artist_type";
import Product from "../../icons/product";
import "../../css/dashboard.sass";
import DashboardCard from "./DashboardCard";
import { DoughnutChart } from "./DoughnutChart";
import randomColor from "randomcolor";
import BarChart from "./BarChart";
import { Scrollbars } from "react-custom-scrollbars";
import { img_url_api } from "../../constants/apiImg";
import DashboardSelectBox from "./DashboardSelectBox";
import EmptyPage from "../../common/EmptyPage";
import BarLoading from "../../common/BarLoading";
import useFetchInfo from "../../utils/useFetchInfo";


const Dashboard = () => {
  const navigate = useNavigate();

  const [dashboardInfo, setDashboardInfo] = useState({
    totalArtistType: 0,
    totalUsers: 0,
    totalProduct: 0,
    productNameList: [],
    productCountList: [],
    productPriceList: [],
    artistCountByArtistTypeList: [],
    artistTypeList: [],
    artistTypeListColor: []
  });

  const [categoryList , setCategoryList ] = useState([]);

  const [shownProductInfo, setShownProductInfo] = useState("Price");

  const [getInfoLoading, setGetInfoLoading] = useState(false);
  const [getInfoError, setGetInfoError] = useState("");

  const { data, isLoading, error } = useFetchInfo(apiServer.dashboard.list);


  const sortArray = (list, keyName) => {
    const sortedList = list?.sort((a, b) => {
        return parseInt(b[keyName]) - parseInt(a[keyName]);
      });
      
      return sortedList;
  }

  useEffect(() => {

    setGetInfoLoading(isLoading);
    setGetInfoError(error);

    const { total_artist_type, total_user, total_product, categoryList, artistTypeCountList, productList } = data;

    const productCountData = productList?.map((element) =>{
        return {
          ...element,
          "qty": parseInt(element?.qty)
        }
      });

    const productPriceData = productList?.map((element) =>{
        return {
          ...element,
          "price" : parseInt(element?.price)
        }
      });
    

    setDashboardInfo({

      totalArtistType : total_artist_type,
      totalUsers : total_user,
      totalProduct: total_product,
      productCountList: sortArray(productCountData, "qty"),
      productPriceList: sortArray(productPriceData, "price"),
      artistTypeList: artistTypeCountList,
      artistCountByArtistTypeList: artistTypeCountList?.map((element) => element?.artist_count),
      artistTypeListColor: randomColor({
        count: artistTypeCountList?.length,
        hue: "blue"
      })

    });

    setCategoryList(categoryList);

  }, [ shownProductInfo, data, isLoading, error ]);

  const {
    totalArtistType,
    totalUsers,
    totalProduct,
    productCountList,
    productPriceList,
    artistCountByArtistTypeList,
    artistTypeList,
    artistTypeListColor
  } = dashboardInfo;

  if (getInfoLoading) return <BarLoading text="Retrieving Information" />;

  if (getInfoError) return <EmptyPage text={getInfoError} />;

  return (
    <div className="ada-operator-list-page">
      <ListHeaderBar title={"Overview Dashboard"} hideCreateBtn={true} />
      <Scrollbars
        autoHide
        autoHideTimeout={200}
        autoHideDuration={200}
        style={{ height: "calc(100vh - 15vh)" }}
      >
        <div>
          <div className="ada-dashboard-info-card-list">
            <DashboardInfoCard
              cardInfo={{
                name: "Artist Types",
                count: totalArtistType,
                icon: <ArtistType size="24px" />
              }}
              onRoute={() => {
                navigate("/artist_types", { replace: true });
              }}
              color={"var(--background-color)"}
            />
            <DashboardInfoCard
              cardInfo={{
                name: "Users",
                count: totalUsers,
                icon: <FaUserAlt size="22px" />
              }}
              onRoute={() => {
                navigate("/users", { replace: true });
              }}
              color={"var(--background-color)"}
            />
            <DashboardInfoCard
              cardInfo={{
                name: "Products",
                count: totalProduct,
                icon: <Product size="24px" />
              }}
              onRoute={() => {
                navigate("/products", { replace: true });
              }}
              color={"var(--background-color)"}
            />
          </div>
          <div className="ada-dashboard-info-card-list">
            <DashboardCard
              title="Artist Overview"
              className={"overview-card"}
              onRoute={() => {
                navigate("/artists", { replace: true });
              }}
            >
              <div className="artist-overview-chart">
                <DoughnutChart
                  data={{
                    labels: artistTypeList?.map((element) => element?.name?.en),
                    datasets: [
                      {
                        label: "",
                        data: artistCountByArtistTypeList,
                        backgroundColor: artistTypeListColor
                      }
                    ]
                  }}
                />
              </div>
              <div className="ada-artist-overview-sub-header">
                <label>Artist Types</label>
                <label>Count</label>
              </div>
              <div className="ada-artist-type-list-container">
                <Scrollbars style={{ height: "150px" }}>
                  {artistTypeList.map((item, index) => (
                    <ArtistTypeInfo
                      key={index}
                      color={artistTypeListColor[index]}
                      artistInfo={item}
                    />
                  ))}
                </Scrollbars>
              </div>
            </DashboardCard>
            <DashboardCard
              title="Category List"
              className={"overview-card"}
              onRoute={() => {
                navigate("/categories", { replace: true });
              }}
            >
              <Scrollbars
                autoHide
                autoHideDuration={200}
                autoHideTimeout={200}
                style={{ height: "380px" }}
              >
                {categoryList.map((category, categoryIndex) => (
                  <CategoryInfo categoryInfo={category} key={categoryIndex} />
                ))}
              </Scrollbars>
            </DashboardCard>
          </div>
          <div className="ada-dashboard-info-card-list">
            <DashboardCard
              title="Product Overview"
              className={"product-overview-card"}
              headerAction={
                <DashboardSelectBox
                  selectedValue={shownProductInfo}
                  list={["Price", "Count"]}
                  onSelect={(chosenValue) => setShownProductInfo(chosenValue)}
                />
              }
            >
              {shownProductInfo === "Price" && (
                <BarChart
                  data={{
                    labels: productPriceList.map((element) => element?.name?.en),
                    datasets: [
                      {
                        label: "Price",
                        backgroundColor: "rgb(105, 65, 198)",
                        // borderColor: 'rgb(105, 65, 198)',
                        data: productPriceList.map((element) => element?.price),
                        maxBarThickness: 80
                      }
                    ]
                  }}
                />
              )}

              {shownProductInfo === "Count" && (
                <BarChart
                  data={{
                    labels: productCountList.map((element) => element?.name?.en),
                    datasets: [
                      {
                        label: "Count",
                        backgroundColor: "rgb(105, 65, 198)",
                        // borderColor: 'rgb(105, 65, 198)',
                        data: productCountList.map((element) => element?.qty),
                        maxBarThickness: 80
                      }
                    ]
                  }}
                />
              )}
            </DashboardCard>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

const ArtistTypeInfo = ({ color, artistInfo }) => {
  return (
    <div className="artist-type-info-container">
      <div className="artist-type-info-left-side">
        <span
          className="artist-type-color"
          style={{ backgroundColor: color }}
        />
        <label>{artistInfo["name"]["en"]}</label>
      </div>
      <div className="artist-type-count">{artistInfo["artist_count"]}</div>
    </div>
  );
};

const CategoryInfo = ({ categoryInfo }) => {
  const name = categoryInfo?.name;

  return (
    <div className="category-info-container">
      <img src={img_url_api + categoryInfo.image} alt="category-img" />
      <span>{name.en}</span>
    </div>
  );
};

export default Dashboard;
