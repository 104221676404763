import React from 'react'
import "../css/common.sass"
import NoData from "../image/NoData.svg";

const EmptyPage = ({ text }) => {
  return (
    <div className='main-empty-page-container'>
      <img src={NoData} alt="empty-data" width="300px" />
      <p>{text}</p>
    </div>
  )
}

export default EmptyPage