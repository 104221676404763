import React, { useEffect, useState } from "react";
import InputField from "../../common/InputField";
import apiServer from "../../constants/apiServer";
import useFetchInfo from "../../utils/useFetchInfo";
import "../../css/artist.sass";
import Loading from "../../common/Loading";
import EmptyPage from "../../common/EmptyPage";


const GeneralInformation = ({ states, onChange, errorStates, onChangeSelect }) => {

  const [categoryList, setCategoryList] = useState([]);

  const { data, isLoading, error } = useFetchInfo(apiServer.product.filter);

  const {
    productEngName,
    productMyanName,
    productCode,
    chosenCategory,
    currency,
    price,
    quantity,
    engDescription,
    myanDescription
  } = states;


  useEffect(() => {
    const modifiedCategory = [];
    const catalogList = data?.catalog;
    for (let i = 0; i < catalogList?.length; i++) {
      const categoryName = catalogList[i]?.catalog_name;
      modifiedCategory.push({
        value: catalogList[i]?.id,
        label: `${categoryName?.en}:${categoryName?.mm}`
      });
    }
    setCategoryList(modifiedCategory)
    console.log("chosenCategory::", chosenCategory);
    if(chosenCategory === ""){
        onChangeSelect('chosenCategory', modifiedCategory[0])
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, error]);

  console.log("erroState,", errorStates)

  if(isLoading) return <Loading text='Retrieving Information' />

  if(error) return <EmptyPage text={error} />

  return (
    <div className="create-product-form">
        <div className="create-product-form-left-side">
            <div className="create-product-form-row">
                <InputField
                    title='Name (in English)'
                    isRequired
                    id="Product Name English"
                    name='productEngName'
                    value={productEngName}
                    onChange={onChange}
                    main-width="100%"
                    width={'230px'}
                    layout="horizontal"
                    isFlag={"productEngName" in errorStates || productEngName?.length > 100}
                    errMsg={productEngName?.length > 100 ? "Must not exceed more than 100 words" :errorStates.productEngName}
                />
            </div>
            <div className="create-product-form-row">
                <InputField
                    title='Name (in Myanmar)'
                    isRequired
                    id="Product Name Myanmar"
                    name='productMyanName'
                    value={productMyanName}
                    onChange={onChange}
                    main-width="100%"
                    width={'230px'}
                    layout="horizontal"
                    isFlag={"productMyanName" in errorStates || productMyanName?.length > 100}
                    errMsg={productMyanName?.length > 100 ? "Must not exceed more than 100 words" : errorStates.productMyanName}
                />
            </div>
            <div className="create-product-form-row">
                <InputField
                    title="Product Code"
                    isRequired
                    id="Product Code"
                    name="productCode"
                    value={productCode}
                    onChange={onChange}
                    main-width="100%"
                    width={'230px'}
                    layout="horizontal"
                    isFlag={"productCode" in errorStates || productCode?.length > 250}
                    errMsg={productCode?.length > 250 ? "Must not exceed more than 250 words" : errorStates.productCode}
                />
            </div>
            <div className="create-product-form-row">
                <InputField
                    title="Category"
                    isRequired
                    id="Category"
                    fieldType={"search-select"}
                    main-width="100%"
                    isFlag={"chosenCategory" in errorStates}
                    errMsg={errorStates.chosenCategory}
                    layout="horizontal"
                    selectBoxClassName={"choose-product-select-box"}
                    selectValue={chosenCategory}
                    selectOptionList={categoryList}
                    searchableSelect
                    onChangeSelectBox= {(chosenValue) => {
                        onChangeSelect("chosenCategory", chosenValue)
                    }}
                />
            </div>
            <div className="create-product-form-row">
                <InputField
                    title="Price"
                    isRequired
                    id="Price"
                    main-width="100%"
                    layout="horizontal"
                    fieldType={"customize"}
                    isFlag={"currency" in errorStates || "price" in errorStates || price?.length > 50}
                    errMsg={price?.length > 50 ? "Must not exceed more than 50 words" :'This is mandatory field'}
                >
                <div className="create-product-choose-price-contaier">
                    <select value={currency} name='currency' style={{width: '80px'}} className="input-field-currency-selectbox"  onChange={onChange}>
                        <option value={1}>MMK</option>
                        <option value={2}>$</option>
                    </select>
                    <input
                        type="integer"
                        id="price"
                        name="price"
                        value={price}
                        onChange={onChange}
                        className="choose-price-input-box"
                    />
                </div>
                </InputField>
            </div>
        </div>
        <div className="create-product-form-right-side">
            <div className="create-product-form-row">
            <InputField
                title="Quantity"
                isRequired
                id="Quantity"
                main-width="100%"
                width='230px'
                layout={"horizontal"}
                type="integer"
                value={quantity}
                name='quantity'
                onChange={onChange}
                isFlag={"quantity" in errorStates}
                errMsg={errorStates.quantity}
                />
            </div>
            <div className="create-product-form-row">
                <InputField
                    title="Description( in English )"
                    isRequired
                    id="engDescription"
                    main-width="100%"
                    width='97%'
                    layout="vertical"
                    fieldType={"textarea"}
                    name='engDescription'
                    description={engDescription}
                    onChangeDescription={onChange}
                    isFlag={"engDescription" in errorStates}
                    errMsg={errorStates.engDescription}
                />
            </div>
            <div className="create-product-form-row">
                <InputField
                    title="Description ( in Myanmar) "
                    isRequired
                    id="myanDescription"
                    name='myanDescription'
                    main-width="100%"
                    width='97%'
                    layout={"vertical"}
                    fieldType="textarea"
                    description={myanDescription}
                    onChangeDescription={onChange}
                    isFlag={"myanDescription" in errorStates}
                    errMsg={errorStates.myanDescription}
                />

            </div>               
        </div>
      
    </div>
  );
};

export default GeneralInformation;
