import React, { useEffect, useState } from "react";
import useFetchInfo from "../../utils/useFetchInfo";
import { useNavigate } from "react-router-dom";
import apiServer from "../../constants/apiServer";
import Loading from "../../common/Loading";
import { post } from "../../utils/api";
import PopupLoading from "../../common/PopupLoading";
import AdaAlert from "../../common/AdaAlert";
import CategoryForm from "./CategoryForm";
import { checkFormValidate } from "../../utils/commonFuncs";
import { stringify } from "query-string";
import EmptyPage from "../../common/EmptyPage";

const CreateCategory = () => {
    const navigate = useNavigate();

    const [categoryInfoValues, setCategoryInfoValues] = useState({
        categoryImg: null,
        engName: "",
        myanName: "",
        subCategory: "no",
        categoryList: [],
        chosenCategory: "",
    });

    const [errorValues, setErrorValues] = useState({});

    const [createCategoryLoading, setCreateCategoryLoading] = useState(false);
    const [errorCreate, setErrorCreate] = useState("");

    const { data, error, isLoading } = useFetchInfo(apiServer.category.filter);

    useEffect(() => {
        const modifiedCategoryList = [];
        for (let i = 0; i < data?.length; i++) {
            const categoryName = data[i]?.name;
            modifiedCategoryList.push({
                value: data[i]?.id,
                label: `${categoryName?.en} : ${categoryName?.mm}`,
            });
        }
        setCategoryInfoValues({
            categoryImg: null,
            engName: "",
            myanName: "",
            subCategory: "no",
            categoryList: modifiedCategoryList,
            chosenCategory: modifiedCategoryList[0],
        });
    }, [data, isLoading, error]);

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setCategoryInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                [name]: value,
            };
        });
    };

    const handleChooseValue = (selectedOption) => {
        setCategoryInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                chosenCategory: selectedOption,
            };
        });
        delete errorValues["chosenCategory"];
        setErrorValues(errorValues);
    };

    const handleUploadImage = (imageInfo) => {
        setCategoryInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                categoryImg: imageInfo,
            };
        });
        delete errorValues["categoryImg"];
        setErrorValues(errorValues);
    };

    const handleRemoveImage = () => {
        setCategoryInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                categoryImg: null,
            };
        });
    };

    const handleCreateCategory = async (event) => {
        event.preventDefault();
        const { categoryImg, engName, myanName, subCategory, chosenCategory } =
            categoryInfoValues;
        const checkGeneralInfo = checkFormValidate({
            categoryImg,
            engName,
            myanName,
            subCategory,
            chosenCategory,
        });
        setErrorValues(checkGeneralInfo);
        const errorStateList = Object.keys(checkGeneralInfo);
        const categoryParentId =
            subCategory === "yes" ? chosenCategory?.value : 0;
        const categoryBody = new FormData();
        categoryBody.append("image", categoryImg);
        if (
            errorStateList.length === 0 &&
            engName.length <= 100 &&
            myanName.length <= 100
        ) {
            setCreateCategoryLoading(true);
            const query = {
                nameENG: engName,
                nameMM: myanName,
                parent_id: categoryParentId,
            };
            const createCategoryURL = `${apiServer.category.create}?${stringify(
                query
            )}`;
            try {
                const { data } = await post(createCategoryURL, categoryBody);
                setCreateCategoryLoading(false);
                if (data.status >= 400) {
                    setErrorCreate(data.message);
                } else {
                    if (data.status === "success") {
                        navigate("/categories", {
                            replace: true,
                            state: { status: "success", message: data.message },
                        });
                    } else if (data.code >= 400) {
                        setErrorCreate("Please fill your information");
                    }
                }
            } catch (error) {
                setCreateCategoryLoading(false);
                setErrorCreate(error.message);
            }
        }
    };

    const handleCancelCreate = () => {
        navigate("/categories", { replace: true });
    };

    if (isLoading) return <Loading text="Retrieving Information" />;

    if (error) return <EmptyPage text={error} />;

    if (createCategoryLoading)
        return <PopupLoading text="Creating Category..." />;

    return (
        <>
            <CategoryForm
                formTitle={"Create Category"}
                onCreate={handleCreateCategory}
                onCancel={handleCancelCreate}
                onSubmitName="Create"
                infoValues={categoryInfoValues}
                errorValues={setErrorValues}
                onUploadImg={handleUploadImage}
                onRemoveImg={handleRemoveImage}
                onChangeValues={handleChangeValue}
                onChangeSelect={handleChooseValue}
            />

            {errorCreate !== "" && (
                <AdaAlert type="error" message={errorCreate} />
            )}
        </>
    );
};

export default CreateCategory;
