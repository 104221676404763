/* eslint-disable import/no-anonymous-default-export */
export default {
    "auth": {
        'login': '/admin/authorization/login',
        'logout': '/admin/authorization/logout'
    },
    'banner':{
        'create': '/admin/banner/create',
        'update': '/admin/banner/update',
        'delete': '/admin/banner/delete',
        'getDetail': '/admin/banner/detail',
        'list': '/admin/banner/list',
    },
    'operator':{
        'create': '/admin/operator/create',
        'update': '/admin/operator/update',
        'delete': '/admin/operator/delete',
        'getDetail': '/admin/operator/detail',
        'list': '/admin/operator/list',
    },
    'artist_type': {
        'create': '/admin/artisttype/create',
        'update': '/admin/artisttype/update',
        'delete': '/admin/artisttype/delete',
        'detail': '/admin/artisttype/detail',
        'list': '/admin/artisttype/list'
    },
    'artists': {
        'create': '/admin/artist/create',
        'update': '/admin/artist/update',
        'delete': '/admin/artist/delete',
        'detail': '/admin/artist/detail',
        'list': '/admin/artist/list',
        'filter':'/admin/artist/getArtisttype'
    },
    'category': {
        'create': '/admin/catalog/create',
        'update': '/admin/catalog/update',
        'delete': '/admin/catalog/delete',
        'detail': '/admin/catalog/detail',
        'list': '/admin/catalog/list',
        'filter' : '/admin/catalog/getall'
    },
    'product' : {
        'create': '/admin/product/create',
        'update': '/admin/product/update',
        'delete': '/admin/product/delete',
        'detail': '/admin/product/detail',
        'list': '/admin/product/list',
        'filter' : '/admin/product/getfilter'
    },
    'process': {
        'create': '/admin/process/create',
        'update': '/admin/process/update',
        'delete': '/admin/process/delete',
        'detail': '/admin/process/detail',
        'list': '/admin/process/list',
        'filter': '/admin/process/setupfilter'
    },
    'user': {
        'list': '/admin/user/list',
    },
    'dashboard' : {
        'list' : '/admin/dashboard/list'
    }
}