import React from 'react'

const banner = ({size}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill='inherit' xmlns="http://www.w3.org/2000/svg">
        {/* <rect width="24" height="24" fill="white"/> */}
        <path d="M12.8961 3V4.14056H11.1039V3H12.8961ZM19.8312 4.3457C19.9669 4.39368 20.1143 4.449 20.2673 4.51869C20.4837 4.61712 20.6938 4.73688 20.8287 4.85105C20.9636 4.96518 21 5.05057 21 5.09102C21 5.13143 20.9636 5.21686 20.8287 5.33103C20.6938 5.44516 20.4837 5.56492 20.2673 5.66339C20.1143 5.73304 19.9669 5.78836 19.8312 5.83634V4.3457ZM4.16883 4.3457V5.83634C4.03305 5.78836 3.8857 5.73304 3.73266 5.66335C3.51631 5.56492 3.30623 5.44516 3.17127 5.33099C3.03635 5.21686 3 5.13147 3 5.09102C3 5.05061 3.03635 4.96518 3.17127 4.85101C3.30623 4.73688 3.51631 4.61712 3.73266 4.51865C3.8857 4.449 4.03305 4.39368 4.16883 4.3457V4.3457ZM19.1299 4.82489V5.35715H17.3377V4.82489H19.1299ZM16.6364 4.82489V15.9103L12 21L7.36364 15.9103V4.82489H16.6364ZM6.66234 4.82489V5.35715H4.87013V4.82489H6.66234ZM14.8442 5.69932H14.1429V15.3121L12 17.9259L9.85714 15.3121V5.69932H9.15584V15.5521L12 19.0212L14.8442 15.5521V5.69932Z" fill='inherit'/>
    </svg>

  )
}

export default banner