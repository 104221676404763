import React, { Suspense } from 'react';
import { DataProvider } from "./context/dataProvider";
import "./css/common.sass";
import Loading from './common/Loading';
import MainRoute from './MainRoute';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <DataProvider>
      <Suspense fallback={<Loading text='Loading' />}>
        <BrowserRouter>
          <MainRoute />
        </BrowserRouter>
      </Suspense>
    </DataProvider>
  );
}

export default App;
