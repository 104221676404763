import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkFormValidate } from "../../utils/commonFuncs";
import apiServer from "../../constants/apiServer";
import { post } from "../../utils/api";
import PopupLoading from "../../common/PopupLoading";
import AdaAlert from "../../common/AdaAlert";
import "../../css/artist.sass";
import BannerForm from "./BannerForm";

const CreateBanner = () => {
    const navigate = useNavigate();
    const [bannerInfoValues, setBannerInfoValues] = useState({
        name: "",
        banner_type: 1,
        is_active: 1,
        remark: "",
        imageList: [],
    });

    const [errorInfoList, setErrorInfoList] = useState({});
    const [createLoading, setCreateLoading] = useState(false);
    const [createError, setCreateError] = useState("");

    const handleChangeInput = (event) => {
        const { name, value } = event.target;
        setBannerInfoValues((generalInfo) => {
            return {
                ...generalInfo,
                [name]:
                    name === "banner_type" || name === "is_active"
                        ? parseInt(value)
                        : value,
            };
        });
        delete errorInfoList[name];
        setErrorInfoList(errorInfoList);
    };

    const handleUploadImg = (imgInfo) => {
        setBannerInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                imageList: [...prevInfo["imageList"], imgInfo],
            };
        });
    };

    const handleChangeCaption = (value, imageInfo) => {
        const modifiedImageList = bannerInfoValues["imageList"];
        let modifiedImageId = modifiedImageList.findIndex(
            (element) => element.id === imageInfo.id
        );
        modifiedImageList[modifiedImageId]["caption"] = value;
        setBannerInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                imageList: modifiedImageList,
            };
        });
    };

    const handleRemoveImage = (imageInfo) => {
        setBannerInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                imageList: bannerInfoValues["imageList"].filter(
                    (element) => element !== imageInfo
                ),
            };
        });
    };

    const handleCancleCreate = () => {
        navigate("/banners", { replace: true });
    };

    const handleCreateBanner = async (event) => {
        event.preventDefault();
        const { name, banner_type, is_active, remark, imageList } =
            bannerInfoValues;
        const infoValue = {
            name,
            banner_type,
            is_active,
            remark,
            imageList,
        };

        const checkFormValues = checkFormValidate(infoValue);
        setErrorInfoList(checkFormValues);
        if (Object.keys(checkFormValues)?.length === 0 && name.length < 100) {
            setCreateLoading(true);
            const body = new FormData();
            for (let i = 0; i < imageList.length; i++) {
                body.append(`images[${i}]`, imageList[i]?.file);
                body.append(`caption[${i}]`, imageList[i]?.caption);
            }

            Object.keys(bannerInfoValues).map((element) =>
                body.append(element, bannerInfoValues[element])
            );
            const bannerURL = `${apiServer.banner.create}`;

            try {
                const { data } = await post(bannerURL, body);
                setCreateLoading(false);
                if (data.status >= 400) {
                    setCreateError(data.message);
                } else {
                    if (data.status === "success") {
                        navigate("/banners", {
                            replace: true,
                            state: { status: "success", message: data.message },
                        });
                    } else if (data.code >= 400) {
                        const responseErrList = Object.values(data)[0];
                        setCreateError(responseErrList[0]);
                    }
                }
            } catch (error) {
                setCreateLoading(false);
                setCreateError(error.message);
            }
        }
    };

    if (createLoading)
        return <PopupLoading text="Creating Banner Information..." />;

    return (
        <>
            <BannerForm
                formTitle={"Create Banner"}
                onSubmitName="Create"
                onCreate={handleCreateBanner}
                onCancel={handleCancleCreate}
                onChangeValue={handleChangeInput}
                onUploadImg={handleUploadImg}
                onRemoveImg={handleRemoveImage}
                infoValues={bannerInfoValues}
                errorValues={errorInfoList}
                onChangeCaption={handleChangeCaption}
            />

            {createError !== "" && (
                <AdaAlert type="error" message={createError} />
            )}
        </>
    );
};

export default CreateBanner;
