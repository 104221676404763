import React, { useState, useEffect } from "react";
import useFetchInfo from "../../utils/useFetchInfo";
import apiServer from "../../constants/apiServer";
import Loading from "../../common/Loading";
import "../../css/process.sass";
import InputField from "../../common/InputField";
import EmptyPage from "../../common/EmptyPage";


const total_step = 6;

const GeneralProcessInfo = ({
  processInfo,
  onChange,
  errorStates,
  onChooseProduct
}) => {
  const { nameEng, nameMyan, step, chosenProduct } = processInfo;

  const { data, isLoading, error } = useFetchInfo(apiServer.process.filter);
  // console.log("process filter::", data)
  const [productList, setProductList] = useState([]);
  const [getInfoLoading, setGetInfoLoading] = useState(true);
  const [getInfoError, setGetInfoError] = useState("");
  const [stepList, setStepList] = useState([]);
  const [createStepLoading, setCreateStepLoading ] = useState(false);

  useEffect(() => {
    const totalStepList = [];
    for(let i = 0 ; i < total_step ; i++){
      totalStepList.push({
        label: `Step ${i+1}`,
        value: i+1,
      })
    };
    setStepList(totalStepList);
  }, [])
  

  useEffect(() => {
    const productInfoList = data?.product;
    const productInfo = [];

    for (let i = 0; i < productInfoList?.length; i++) {
      const productName = productInfoList[i]["product_name"];
      productInfo.push({
        // id: productInfoList[i]["id"],
        label: `${productName["en"]} : ${productName["mm"]}`,
        value: productInfoList[i]["id"]
      });
    }
    setProductList(productInfo);
    setGetInfoLoading(isLoading);
    setGetInfoError(error);
  }, [data, isLoading, error]);

  const handleCreateStep = (inputValue) => {
    setCreateStepLoading(true);
    setTimeout(()=> {
      setStepList((prevSteps)=> {
       return [...prevSteps, {label: inputValue, value: inputValue}]
      });
      onChooseProduct('step', {label: inputValue, value: inputValue})
      setCreateStepLoading(false);
    },500)
  };

  if (getInfoLoading) return <Loading text="Retrieving Information" />;

  if (getInfoError) return <EmptyPage text={getInfoError} />

  return (
    <div className="create-process-form">
      <div className="create-process-form-row">
        <InputField
          title="Name(in English)"
          isRequired
          id="Process Name English"
          name="nameEng"
          value={nameEng}
          onChange={onChange}
          // layout="vertical"
          isFlag={"nameEng" in errorStates}
          mainWidth='100%'
          width='250px'
          errMsg={errorStates?.nameEng}
        />
      </div>
      <div className="create-process-form-row">
        <InputField
          title="Name(in Myanmar)"
          isRequired
          id="Process Name Myanmar"
          name="nameMyan"
          value={nameMyan}
          onChange={onChange}
          // layout="vertical"
          isFlag={"nameMyan" in errorStates}
          mainWidth='100%'
          width='250px'
          errMsg={errorStates?.nameMyan}
        />
      </div>
      <div className="create-process-form-row">
        <InputField
          title="Choose Product"
          isRequired
          id="product name"
          name="chosenProduct"
          selectValue={chosenProduct}
          selectOptionList ={productList}
          // layout='vertical'
          mainWidth='100%'
          selectBoxClassName="process-select-box-container"
          searchableSelect
          onChangeSelectBox={(chosenValue) => { onChooseProduct('chosenProduct', chosenValue) }}
          fieldType = 'search-select'
          isFlag={'chosenProduct' in errorStates}
          errMsg={errorStates?.chosenProduct}
        />
      </div>
      <div className="create-process-form-row">
        <InputField
          title="Step No."
          isRequired
          id="Process Step No"
          name="step"
          // layout="vertical"
          mainWidth='100%'
          width='250px'
          onChangeSelectBox={(chosenValue) => { onChooseProduct('step', chosenValue) }}
          selectOptionList={stepList}
          createSelectValue={step}
          createValueLoading={createStepLoading}
          selectBoxClassName="process-select-box-container"
          onInputSelectChange={handleCreateStep}
          fieldType='create-select'
          isFlag={'step' in errorStates}
          errMsg={errorStates?.step}
        />
      </div>
    </div>
  );
};

export default GeneralProcessInfo;
