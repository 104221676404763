import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiServer from "../../constants/apiServer";
import { post } from "../../utils/api";
import AdaAlert from "../../common/AdaAlert";
import PopupLoading from "../../common/PopupLoading";
import { useParams } from "react-router-dom";
import SquareLoading from "../../common/SquareLoading";
import useFetchInfo from "../../utils/useFetchInfo";
import { checkFormValidate } from "../../utils/commonFuncs";
import ArtistTypeForm from "./ArtistTypeForm";
import EmptyPage from "../../common/EmptyPage";

const EditArtistType = () => {
    const navigate = useNavigate();

    const { artistTypeId } = useParams();

    const [artistTypeInfoValues, setArtistTypeInfoValues] = useState({
        nameENG: "",
        nameMM: "",
    });

    const [artistTypeErrorValues, setArtistTypeErrorValues] = useState({});

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setArtistTypeInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                [name]: value,
            };
        });
        delete artistTypeErrorValues[name];
        setArtistTypeErrorValues(artistTypeErrorValues);
    };

    const [updateLoading, setUpdateLoading] = useState(false);
    const [errorUpdate, setErrorUpdate] = useState("");

    const { data, isLoading, error } = useFetchInfo(
        `${apiServer.artist_type.detail}?id=${artistTypeId}`
    );

    useEffect(() => {
        setArtistTypeInfoValues({
            nameENG: data?.name?.en,
            nameMM: data?.name?.mm,
        });
    }, [data, isLoading, error]);

    const handleUpdateArtistType = async (event) => {
        event.preventDefault();
        const checkGeneralInfo = checkFormValidate({
            nameENG: artistTypeInfoValues["nameENG"],
            nameMM: artistTypeInfoValues["nameMM"],
        });
        setArtistTypeErrorValues(checkGeneralInfo);
        const errorStateList = Object.keys(checkGeneralInfo);
        if (errorStateList.length === 0) {
            setUpdateLoading(true);
            const fd = new FormData();
            fd.append("id", artistTypeId);
            Object.keys(artistTypeInfoValues).map((element) =>
                fd.append(element, artistTypeInfoValues[element])
            );
            const updateArtistURL = `${apiServer.artist_type.update}`;
            try {
                const { data } = await post(updateArtistURL, fd);
                setUpdateLoading(false);
                if (data.status > 400) {
                    setErrorUpdate(data.message);
                } else {
                    if (data.code >= 400) {
                        setErrorUpdate("Data should not be empty");
                    } else if (data.status === "success") {
                        navigate("/artist_types", {
                            replace: true,
                            state: { status: "success", message: data.message },
                        });
                    }
                }
            } catch (error) {
                setUpdateLoading(false);
                setErrorUpdate(error.message);
            }
        }
    };

    const handleCancel = () => {
        navigate("/artist_types", { replace: true });
    };

    if (isLoading)
        return <SquareLoading text="Retrieving Artist Type Information" />;
    if (updateLoading) return <PopupLoading text={"Uploading Artist Type"} />;
    if (error) return <EmptyPage text={error} />;

    return (
        <>
            <ArtistTypeForm
                onCreate={handleUpdateArtistType}
                onCancel={handleCancel}
                infoValues={artistTypeInfoValues}
                errorValues={artistTypeErrorValues}
                onChangeValue={handleChangeValue}
                formTitle={"Update Artist Type"}
                onSubmitName={"Update"}
            />
            {errorUpdate !== "" && (
                <AdaAlert type="error" message={errorUpdate} />
            )}
        </>
    );
};

export default EditArtistType;
