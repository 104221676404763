import React, { useState } from "react";
import { IoImage } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md";
import "../css/common.sass";
import ProfileEditor from './ProfileEditor';

const ImageUpload = (props) => {

  const [openProfileEditor, setOpenProfileEditor ] = useState(false);

  const [uploadProfile, setUploadProfile ] = useState(null);

  const [ editProfile, setEditProfile ] = useState(null);

  const { uploadImg, onUploadImage, cancleUploadImg, isFlag, errMsg } = props;

  const handleUploadImg = (event) => {
   
    if(event.target.files?.[0]){
      setOpenProfileEditor(true);
      setUploadProfile(event.target.files[0])
    }
    
  }


  const removeImage = () => {
    setEditProfile(null)
    cancleUploadImg();
  }

  const handleSave = (editInfo) =>{
    setEditProfile(editInfo)
    fetch(editInfo.img)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], uploadProfile['name'],{ type: "image/png" })
      onUploadImage(file);
    })
    setOpenProfileEditor(false);
  }

  return (
    <>
      <div className="artist-profile-container" style={{border: isFlag && "1px solid brown"}}>
        {(editProfile !== null || uploadImg !== null) && (
          <img
            src={editProfile === null ? uploadImg : editProfile.img}
            style={{
              borderRadius: `${
                (Math.min(
                  editProfile?.height,
                  editProfile?.width,
                ) +
                  10) *
                (editProfile?.borderRadius / 2 / 100)
              }px`,
            }}
            alt="artist profile"
            width="100%"
          />
        )}
        {uploadImg === null && (
          <>
            <IoImage size="28px" />
            <input
              type="file"
              className="upload-profile"
              onChange={handleUploadImg}
            />
          </>
        )}
        {(uploadProfile !== null || uploadImg !== null) && (
          <button className="remove-profile" onClick={removeImage}>
            <MdOutlineClose />
          </button>
        )}
      </div>
      {errMsg && <div className="image-upload-error-message">{errMsg}</div>}
      <button className="upload-profile-btn">
        Upload Profile
        <input
          type="file"
          className="upload-profile-btn-input"
          onChange={handleUploadImg}
        />
      </button>

      {openProfileEditor && <ProfileEditor profileInfo={uploadProfile} replaceImg={handleUploadImg} onClose={()=> setOpenProfileEditor(false)} onSave={handleSave} /> }
    </>
  );
};

export default ImageUpload;
