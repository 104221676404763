import React, { useState } from 'react'
import { GoChevronDown } from "react-icons/go";
import "../../css/dashboard.sass"

const DashboardSelectBox = ({ selectedValue, list, onSelect }) => {

    const [ showList, setShowList ] = useState(false);
    const handleChooseValue = (value) => {
        setShowList(false);
        onSelect(value)
    }

  return (
    <div className='dashboard-select-box-container'>
        <button className='dashboard-chosen-value' onClick={() => {setShowList(!showList)}}>
            <span>{selectedValue}</span>
            <GoChevronDown size='18px' />
        </button>
        {
            showList && <div className='dashboard-select-list'>
                {
                    list.map((value, index)  => 
                        <button key={index} onClick={() => handleChooseValue(value)}>{value}</button>
                    )
                }
            </div>
        }
    </div>
  )
}

export default DashboardSelectBox