import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import apiServer from "../../constants/apiServer";
import { post } from "../../utils/api";
import format from "date-fns/format";
import AdaAlert from "../../common/AdaAlert";
import PopupLoading from "../../common/PopupLoading";
import "../../css/artist.sass";
import useFetchInfo from "../../utils/useFetchInfo";
import { img_url_api } from "../../constants/apiImg";
import ArtistForm from "./ArtistForm";
import { checkFormValidate } from "../../utils/commonFuncs";
import EmptyPage from "../../common/EmptyPage";
import SquareLoading from "../../common/SquareLoading";

const UpdateArtist = () => {
    const { artist_Id } = useParams();
    const navigate = useNavigate();

    const [artistInfoValues, setArtistInfoValues] = useState({
        profile: null,
        nameEN: "",
        nameMM: "",
        type: [],
        chosenType: {},
        artisttype_id: 0,
        dob: new Date("1800-1-1"),
        gender: "Male",
        bioEN: "",
        bioMM: "",
    });

    const [errorValues, setErrorValues] = useState({});

    const [getInfoLoading, setGetInfoLoading] = useState(true);
    const [artistUpdateLoading, setArtistUpdateLoading] = useState(false);
    const [errorUpdateArtist, setErrorUpdateArist] = useState("");

    const [getInfoError, setGetInfoError] = useState("");

    const {
        data: typeList,
        isLoading: typeListLoading,
        error: typeListError,
    } = useFetchInfo(`${apiServer.artists.filter}`);

    const {
        data: artistInfo,
        isLoading: artistInfoLoading,
        error: artistInfoError,
    } = useFetchInfo(`${apiServer.artists.detail}?id=${artist_Id}`);

    useEffect(() => {
        setGetInfoLoading(typeListLoading && artistInfoLoading);
        setGetInfoError(typeListError || artistInfoError);
        const modifyData = [];
        typeList.forEach((element) => {
            const artistTypeName = element["name"];
            modifyData.push({
                value: element?.id,
                label: `${artistTypeName?.en} : ${artistTypeName?.mm}`,
            });
        });

        setArtistInfoValues({
            profile: img_url_api + artistInfo?.image,
            nameEN: artistInfo?.name?.en,
            nameMM: artistInfo?.name?.mm,
            type: modifyData,
            chosenType: {
                value: artistInfo?.artisttype_id,
                label: `${artistInfo?.artisttype_name?.en} : ${artistInfo?.artisttype_name?.mm}`,
            },
            artisttype_id: artistInfo?.artisttype_id,
            dob: new Date(artistInfo?.dob),
            gender: artistInfo?.gender === 0 ? "Male" : "Female",
            bioEN: artistInfo?.bio?.en,
            bioMM: artistInfo?.bio?.mm,
        });
    }, [
        typeListLoading,
        artistInfoLoading,
        typeListError,
        artistInfoError,
        artistInfo,
        typeList,
    ]);

    const handleCancelUpdate = () => {
        navigate("/artists", { replace: true });
    };

    const handleUploadImage = (imageInfo) => {
        setArtistInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                profile: imageInfo,
            };
        });
        delete errorValues["profile"];
        setErrorValues(errorValues);
    };

    const handleChangeDate = (date) => {
        const modifiedDate = new Date(date);
        setArtistInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                dob: modifiedDate,
            };
        });
    };

    const handleChangeSelect = (name, value) => {
        setArtistInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                [name]: value,
            };
        });
    };

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setArtistInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                [name]: value,
            };
        });
        delete errorValues[name];
        setErrorValues(errorValues);
    };

    const handleRemoveImage = () => {
        setArtistInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                profile: null,
            };
        });
    };

    const handleUpdateArtist = async (event) => {
        event.preventDefault();

        const checkGeneralInfo = checkFormValidate({
            profile: artistInfoValues["profile"],
            nameEN: artistInfoValues["nameEN"],
            nameMM: artistInfoValues["nameMM"],
            chosenType: artistInfoValues["chosenType"],
            bioEN: artistInfoValues["bioEN"],
            bioMM: artistInfoValues["bioMM"],
            dob: artistInfoValues["dob"],
            gender: artistInfoValues["gender"],
        });

        setErrorValues(checkGeneralInfo);

        const errorStateList = Object.keys(checkGeneralInfo);

        const { nameEN, nameMM } = artistInfoValues;

        if (
            errorStateList.length === 0 &&
            nameEN.length <= 100 &&
            nameMM.length <= 100
        ) {
            setArtistUpdateLoading(true);

            // form data
            const fd = new FormData();
            fd.append("image", artistInfoValues?.profile);
            fd.append("id", artist_Id);
            Object.keys(artistInfoValues).map((element) =>
                element === "gender"
                    ? fd.append(
                          "gender",
                          artistInfoValues["gender"] === "Male" ? 0 : 1
                      )
                    : element === "dob"
                    ? fd.append(
                          "dob",
                          format(artistInfoValues["dob"], "yyyy-MM-dd")
                      )
                    : fd.append(element, artistInfoValues[element])
            );
            // create api query
            const updateUrl = `${apiServer.artists.update}`;

            try {
                const { data } = await post(updateUrl, fd);
                setArtistUpdateLoading(false);
                if (data.status > 400) {
                    setErrorUpdateArist(data.message);
                } else {
                    if (data.code >= 400) {
                        setErrorUpdateArist("Please fill all the data");
                    } else if (data.status === "success") {
                        navigate("/artists", {
                            replace: true,
                            state: { status: "success", message: data.message },
                        });
                    }
                }
            } catch (err) {
                setArtistUpdateLoading(false);
                setErrorUpdateArist(err.message);
            }
        }
    };

    if (getInfoError) return <EmptyPage text={getInfoError} />;

    if (getInfoLoading)
        return <SquareLoading text={"Retrieving Artist Information..."} />;

    if (artistUpdateLoading)
        return <PopupLoading text="Updating Artist Information..." />;

    return (
        <>
            <ArtistForm
                formTitle={"Update Artist"}
                onChangeValue={handleChangeValue}
                onUploadProfile={handleUploadImage}
                onRemoveProfile={handleRemoveImage}
                onChangeDate={handleChangeDate}
                onSelectValue={handleChangeSelect}
                infoValues={artistInfoValues}
                errorValues={errorValues}
                onCreate={handleUpdateArtist}
                onCreateCancel={handleCancelUpdate}
                formSubmitName={"Update"}
            />

            {errorUpdateArtist !== "" && (
                <AdaAlert type="error" message={errorUpdateArtist} />
            )}
        </>
    );
};

export default UpdateArtist;
