import React from 'react';
import { useRoutes } from "react-router-dom";
import Login from "./components/Login";
import RequireAuth from "./utils/RequireAuth";
import Layout from "./common/Layout";
import { OperatorList, CreateOperator, EditOperator } from "./PageRoutes/Operator";
import { CreateArtist, ArtistList, UpdateArtist } from "./PageRoutes/Artist";
import { CreateBanner, BannerList, UpdateBanner } from "./PageRoutes/Banner";
import { CreateArtistType, ArtistTypeList, UpdateArtistType } from './PageRoutes/ArtistType';
import { CategoryList, UpdateCategory, CreateCategory } from "./PageRoutes/Category";
import { ProductList, CreateProduct, UpdateProduct } from "./PageRoutes/Product";
import { ProcessList, CreateProcess, UpdateProcess } from "./PageRoutes/Process";
import PageNotFound from "./common/PageNotFound";
import UserList from './components/User/UserList';
// import Dashboard from "./components/Dashboard/Dashboard";
import Dashboard from "./components/Dashboard/DashboardLocal";


const Router = () => {

    let routes = useRoutes( [
        {
            element: <Login />,
            path: '/login',
        },
        {
            element: <RequireAuth />,
            path: '',
            children: [
                {
                    element: <Layout />,
                    path: '/',
                    children: [
                        {
                            index: true,
                            path: 'dashboard',
                            element: <Dashboard />,
                        },
                          // Operator Section -------------
                        {
                            element: <OperatorList />,
                            path: 'operators',
                        },
                        {
                            element: <EditOperator />,
                            path: 'operators/:operatorId/edit'
                        },
                        {
                            element: <CreateOperator />,
                            path: 'operators/create'
                        },
    
                        // Banner Section -------------
    
                        {
                            element: <BannerList />,
                            path: 'banners',
                        },
                        {
                            element: <UpdateBanner />,
                            path: 'banners/:bannerId/edit'
                        },
                        {
                            element: <CreateBanner />,
                            path: 'banners/create'
                        },
    
                          // Artist Section -------------
    
                        {
                            element: <ArtistList />,
                            path: 'artists',
                        },
                        {
                            element: <UpdateArtist />,
                            path: 'artists/:artist_Id/edit'
                        },
                        {
                            element: <CreateArtist />,
                            path: 'artists/create'
                        },
    
                          // Artist Type Section -------------
    
                        {
                            element: <ArtistTypeList />,
                            path: 'artist_types',
                        },
                        {
                            element: <UpdateArtistType />,
                            path: 'artist_types/:artistTypeId/edit'
                        },
                        {
                            element: <CreateArtistType />,
                            path: 'artist_types/create'
                        },
    
                          // Category Section -------------
    
                        {
                            element: <CategoryList />,
                            path: 'categories',
                        },
                        {
                            element: <UpdateCategory />,
                            path: 'categories/:categoryId/edit'
                        },
                        {
                            element: <CreateCategory />,
                            path: 'categories/create'
                        },
    
                          // Product Section -------------
    
                        {
                            element: <ProductList />,
                            path: 'products',
                        },
                        {
                            element: <UpdateProduct />,
                            path: 'products/:productId/edit'
                        },
                        {
                            element: <CreateProduct />,
                            path: 'products/create'
                        },
    
                          // Process Section -------------
    
                        {
                            element: <ProcessList />,
                            path: 'processes',
                        },
                        {
                            element: <UpdateProcess />,
                            path: 'processes/:processId/edit'
                        },
                        {
                            element: <CreateProcess />,
                            path: 'processes/create'
                        },
    
                          // User Section -------------
    
                        {
                            element: <UserList />,
                            path: 'users',
                        },

                        // incorrect route page
                        {
                            path: '*',
                            element: <PageNotFound />
                        }
                    ]
                }
            ]
        }
    ])

    return routes
}

export default Router