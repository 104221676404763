import React, { useEffect, useState } from "react";
import apiServer from "../../constants/apiServer";
import useFetchInfo from "../../utils/useFetchInfo";
import { img_url_api } from "../../constants/apiImg";
import "../../css/process.sass";
import "../../css/artist.sass";
import { checkFormValidate } from "../../utils/commonFuncs";
import PopupLoading from "../../common/PopupLoading";
import { format } from "date-fns";
import { post } from "../../utils/api";
import { stringify } from "query-string";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import EmptyPage from "../../common/EmptyPage";
import ProcessForm from "./ProcessForm";

const UpdateProcess = () => {
    const [processInfoValues, setProcessInfoValues] = useState({
        nameEng: "",
        nameMyan: "",
        step: null,
        chosenProduct: null,
        startDate: new Date(),
        endDate: new Date(),
        desEng: "",
        desMyan: "",
        chosenArtistList: [],
        imageList: [],
        deleteImageList: [],
    });

    const { processId } = useParams();

    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState(0);

    const [errorValueList, setErrorValueList] = useState({});

    const [updateLoading, setUpdateLoading] = useState(false);
    const [errorUpdate, setErrorUpdate] = useState("");

    const [getInfoLoading, setGetInfoLoading] = useState(false);
    const [getInfoError, setGetInfoError] = useState("");

    const { data, isLoading, error } = useFetchInfo(
        `${apiServer.process.detail}?id=${processId}`
    );

    useEffect(() => {
        const fromDate = data["fromdate"];
        const toDate = data["todate"];
        const productList = data["product"];
        const artistList = data["artist"];
        const processMediaList = data["processmedia"];
        const startDate = new Date(
            `${fromDate?.year}-${fromDate?.month}-${fromDate?.day}`
        );
        const endDate = new Date(
            `${toDate?.year}-${toDate?.month}-${toDate?.day}`
        );
        let productName = "";
        let productId = "";

        for (let i = 0; i < productList?.length; i++) {
            productName = productList[i]?.name;
            productId = productList[i]?.id;
        }

        const receivedArtistList = [];
        for (let i = 0; i < artistList?.length; i++) {
            receivedArtistList.push({
                profile: img_url_api + data["artist"][i]?.image,
                name: data["artist"][i]?.name?.en,
                id: data["artist"][i]?.id,
            });
        }

        const receivedMediaList = [];
        for (let i = 0; i < processMediaList?.length; i++) {
            receivedMediaList.push({
                id: processMediaList[i]?.id,
                src: img_url_api + processMediaList[i]?.file,
                file: img_url_api + processMediaList[i]?.file,
                type: "image/",
                caption: processMediaList[i]?.caption,
                status: "uploaded",
            });
        }

        setProcessInfoValues({
            nameEng: data["title"]?.en,
            nameMyan: data["title"]?.mm,
            step: { label: `Step ${data["step"]}`, value: data["step"] },
            chosenProduct: {
                label: `${productName?.en} : ${productName?.mm}`,
                value: productId,
            },
            startDate: startDate,
            endDate: endDate,
            desEng: data["description"]?.en,
            desMyan: data["description"]?.mm,
            chosenArtistList: receivedArtistList,
            imageList: receivedMediaList,
            deleteImageList: [],
        });

        setGetInfoLoading(isLoading);
        setGetInfoError(error);
    }, [data, isLoading, error]);

    const handleChooseValue = (name, selectedOption) => {
        setProcessInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                [name]: selectedOption,
            };
        });
        delete errorValueList[name];
        setErrorValueList(errorValueList);
    };

    const handleAddValues = (name, valueInfo) => {
        setProcessInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                [name]: [...prevInfo[name], valueInfo],
            };
        });
    };

    const handleRemoveValues = (name, valueInfo) => {
        setProcessInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                [name]: prevInfo[name].filter(
                    (element) => parseInt(element.id) !== parseInt(valueInfo.id)
                ),
            };
        });
    };

    const handleChangeCaption = (value, imageInfo) => {
        const modifiedImageList = processInfoValues["imageList"];
        let modifiedImageId = modifiedImageList.findIndex(
            (element) => element.id === imageInfo.id
        );
        modifiedImageList[modifiedImageId]["caption"] = value;
        setProcessInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                imageList: modifiedImageList,
            };
        });
    };

    const handleInput = (event) => {
        const { name, value } = event.target;
        console.log("input values::", name, value);
        setProcessInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                [name]: value,
            };
        });
        delete errorValueList[name];
        setErrorValueList(errorValueList);
    };

    const handleUploadImg = (imgInfo) => {
        setProcessInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                imageList: [...prevInfo["imageList"], imgInfo],
            };
        });
    };

    const handleChangeStartDate = (event) => {
        setProcessInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                startDate: event,
                endDate: event,
            };
        });
    };

    const handleChangeEndDate = (event) => {
        setProcessInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                endDate: event,
            };
        });
    };

    const handleRemoveImage = (imageInfo) => {
        setProcessInfoValues((prevInfo) => {
            return {
                ...prevInfo,
                imageList: processInfoValues["imageList"].filter(
                    (element) => element !== imageInfo
                ),
                deleteImageList: [...prevInfo["deleteImageList"], imageInfo],
            };
        });
    };

    const handleNext = () => {
        if (activeStep === 0) {
            const generalInfoValue = {
                nameEng: processInfoValues["nameEng"],
                nameMyan: processInfoValues["nameMyan"],
                step: processInfoValues["step"],
                chosenProduct: processInfoValues["chosenProduct"],
            };
            const checkGeneralInfo = checkFormValidate(generalInfoValue);
            setErrorValueList(checkGeneralInfo);
            const errorStateList = Object.keys(checkGeneralInfo);
            if (errorStateList.length === 0) {
                setActiveStep((prevStep) => prevStep + 1);
            }
        } else if (activeStep === 1) {
            const detailedInfoValue = {
                startDate: processInfoValues["startDate"],
                endDate: processInfoValues["endDate"],
                desEng: processInfoValues["desEng"],
                desMyan: processInfoValues["desMyan"],
                chosenArtistList: processInfoValues["chosenArtistList"],
            };
            const checkDetailedInfo = checkFormValidate(detailedInfoValue);
            setErrorValueList(checkDetailedInfo);
            const detailErrorList = Object.keys(checkDetailedInfo);
            if (detailErrorList.length === 0) {
                setActiveStep((prevStep) => prevStep + 1);
            }
        } else if (activeStep === 2) {
            handleUpdate();
        }
    };

    const handleUpdate = async () => {
        const {
            nameEng,
            nameMyan,
            step,
            chosenProduct,
            startDate,
            endDate,
            desEng,
            desMyan,
            imageList,
            deleteImageList,
            chosenArtistList,
        } = processInfoValues;

        const errorUpdate = imageList?.filter(
            (element) => element?.caption.length > 250
        );

        imageList?.length > 0 && setErrorUpdate("Please upload process image");

        if (imageList?.length > 0 && errorUpdate.length === 0) {
            setUpdateLoading(true);

            const passedBody = new FormData();
            for (let i = 0; i < imageList.length; i++) {
                if (imageList[i].status === "uploaded") {
                    passedBody.append(
                        `update_process_media_id[${i}]`,
                        imageList[i]?.id
                    );
                    passedBody.append(
                        `update_caption[${i}]`,
                        imageList[i]?.caption
                    );
                } else {
                    passedBody.append(`image[${i}]`, imageList[i]?.file);
                    passedBody.append(
                        `image_caption[${i}]`,
                        imageList[i]?.caption
                    );
                }
            }
            for (let i = 0; i < chosenArtistList.length; i++) {
                passedBody.append(`artist_id[${i}]`, chosenArtistList[i]["id"]);
            }

            for (let i = 0; i < deleteImageList?.length; i++) {
                passedBody.append(
                    `delete_process_media_id[${i}]`,
                    deleteImageList[i]?.id
                );
            }

            const query = {
                titleEN: nameEng,
                titleMM: nameMyan,
                step: parseInt(step["value"]),
                product_id: chosenProduct["value"],
                descriptionEN: desEng,
                descriptionMM: desMyan,
                from_date: format(startDate, "yyyy-MM-dd"),
                to_date: format(endDate, "yyyy-MM-dd"),
            };

            const processURL = `${
                apiServer.process.update
            }?id=${processId}&${stringify(query)}`;

            try {
                const { data } = await post(processURL, passedBody);
                console.log("call api for update process", data);
                setUpdateLoading(false);
                if (data.status >= 400) {
                    setErrorUpdate(data.message);
                } else {
                    if (data.status === "success") {
                        navigate("/processes", {
                            replace: true,
                            state: { status: "success", message: data.message },
                        });
                    } else if (data.code >= 400) {
                        const responseErrList = Object.values(data)[0];
                        setErrorUpdate(responseErrList[0]);
                    }
                }
            } catch (error) {
                setErrorUpdate(error.message);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => (prevStep <= 0 ? 0 : prevStep - 1));
    };

    if (getInfoLoading) return <Loading text="Retrieving Information" />;

    if (getInfoError) return <EmptyPage text={getInfoError} />;

    if (updateLoading) return <PopupLoading text="Updating Process" />;

    return (
        <ProcessForm
            title="Update"
            processInfoValues={processInfoValues}
            handleInput={handleInput}
            errorValueList={errorValueList}
            onUploadImg={handleUploadImg}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleChooseValue={handleChooseValue}
            handleAddValues={handleAddValues}
            handleRemoveValues={handleRemoveValues}
            onRemoveImage={handleRemoveImage}
            onChangeCaption={handleChangeCaption}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            errorMessage={errorUpdate}
        />
    );
};

export default UpdateProcess;
