import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../css/customizeCalendar.css";

const CustomCalendar = (props) => {
  let { value, onChange, minDate, maxDate, tileDisabled } = props;
  return (
    <Calendar
      value={value}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      tileDisabled={tileDisabled}
    />
  );
};

export default CustomCalendar;
