import React,{useState, useEffect, useCallback} from 'react'
import { useNavigate } from "react-router-dom";
import useFetch from '../../utils/useFetch';
import apiServer from '../../constants/apiServer';
import { post } from "../../utils/api";
import DataList from '../../common/DataList';
import defaultImg from '../../image/default-image.png';
import ConfirmationPopup from '../../common/ConfirmationPopup';
import { img_url_api } from "../../constants/apiImg";
import TableAction from '../../common/TableAction';
import { Headers } from "../../constants/Headers";
import { StatusCode, DeleteRow } from "../../utils/commonFuncs";
import { useContext } from 'react';
import { DataContext } from '../../context/dataProvider';
import { useLocation } from 'react-router-dom';

const ArtistList = () => {

    const navigate = useNavigate();

    const location = useLocation();

    const { currentPageList, setCurrentPageList } = useContext(DataContext);

    const [ page, setPage ] = useState(currentPageList['artist']);
    const [rowPerPage, setRowPerPage ] = useState(10);
    const [totalRecords, setTotalRecords ] = useState(0);
    const [actionStatus, setActionStatus] = useState('success');
    const [actionMessage, setActionMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [getListError, setGetListError ] = useState('');
    const [confirmDelete , setConfirmDelete ] =  useState(false); 
    const [artistId, setArtistId] = useState('');
    const [ headerInfo , setHeaderInfo ] = useState(Headers['Artists']);

    const { data, totalCount, isLoading, error } = useFetch(
      `${apiServer.artists.list}?page_at=${page}&row_count=${rowPerPage}`
    );

    useEffect(()=>{
      setLoading(isLoading);
    },[isLoading])

    const modifiedData = useCallback((listData) => {
      const modifyData = listData.map((info, index) => {
        return {
          ...info,
          rowId: index+1+(page - 1) * rowPerPage,
          profile: info?.image,
          artist_eng_name: info?.artist_name.en,
          artist_type: info?.artist_type_name.mm
        }
      });
      return modifyData;
    }, [page, rowPerPage]);


    useEffect(()=> {
      const modifyData = modifiedData(data);
      setRowData(modifyData)
    },[data, page, modifiedData, rowPerPage]);

    useEffect(() => {

      if(location?.state !== null){
        const { status, message } = location?.state;
        setActionStatus(status);
        setActionMessage(message);
      }
  
    }, [location]);

    useEffect(() => {
      setTotalRecords(totalCount)
    },[totalCount])

    useEffect(()=> {
      setGetListError(error)
    },[error]);


    const modifyHeader = useCallback(
      () => {

        const headerData = headerInfo;

        headerData[1]["renderCell"] = (params) => {
          const profileImage = params.formattedValue;
          const id = params.api.getRowIndex(params.row.id);
          return(
            <img src={profileImage !== "" || profileImage !== undefined ? img_url_api+profileImage : defaultImg } alt="artist_profile" className={profileImage === "" || profileImage === undefined ? "dummy_profile" : id === 0 ? "first--artist_profile" : "artist_profile"}/>
          )
        }
  
        headerData[headerData.length - 1]["renderCell"] = ({id}) =>{
          const onEdit = () =>{
            navigate(`${id}/edit`, { replace: true });
          }
  
          const onDelete = () => {
            setConfirmDelete(true);
            setArtistId(id);
          }
          return (
            <TableAction onEdit={onEdit} onDelete={onDelete} />
          );
        };
      
        setHeaderInfo(headerData);
      },
      [headerInfo, navigate]
    );

    useEffect(() => {

      modifyHeader();

    }, [modifyHeader, headerInfo]);


    const handleConfirmDelete = async() => {

      setConfirmDelete(false);

      setLoading(true);

      try{

        const { data: deletedInfo } = await post(`${apiServer.artists.delete}?id=${artistId}`);

        setActionStatus(StatusCode[deletedInfo?.status]);
        setActionMessage(deletedInfo?.message);

        if(! (deletedInfo?.status >= 400 )){
          
          const dataList = modifiedData(DeleteRow(rowData, artistId));
          setRowData(dataList);
          setTotalRecords(totalRecords - 1);
          if(dataList.length === 0){

            setPage((prev) => prev > 1  ? prev - 1 : 1);
            setCurrentPageList((prev) => {
              return {
                ...currentPageList,
                "artist" :  prev > 1 ? prev - 1 : 1
              }
            });
          }

        }
        
        setLoading(false);


      }catch(err){

          setLoading(false);
          setActionStatus("error");
          setActionMessage(err.message);
          
      }

    }

    const handleChangePage = (currentPage) => {
      setPage(currentPage + 1)
      setCurrentPageList({
        ...currentPageList,
        "artist" : currentPage + 1 
      });
    }

    const handleChangePageSize = (rows) => {
      setRowPerPage(rows)
    }

    return (
      <>
          <DataList
            title={"Artist List"}
            headers={headerInfo}
            rowData={rowData}
            isLoading={loading}
            totalCount={totalRecords}
            error={getListError}
            currentPage={page}
            pageSize={rowPerPage}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            deleteMsg={actionMessage}
            deleteStatus={actionStatus}
            emptyDataText={'There is no artist. Please Create One.'}
            
          />

          { confirmDelete && 
            <ConfirmationPopup
                headerText="Delete Artist"
                body={"Are you sure you want to delete this artist ?"}
                onSubmit={handleConfirmDelete}
                onCancel={() => {
                  setConfirmDelete(false)
                }}
            />
          }
      
      </>
    );
}

export default ArtistList