import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import Loading from "./Loading";
import { DataGrid } from "@mui/x-data-grid";
import AdaAlert from "./AdaAlert";
import ListHeaderBar from "./ListHeaderBar";
import EmptyDataList from "./EmptyDataList";
import "../css/dataList.sass";
import EmptyPage from "../common/EmptyPage";
import ReactLoading from "./ReactLoading";

const DataList = ({
  title,
  headers,
  totalCount,
  currentPage,
  pageSize,
  rowData,
  isLoading,
  error,
  deleteMsg,
  deleteStatus,
  disabledCreate,
  emptyDataText, 
  onPageChange,
  onPageSizeChange,
  hideCreateBtn
}) => {
  const navigate = useNavigate();
  const pathName = useLocation().pathname;

  const handleCreateOperator = () => {
    navigate(`${pathName}/create`, { replace: true });
  };

  if (isLoading) return <ReactLoading text={`Loading ${title}`} />;

  if (error) return <EmptyPage text={error} />

  return (
    <>
        {
          rowData.length === 0 && 
          <EmptyDataList text={emptyDataText} onCreate={handleCreateOperator} />
        }
        {
          rowData.length > 0 && 
            <div className="ada-operator-list-page">
              <ListHeaderBar
                title={title}
                createAction={handleCreateOperator}
                disabledCreate={disabledCreate}
                hideCreateBtn={rowData.length === 0 || hideCreateBtn}
              />
              <div className="ada-operator-list-container">
                <DataGrid
                  rows={rowData}
                  columns={headers}
                  onPageChange={(newPage) => onPageChange(newPage)}
                  onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
                  rowsPerPageOptions={[10, 15, 20]}
                  rowCount={totalCount}
                  paginationMode="server"
                  initialState={{
                    // ...rowData.initialState,
                    pagination: {
                      pageSize: pageSize,
                      page: currentPage - 1
                    },
                  }}
                  scrollbarSize={2}
                  scrollArea--right={{
                    backgroundColor: 'transparent',
                  }}
                  getRowId= {(row) => row.id}
                />
              </div>
              {deleteMsg && <AdaAlert message={deleteMsg} type={deleteStatus} />}
            </div>
        }
    </>
  );
};

export default DataList;
