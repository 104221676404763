import React from 'react'
import empty_data from "../image/empty_data.svg";
import CreateButton from './CreateButton';
import "../css/common.sass"

const EmptyDataList = ({text, onCreate}) => {
  return (
    <div className='main-empty-page-container'>
        <img src={empty_data} width='300px' alt='empty_data' />
        <p>{text}</p>
        <CreateButton onCreate={onCreate} />
    </div>
  )
}

export default EmptyDataList