import React from 'react'
import EditImageBox from '../../common/EditImageBox'
import PopupHeader from '../../common/PopupHeader'
import "../../css/banner.sass";
import { Scrollbars } from "react-custom-scrollbars";

const EditBannerImageList = ( {imageList, onRemove, onAddCaption, onClose} ) => {
  return (
    <div className='popup-overlay-container'>
        <div className='edit-banner-image-list-popup'>
            <PopupHeader header={'Edit Banner Images'} onClose={onClose}/>
            <Scrollbars autoHide autoHideTimeout={200} autoHideDuration={200} style={{height: 'calc(100% - 13vh)' }}>
                <div className='edit-banner-image-list'>
                    {
                        imageList.map((item, index) => 
                            <EditImageBox imageInfo={{...item, status: "new"}} key={index} onRemove={() => onRemove(item)} onAddCaption={onAddCaption} />
                        )
                    }
                </div>     
            </Scrollbars>
            
        </div>
    </div>
  )
}

export default EditBannerImageList