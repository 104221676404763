import React from "react";
import DetailHeaderBar from "../../common/DetailHeaderBar";
import InputField from "../../common/InputField";
import "../../css/artist.sass";
import { Scrollbars } from "react-custom-scrollbars";
import FormActionRow from "../../common/FormActionRow";
import ImageUpload from "../../common/ImageUpload";

const ArtistForm = ({
    formTitle,
    onChangeValue,
    onUploadProfile,
    onRemoveProfile,
    onChangeDate,
    onSelectValue,
    infoValues,
    errorValues,
    onCreate,
    onCreateCancel,
    formSubmitName,
}) => {
    const {
        profile,
        nameEN,
        nameMM,
        type,
        chosenType,
        dob,
        gender,
        bioEN,
        bioMM,
    } = infoValues;

    return (
        <>
            <div className="create-artist-page-section">
                <DetailHeaderBar navigateRoute={"/artists"} name={formTitle} />
                <form onSubmit={onCreate}>
                    <div className="create-artist-page-body">
                        <div className="create-artist-page-left-side">
                            <ImageUpload
                                uploadImg={profile}
                                onUploadImage={onUploadProfile}
                                cancleUploadImg={onRemoveProfile}
                                isFlag={"profile" in errorValues}
                                errMsg={errorValues?.profile}
                            />
                        </div>
                        <Scrollbars
                            style={{ height: "480px" }}
                            autoHide
                            autoHideDuration={200}
                            autoHideTimeout={200}
                        >
                            <div className="create-artist-page-right-side">
                                <div className="create-artist-form-row">
                                    <InputField
                                        layout="vertical"
                                        mainWidth="100%"
                                        width="93%"
                                        isRequired
                                        title="Name (in English)"
                                        id="Artist English Name"
                                        name="nameEN"
                                        value={nameEN}
                                        isFlag={
                                            "nameEN" in errorValues ||
                                            nameEN?.length > 100
                                        }
                                        errMsg={
                                            nameEN?.length > 100
                                                ? "Must not exceed 100 words"
                                                : errorValues["nameEN"]
                                        }
                                        onChange={onChangeValue}
                                    />
                                    <InputField
                                        layout="vertical"
                                        mainWidth="100%"
                                        width="93%"
                                        isRequired
                                        title="Name (in Myanmar)"
                                        id="Artist Myanmar Name"
                                        name="nameMM"
                                        value={nameMM}
                                        isFlag={
                                            "nameMM" in errorValues ||
                                            nameMM?.length > 100
                                        }
                                        errMsg={
                                            nameMM?.length > 100
                                                ? "Must not exceed 100 words"
                                                : errorValues["nameMM"]
                                        }
                                        onChange={onChangeValue}
                                    />
                                </div>
                                <div className="create-artist-form-row">
                                    <InputField
                                        isRequired
                                        title="Artist Type"
                                        mainWidth="100%"
                                        id="Artist Type"
                                        name="artistType"
                                        selectBoxClassName="choose-artist-type-select-box"
                                        selectValue={chosenType}
                                        selectOptionList={type}
                                        searchableSelect
                                        onChangeSelectBox={(chosenValue) => {
                                            onSelectValue(
                                                "chosenType",
                                                chosenValue
                                            );
                                            onSelectValue(
                                                "artisttype_id",
                                                chosenValue?.value
                                            );
                                        }}
                                        fieldType={"search-select"}
                                        isFlag={"artisttype_id" in errorValues}
                                        errMsg={errorValues["artisttype_id"]}
                                    />
                                </div>
                                <div className="create-artist-form-row">
                                    <InputField
                                        layout="vertical"
                                        mainWidth="100%"
                                        width="93%"
                                        isRequired
                                        title="Date of Birth"
                                        fieldType="date"
                                        id="dob"
                                        name="dob"
                                        isFlag={"dob" in errorValues}
                                        errMsg={errorValues["dob"]}
                                        selectedDate={dob}
                                        onChangeDate={onChangeDate}
                                        maxSelectDate={new Date("1-1-2018")}
                                        minDate={new Date(1 - 1 - 1800)}
                                    />
                                    <InputField
                                        layout="vertical"
                                        mainWidth="100%"
                                        width="93%"
                                        isRequired
                                        title="Gender"
                                        fieldType="selectbox"
                                        id="artist_gender"
                                        name="gender"
                                        isFlag={"gender" in errorValues}
                                        errMsg={errorValues["gender"]}
                                        list={["Male", "Female"]}
                                        selectedValue={gender}
                                        onSelectValue={onChangeValue}
                                    />
                                </div>
                                <div className="create-artist-form-row">
                                    <InputField
                                        layout={"vertical"}
                                        mainWidth="100%"
                                        width="96%"
                                        isRequired
                                        title="Biography (in English)"
                                        id="artist-eng-bio"
                                        name="bioEN"
                                        isFlag={"bioEN" in errorValues}
                                        errMsg={errorValues["bioEN"]}
                                        fieldType={"textarea"}
                                        description={bioEN}
                                        onChangeDescription={onChangeValue}
                                    />
                                </div>
                                <div className="create-artist-form-row">
                                    <InputField
                                        layout={"vertical"}
                                        mainWidth="100%"
                                        width="96%"
                                        isRequired
                                        title="Biography (in Myanmar)"
                                        id="artist-myan-bio"
                                        name="bioMM"
                                        isFlag={"bioMM" in errorValues}
                                        errMsg={errorValues["bioMM"]}
                                        fieldType={"textarea"}
                                        description={bioMM}
                                        onChangeDescription={onChangeValue}
                                    />
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                    <FormActionRow
                        submitBtnName={formSubmitName}
                        onCancel={onCreateCancel}
                    />
                </form>
            </div>
        </>
    );
};

export default ArtistForm;
