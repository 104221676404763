import React from 'react'
import "../../css/dashboard.sass";
import { FiArrowRight } from "react-icons/fi";

const DashboardInfoCard = ({cardInfo, onRoute, color}) => {
  return (
    <div className='dashboard-info-card-container'>
      <div className='dashoboard-info-card--first-part'>
        <div className='dashboard-info-card-icon' style={{backgroundColor: color}}>
          {cardInfo.icon}
        </div>
        <div className='dashboard-info-card-info'>
          <span>{cardInfo.name}</span>
          <h6>{cardInfo.count}</h6>
        </div>

      </div>
      <div className='dashboard-info-card--second-part'>
        <button onClick={onRoute}>{cardInfo.name}&nbsp;<FiArrowRight size='18px' /></button>
      </div>
    </div>
  )
}

export default DashboardInfoCard