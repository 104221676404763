import React from "react";
import InputField from "../../common/InputField";
import "../../css/artist.sass";


const DetailedInformation = ({ states, onChange, onChangeDateRange, onChangeDate, minReleaseDate, onChangeUnit }) => {
  let {
    creationDateRange,
    releasedDate,
    isLimited,
    isAvailable,
    size,
    sizeUnit,
    length,
    lengthUnit,
    weight,
    weightUnit,
    warranty
  } = states;

  console.log('minReleaseDate',minReleaseDate, new Date(releasedDate), creationDateRange)

  return (
    <div className="create-product-form">
      <div className="create-product-form-left-side">
        <div className="create-product-form-row">
          <InputField
            title="Creation Date"
            layout="horizontal"
            isRequired
            id="Creation Date Range"
            mainWidth="100%"
            width="220px"
            fieldType={"date-range"}
            dateRangeValue={creationDateRange}
            onChangeDateRangeValue={onChangeDateRange}
          />
        </div>
        <div className="create-product-form-row">
          <InputField
            title="Released Date"
            layout="horizontal"
            isRequired
            id="Released Date"
            fieldType={"date"}
            mainWidth="100%"
            width="240px"
            selectedDate={releasedDate}
            onChangeDate={onChangeDate}
            minSelectDate={minReleaseDate === undefined ? releasedDate : new Date(minReleaseDate)}
          />
        </div>
        <div className="create-product-form-row">
          <InputField
            title="Is Limited?"
            layout="horizontal"
            isRequired
            id="isLimited"
            fieldType={"radio"}
            mainWidth="100%"
            width="240px"
            name="isLimited"
            selectedRadioValue={isLimited}
            radioValueList={[
              { name: "Yes", value: "1" },
              { name: "No", value: "2" }
            ]}
            onChangeRadioValue={onChange}
          />
        </div>
        <div className="create-product-form-row">
          <InputField
            title="Is Available?"
            layout={"horizontal"}
            isRequired
            id="isAvailable"
            fieldType={"radio"}
            mainWidth="100%"
            width="240px"
            name="isAvailable"
            selectedRadioValue={isAvailable}
            radioValueList={[
              { name: "Yes", value: "1" },
              { name: "No", value: "2" }
            ]}
            onChangeRadioValue={onChange}
          />
        </div>
      </div>
      <div className="create-product-form-right-side">
        <div className="create-product-form-row">
          <InputField
            title="Size"
            layout={"horizontal"}
            id="size"
            fieldType={"inputSelect"}
            type="number"
            mainWidth="100%"
            width="225px"
            name="size"
            unitName="sizeUnit"
            value={size}
            onChange={onChange}
            unitValue={sizeUnit}
            unitValueList={["inch", "cm"]}
            onChangeUnitValue={onChangeUnit}
            isFlag = { size?.length > 250 }
            errMsg = { "Must not exceed more than 250 words" }
          />
        </div>
        <div className="create-product-form-row">
          <InputField
            title="Length"
            layout={"horizontal"}
            fieldType="inputSelect"
            id="length"
            type="number"
            mainWidth="100%"
            width="225px"
            name="length"
            unitName={"lengthUnit"}
            value={length}
            onChange={onChange}
            unitValue={lengthUnit}
            unitValueList={["inch", "cm"]}
            onChangeUnitValue={onChangeUnit}
            isFlag={ length?.length > 250 }
            errMsg = {"Must not exceed more than 250 words"}
          />
        </div>
        <div className="create-product-form-row">
          <InputField
            title="Weight"
            layout={"horizontal"}
            id="weight"
            fieldType="inputSelect"
            type="number"
            mainWidth="100%"
            width="225px"
            name="weight"
            unitName={"weightUnit"}
            value={weight}
            onChange={onChange}
            unitValue={weightUnit}
            unitValueList={["lb", "kg"]}
            onChangeUnitValue={onChangeUnit}
            isFlag = { weight?.length > 250 }
            errMsg = { "Must not exceed more than 250 words" }
          />
        </div>
        <div className="create-product-form-row">
          <InputField
            title="Warranty"
            layout={"horizontal"}
            id="weight"
            mainWidth="100%"
            width="204px"
            name="warranty"
            value={warranty}
            onChange={onChange}
            isFlag ={ warranty?.length > 250 }
            errMsg = { "Must not exceed more than 250 words" }
          />
        </div>
      </div>
    </div>
  );
};

export default DetailedInformation;
