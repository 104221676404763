import React from 'react'

const DotCircleLoading = ({ text }) => {
  return (
    <div className='main-loading-container'>
        <div className='loading-container'>
            <div className='circle-dot-loading-container' />&nbsp;{text}
        </div>
    </div>
  )
}

export default DotCircleLoading