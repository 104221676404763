import React, { useState } from "react";

export const DataContext = React.createContext(null);

export const DataProvider = ({ children }) =>{
    const [navWidth, setNavWidth] = useState('200px');

    const [ currentPageList, setCurrentPageList ] = useState({
        'banner' : 1,
        'artist' : 1,
        'artistType' : 1,
        'category' : 1,
        'operator' : 1,
        'process' : 1,
        'product' : 1,
        'user' : 1
    });

    return(
        <DataContext.Provider value={{navWidth, setNavWidth, currentPageList, setCurrentPageList}}>
            {children}
        </DataContext.Provider>
    )
}