import React, { useState, useEffect, useCallback } from 'react'
import useFetch from '../../utils/useFetch';
import apiServer from '../../constants/apiServer';
import DataList from '../../common/DataList';
import { Headers } from '../../constants/Headers';

const UserList = () => {
  // const navigate = useNavigate();

  const [ page, setPage ] = useState(1);
  const [rowPerPage, setRowPerPage ] = useState(10);
  const [totalRecords, setTotalRecords ] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [getListError, setGetListError ] = useState('');

  const { data, totalCount, isLoading, error } = useFetch(
    `${apiServer.user.list}?page_at=${page}&row_count=${rowPerPage}`
  );

  const modifiedData = useCallback(() => {
    let modifyData = data.map((info, index) => {
      return {
        ...info,
        rowId: index + 1 + (page - 1) * rowPerPage,
      }
    });
    return modifyData;
}, [data, page, rowPerPage])

  useEffect(()=>{
    setLoading(isLoading);
  },[isLoading])


  useEffect(()=> {
    setRowData(modifiedData());
  },[data, page, modifiedData]);

  useEffect(()=> {
    setGetListError(error)
  },[error])
  
  useEffect(() => {
    setTotalRecords(totalCount)
  },[totalCount])


  const handleChangePage = (currentPage) => {
    setPage(currentPage + 1)

  }

  const handleChangePageSize = (rows) => {
    setRowPerPage(rows)
  }

  return (
    <>
      <DataList
        title={"User List"}
        headers={Headers['User']}
        rowData={rowData}
        isLoading={loading}
        totalCount={totalRecords}
        error={getListError}
        currentPage={page}
        pageSize={rowPerPage}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        hideCreateBtn
      />

    </>
  );
}

export default UserList