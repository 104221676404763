import React from 'react'
import DetailHeaderBar from '../../common/DetailHeaderBar';
import InputField from '../../common/InputField';
import ImageUpload from '../../common/ImageUpload';
import FormActionRow from '../../common/FormActionRow';
import "../../css/artist.sass";


const CategoryForm = ( { infoValues, errorValues, onCreate, onCancel, formTitle, onSubmitName, onUploadImg, onRemoveImg, onChangeValues, onChangeSelect }) => {

    const {categoryImg, engName, myanName, subCategory, chosenCategory, categoryList } = infoValues;

  return (
    <div className="create-artist-page-section">
    <DetailHeaderBar navigateRoute={"/categories"} name={formTitle} />
    <form onSubmit={onCreate}>
      <div
        className="create-artist-page-body"
        style={{ height: "calc(100vh - 14em)" }}
      >
        <div className="create-artist-page-left-side">
          <ImageUpload
            uploadImg={categoryImg}
            onUploadImage={onUploadImg}
            cancleUploadImg={onRemoveImg}
            isFlag={"categoryImg" in errorValues}
            errMsg={errorValues?.categoryImg}
          />
        </div>
        <div className="create-artist-page-right-side">
          <div className="create-artist-form-row">
            <InputField
              layout="horizontal"
              mainWidth="100%"
              width="250px"
              isRequired
              title="Category Name (in English)"
              id="Category English Name"
              name="engName"
              value={engName}
              isFlag={'engName' in errorValues || engName?.length > 100}
              errMsg={engName?.length > 100 ? "Must not exceeded more than 100 words" : errorValues?.engName}
              onChange={onChangeValues}
            />
          </div>
          <div className="create-artist-form-row">
            <InputField
              layout="horizontal"
              mainWidth="100%"
              width="250px"
              isRequired
              title="Category Name (in Myanmar)"
              id="Category Myanmar Name"
              name="myanName"
              value={myanName}
              isFlag={myanName in errorValues || myanName?.length > 100}
              errMsg={myanName?.length > 100 ? "Must not exceeded more than 100 words" : errorValues?.myanName}
              onChange={onChangeValues}
            />
          </div>
          {
            categoryList.length >= 1 && (
              <div className="create-artist-form-row">
                <InputField
                  layout="horizontal"
                  mainWidth="100%"
                  width="280px"
                  id="CategoryType"
                  isRequired
                  fieldType="radio"
                  title="Sub Category"
                  name="subCategory"
                  selectedRadioValue={subCategory}
                  onChangeRadioValue={onChangeValues}
                  radioValueList={[
                    {
                      name: "Yes",
                      value: "yes"
                    },
                    {
                      name: "No",
                      value: "no"
                    }
                  ]}
                />
              </div>
            )
          }
          
          {subCategory === "yes" && (
            <div className="create-artist-form-row">
              <InputField
                title="Choose Category"
                fieldType='search-select'
                isRequired
                searchableSelect
                id="Choose Category"
                mainWidth="100%"
                selectBoxClassName={'choose-category-select-box'}
                selectValue={chosenCategory}
                selectOptionList={categoryList}
                name='chosenCategory'
                onChangeSelectBox={(chosenValue) => onChangeSelect(chosenValue)}
                isFlag={'chosenCategory' in errorValues}
                errMsg={errorValues?.chosenCategory}
              />
            </div>
          )}
        </div>
      </div>
      <FormActionRow submitBtnName={onSubmitName} onCancel={onCancel} />
    </form>
  </div>
  )
}

export default CategoryForm